import { cx } from '@emotion/css';
import React, { EventHandler, useState } from 'react';
import { SizeVariants } from '../../../theme/themes';
import { makeCSS } from '../../../utils/makeCSS';
import { Icon } from '../../atoms/Icon';
import { iconFiles } from '../../atoms/Icon/files';
import { Key } from '../../atoms/Key';
import { Typography } from '../../atoms/Typography';

/**
 * The ContextMenuListItem is displaying one of the options that the user can choose
 * from in the ContextMenu. A number of different ContextMenuListItem components and ContextMenuDivider components compose the final ContextMenu.
 */

export interface ContextMenuListItemProps {
  leftIcon?: keyof typeof iconFiles;
  /**The label of this option.*/
  name?: string;
  /** the form value of the item when used in a Select component */
  value?: string;
  /**Display / Use a modifier to indicate the shortcut for this option.*/
  showShortcut?: boolean;
  modifiers?: string;
  /**Display or not use a chevron for this option.*/
  showChevron?: boolean;
  /**Select this option, name it to `onClick` here to prevent it gets override in `Select` component */
  onClick?: EventHandler<React.MouseEvent>;
  /**Select this option, name it to `onClick` here to prevent it gets override in `Select` component */
  onSelect?: EventHandler<React.SyntheticEvent>;
  /**Displayed this option */
  disabled?: boolean;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      userSelect: 'none',
      display: 'inline-flex',
      width: '100%',
      minHeight: theme.spacing[10],
      padding: theme.spacing[2],
      alignItems: 'center',
      borderRadius: theme.borderRadius.lg,
      cursor: 'pointer',
      outline: 'none',
      boxSizing: 'border-box',
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:active': {
        background: theme.color.overlay.pressed
      },
      '&.disabled': {
        cursor: 'default',
        opacity: 0.4,
        '&:hover': {
          background: 'none'
        }
      }
    },
    labelStyle: {
      pointerEvents: 'none'
    },
    leftIcon: {
      color: theme.color.icon['02'],
      paddingRight: theme.spacing[2],
      pointerEvents: 'none'
    },
    rightIcon: {
      color: theme.color.icon['02'],
      paddingLeft: theme.spacing[2],
      pointerEvents: 'none'
    },
    alignRight: {
      marginLeft: 'auto'
    },
    modifiers: {
      marginLeft: 'auto',
      pointerEvents: 'none'
    }
  };
});

export function ContextMenuListItem(props: ContextMenuListItemProps) {
  const {
    leftIcon,
    value,
    name,
    showShortcut = true,
    modifiers = '',
    showChevron = true,
    onClick,
    onSelect,
    disabled
  } = props;

  const classes = useCSS();

  const size = SizeVariants.md;

  //icon color
  const [focus, setFocus] = useState(false);
  const colorVariant = focus ? '01' : '02';

  const handleFocus = () => {
    disabled ? setFocus(false) : setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };

  const addHoverStyle = () => {
    if (!disabled) {
      setFocus(true);
    }
  };
  const removeHoverStyle = () => {
    setFocus(false);
  };

  const displayName = name || value?.charAt(0).toUpperCase() + value?.slice(1);

  return (
    <div
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={0}
      onClick={onClick || onSelect}
      onMouseOver={addHoverStyle}
      onMouseLeave={removeHoverStyle}
      className={cx(classes.root, {
        disabled: disabled
      })}
    >
      {leftIcon && (
        <div className={cx(classes.leftIcon)}>
          <Icon icon={leftIcon} colorVariant={colorVariant} size={size} />
        </div>
      )}
      <div className={`${classes.labelStyle}`}>
        <Typography variant="md" colorVariant="01" weight="body">
          {/**capitalize first letter */}
          {displayName}
        </Typography>
      </div>
      {showShortcut && modifiers !== '' ? (
        <div className={`${classes.modifiers}`}>
          <Key keyCode={modifiers} variant="modifier" />
        </div>
      ) : (
        ''
      )}
      {showChevron && (
        <div
          className={cx(
            classes.rightIcon,
            !showShortcut ? classes.alignRight : ''
          )}
        >
          <Icon icon="chevron_right" colorVariant={colorVariant} size={size} />
        </div>
      )}
    </div>
  );
}
