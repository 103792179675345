import { validate } from 'jsonschema';
import moment from 'moment';
import React from 'react';
import {
  MetadataCategory,
  MetadataFormField
} from '@cube3/common/model/schema/resources/metadata-category';
import { ContractKeyGroup } from '@cube3/common/model/schema/resources/contract-key-group';
import { ContractValue } from '@cube3/common/model/schema/resources/contract-value';
import { MetadataValue } from '@cube3/common/model/schema/resources/metadata-value';
import { MetadataField } from '@cube3/common/model/schema/resources/metadata-field';

const ERROR_MESSAGE = {
  REQUIRED: 'Field can not be empty'
};

export const FORMAT_C3_YEAR_DATE = 'c3:year-date';

export const selectFieldRegexp = /select/; // /select\(.*?\)/;
export const multipleFieldRegexp = /multiple/; // /multiple\(.*?\)/;

export const hydrateValue = (value, type, skipJson = false) => {
  // TODO: update when this is done serverside and/or add multi-select
  let parsed = value;

  if (!skipJson) {
    try {
      parsed = JSON.parse(value);
    } catch (e) {
      console.warn(`tried to parse value ${value} of type ${type}`);
    }
  }

  switch (true) {
    case type === 'waterfall_select':
      if (typeof parsed === 'string') {
        parsed = JSON.parse(parsed);
      }
      return { value: parsed };
    case multipleFieldRegexp.test(type):
      return parsed.split(',');
    case type === 'text' || type === 'string' || type === 'textarea':
      return parsed.toString();
    case type === 'asset':
      return parsed.toString().split(',');
    default:
      return parsed;
  }
};

export const dehydrateValue = (value, type, schema) => {
  // TODO: update when this is done serverside and/or add multi-select
  let formated;
  switch (true) {
    case type === 'date' &&
      schema &&
      JSON.parse(schema)?.format === FORMAT_C3_YEAR_DATE:
      formated = yearParser(value);
      break;
    case type === 'waterfall_select':
      formated = value?.value;
      break;
    case multipleFieldRegexp.test(type):
      formated = value.join(',');
      break;
    case type === 'text' || type === 'string' || type === 'textarea':
      formated = value.toString().trim();
      break;
    default:
      formated = value;
      break;
  }

  return formated;
};

// TODO: actually format errors based on error codes or something
const formatErrors = (errors) => {
  if (errors?.length > 0) {
    console.warn('jsonschema errors', errors);
    switch (errors[0].name) {
      case 'required':
        return ERROR_MESSAGE.REQUIRED;
      case 'minLength':
        if (errors[0].argument === 1) {
          return ERROR_MESSAGE.REQUIRED;
        }
        break;
      case 'enum':
        return `Allowed values: ${errors[0].argument.join(', ')}`;
      case 'oneOf':
        return ERROR_MESSAGE.REQUIRED;

      default:
        break;
    }

    return errors[0].message || `This does not match the required format.`; // TODO: Expand in such a way that we look at what errors the schema returns
  }
};

const isResources = (value) => {
  return !value?.filter((item) => !item.id || !item.type).length;
};

const makeValidator = (required, schemaString, value_type) => {
  const schema = JSON.parse(schemaString);

  return (value, allValues, props, name) => {
    if (required && (!value || value.length === 0)) {
      switch (value_type) {
        case 'number':
          if (value === 0) {
            // don't show error if type 0
            break;
          }
          break;
        case 'multiselect':
          if (!name) {
            break;
          }
          return ERROR_MESSAGE.REQUIRED;
        default:
          return ERROR_MESSAGE.REQUIRED;
      }
    }

    const valid = validate(value, schema);
    switch (value_type) {
      case 'resource_picker':
        if (schema.title !== 'ContractRecipients' && !isResources(value)) {
          return formatErrors(['Not a valid resource']);
        } else {
          return undefined;
        }
      case 'date':
        if (!value) return undefined; // skip json schema validation if there is no value for `year_only` date type
        break;
      case 'select': // don't show type error if the field is not required and nothing is selected
        if (!value && !required) {
          return undefined;
        }
        break;
      case 'waterfall_select': {
        const errors = validate(value?.value, schema).errors;
        if (errors?.[0]?.name === 'oneOf' && !required) {
          return undefined;
        }
        return formatErrors(errors);
      }

      default:
        return formatErrors(valid.errors);
    }
  };
};

const lookupResourcePicker = (schema) => {
  switch (schema.title) {
    case 'ContractProjects':
      return 'contract-projects';
    case 'ContractAssets':
      return 'contract-assets';
    case 'ContractRecipients':
      return 'contract-recipients';
    default:
      return undefined;
  }
};

// build additional data used by components like suggestions / options
const makeData = (field: MetadataFormField) => {
  const schema = JSON.parse(field.json_schema);
  const type = field.value_type;
  const { minLength, maxLength, minimum, maximum, format, multipleOf, items } =
    schema;

  switch (true) {
    case type === 'number':
      return {
        min: minimum,
        max: maximum,
        step: multipleOf
      };
    case type === 'string':
      return {
        minLength,
        maxLength
      };
    case type === 'email':
    case type === 'date':
      return {
        minLength,
        maxLength,
        yearOnly: format === FORMAT_C3_YEAR_DATE
      };
    case type === 'textarea':
      return { minLength, maxLength };
    case type === 'multiselect':
      return {
        suggestions: items.enum
      }; // TODO:
    case type === 'resource_picker':
      return {
        variant: lookupResourcePicker(schema)
      }; // TODO:

    case type === 'waterfall_select':
      return {
        options: makeWaterfallOptions(schema)
      }; // TODO: min / max entries

    case !!selectFieldRegexp.test(type):
      return {
        options:
          schema.type === 'array' ? items.enum || items[0].enum : schema.enum,
        multiple: schema.type === 'array'
      }; // TODO: min / max entries

    // case !!multipleFieldRegexp.test(type):
    //   return {
    //     suggestions: ['i', "don't", 'know', 'where', 'to', 'get', 'these']
    //   }; // TODO: find where to get these
    default:
      return undefined;
  }
};

const makeWaterfallOptions = (schema) => {
  const { properties } = schema;

  return Object.keys(properties).reduce((acc, l1) => {
    acc[l1] = [...(properties[l1].items.enum || [])];
    // provide some hints for the placeholder
    // NOTE: bit ugly since it adds properties to an array instance, but it works for now
    acc[l1].optional = !properties[l1].minItems || properties[l1].minItems < 1;
    acc[l1].minItems = properties[l1].minItems;
    return acc;
  }, {});
};

const waterfallSelectParser = (val) => {
  return val
    ? { value: { [val.input.level1]: val.input.level2 || [] } }
    : undefined;
};
const numberParser = (val) => (val === '' ? undefined : Number(val));
const stringParser = (val) => {
  if (val !== undefined) {
    const s = String(val);

    if (s.trim() === '' || s === '') {
      return '';
    } else {
      return s;
    }
  }
};
const identityParser = (val) => val;

export const dateParser = (val) =>
  val && moment(val).endOf('day').startOf('second').toISOString();

export const yearParser = (val) =>
  val && moment(val).utc().startOf('year').toISOString();

const identityFormatter = (val) => val;
const numberFormatter = (val) => (val ? String(val) : '');
const stringFormatter = (val) => (val ? String(val) : '');
const yearFormatter = (val) =>
  val &&
  moment(val)
    .utc()
    // prevent issues with timezones
    .endOf('day')
    .toISOString();

const waterfallSelectFormatter = (val) => {
  return val
    ? {
        input: {
          level1: val?.value ? Object.keys(val?.value)[0] : undefined,
          level2: val?.value
            ? val?.value[Object.keys(val?.value)[0]]
            : undefined
        }
      }
    : undefined;
};

/** NOTE: FieldArray type fields don't support parse */
const makeParser = (fieldType, schema) => {
  const type = fieldType;

  switch (true) {
    case type === 'date':
      if (schema && JSON.parse(schema)?.format === FORMAT_C3_YEAR_DATE) {
        return yearParser;
      }
      return dateParser;
    case type === 'number':
      return numberParser;
    case type === 'string':
    case type === 'email':
    case type === 'textarea':
      return stringParser;
    case type === 'waterfall_select':
      return waterfallSelectParser;
    default:
      return identityParser;
  }
};

/** NOTE: FieldArray type fields don't support format */
const makeFormatter = (fieldType, schema) => {
  const type = fieldType;

  switch (true) {
    case type === 'number':
      return numberFormatter;
    case type === 'date':
      if (schema && JSON.parse(schema)?.format === FORMAT_C3_YEAR_DATE) {
        return yearFormatter;
      }
      return stringFormatter;
    case type === 'string':
    case type === 'email':
    case type === 'textarea':
      return stringFormatter;
    case type === 'waterfall_select':
      return waterfallSelectFormatter;
    default:
      return identityFormatter;
  }
};

const getFields = (category) => {
  return (
    category &&
    ((category.metadata_fields || category.keys) as MetadataFormField[])
  );
};

const getFieldString = (category) =>
  category && category.hasOwnProperty('keys') ? 'key' : 'field';

const fieldOwnerCache = new Map();
export const clearFieldOwnerCache = () => {
  fieldOwnerCache.clear();
};

// add validators, additional data props, etc
export const useFormatMetadataCategory = (
  category: MetadataCategory | ContractKeyGroup,
  /** force to be required (exp: youtube export fields) */
  forceRequired = false
) => {
  return React.useMemo(() => {
    const fields = getFields(category);
    const fieldString = getFieldString(category);

    if (fields) {
      return fields.map((field) => {
        const isRequired = field.required || forceRequired;

        const id = `${fieldString}_${field.id}`;

        if (!fieldOwnerCache.has(id)) {
          fieldOwnerCache.set(id, { owner: category.id });
        }

        const foc = fieldOwnerCache.get(id);

        foc.owner = category.id;

        return {
          ...field,
          id: id, // workaround because redux form doesn't allow numeric field names
          name: `${fieldString}_${field.id}`, // workaround because redux form doesn't allow numeric field names
          label: field.display_name,
          data: makeData(field),
          validate: makeValidator(
            isRequired,
            field.json_schema,
            field.value_type
          ),
          parse: makeParser(field.value_type, field.json_schema),
          format: makeFormatter(field.value_type, field.json_schema),
          disabled: field.read_only,
          required: isRequired,
          owner: foc
        };
      });
    }
  }, [category, forceRequired]);
};

export const getDefaultValueFromSchema = (schema: string) => {
  return schema && JSON.parse(schema)?.default;
};

// build initialValues object
// dehydrate some values (comma separated -> array)
export const useFormatMetadataValues = (
  values: Array<MetadataValue | ContractValue>,
  category: MetadataCategory | ContractKeyGroup,
  useDefault = false
) => {
  return React.useMemo(() => {
    const fields = getFields(category);
    const fieldString = getFieldString(category);
    const isMetaData = category?.type === 'metadata-category';

    const fieldIds: string[] = fields?.map((field) => field.id) || [];

    const retrievedValues: { [field_id: string]: unknown } =
      fields &&
      values &&
      values
        .filter((value) => fieldIds.indexOf(value[`${fieldString}_id`]) > -1)
        .reduce(
          // eslint-disable-next-line no-sequences
          (obj, value) => {
            const fieldType = fields.filter(
              (f) => f.id === value[`${fieldString}_id`]
            )[0].value_type;
            obj[`${fieldString}_${value[`${fieldString}_id`]}`] = hydrateValue(
              value.value,
              fieldType,
              isMetaData
            );
            return obj;
          },
          {}
        );

    if (!useDefault) {
      return {
        ...fieldIds.reduce((acc, val) => {
          acc[`${fieldString}_${val}`] = undefined;
          return acc;
        }, {}),
        ...retrievedValues
      };
    }

    const fieldsWithDefaultValues = fields?.filter(
      (f) => !!getDefaultValueFromSchema(f.json_schema)
    );
    const defaultValuesFromSchema = fieldsWithDefaultValues?.reduce(
      (obj: { [field_id: string]: any }, field) => {
        const fieldType = field.value_type;
        obj[`${fieldString}_${field.id}`] = hydrateValue(
          getDefaultValueFromSchema(field.json_schema),
          fieldType,
          isMetaData
        );
        return obj;
      },
      {}
    );

    // merge retrieved values and default values from schema
    const merged: { [field_id: string]: any } = retrievedValues || {};
    if (Object.keys(defaultValuesFromSchema)?.length) {
      Object.keys(defaultValuesFromSchema).forEach((field_id) => {
        if (!merged.hasOwnProperty(field_id)) {
          merged[field_id] = defaultValuesFromSchema[field_id];
        }
      });
    }

    return merged;
  }, [values, category]);
};

// use for debug page: add validators, additional data props, etc
export const useFormatMetadataCategories = (
  categories: Array<MetadataCategory | ContractKeyGroup>
) => {
  return React.useMemo(() => {
    return categories.reduce((acc, category) => {
      const fields = getFields(category);
      const fieldString = getFieldString(category);

      if (fields) {
        return [
          ...acc,
          fields.map((field) => {
            return {
              ...field,
              id: `${fieldString}_${field.id}`, // workaround because redux form doesn't allow numeric field names
              name: `${fieldString}_${field.id}`, // workaround because redux form doesn't allow numeric field names
              label: field.display_name,
              data: makeData(field),
              validate: makeValidator(
                field.required,
                field.json_schema,
                field.value_type
              ),
              parse: makeParser(field.value_type, field.json_schema),
              format: makeFormatter(field.value_type, field.json_schema),
              disabled: field.read_only
            };
          })
        ];
      }
      return acc;
    }, []);
  }, [categories]);
};

export interface FormatedMetadataField extends MetadataField {
  name: string;
  label: string;
  disabled: boolean;
  data: unknown;
  validate(): void | {};
  parse(val: unknown): unknown;
  format(val: unknown): unknown;
}
