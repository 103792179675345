import { useContext, useEffect, useRef } from 'react';
import { shortCutContext } from './ShortCutsProvider';

export interface ShortCutConfig {
  keyCode: string;
  ctrl?: boolean;
  alt?: boolean;
  shift?: boolean;
  meta?: boolean;
  option?: boolean;
  cmd?: boolean;
  action?(ev: KeyboardEvent, next: () => void): void;
  disabled?: boolean;
  help?: string;
  icon?: string;
}

let ID = 0;

export const useShortCut = ({
  keyCode,
  ctrl,
  alt,
  shift,
  meta,
  option,
  cmd,
  action,
  disabled,
  help,
  icon
}: ShortCutConfig) => {
  const { registerKey, unregisterKey } = useContext(shortCutContext);

  const id = useRef(null);

  useEffect(() => {
    if (!id.current) {
      id.current = ID++;
    }
  }, []);

  useEffect(() => {
    if (!disabled) {
      const currentId = id.current;
      const modifiers = { ctrl, alt: alt || option, shift, meta: meta || cmd };

      registerKey({
        id: currentId,
        keyCode: keyCode,
        action,
        modifiers,
        help,
        icon
      });

      return () => {
        unregisterKey({ id: currentId, keyCode: keyCode, action, modifiers });
      };
    }
  }, [
    keyCode,
    ctrl,
    alt,
    shift,
    meta,
    option,
    cmd,
    action,
    disabled,
    registerKey,
    unregisterKey,
    help
  ]);
};
