import { capitalize } from '@material-ui/core';
import { isMacOs } from 'react-device-detect';
import { makeIdentifier } from './utils';

export const keyImages = {
  Period: '.',
  Comma: ',',
  ArrowRight: '→',
  ArrowLeft: '←',
  ArrowUp: '↑',
  ArrowDown: '↓',
  Enter: '⏎',
  option: '⌥',
  command: '⌘',
  ctrl: isMacOs ? '⌃' : '',
  PageDown: isMacOs ? '↓' : 'PgDn',
  PageUp: isMacOs ? '↑' : 'PgUp',
  Space: '␣ Space',
  shift: '⇧',
  Delete: '⌦ Delete'
};

export const keyWidths = {
  Space: 120,
  Delete: 80,
  Enter: 80,
  Backspace: 100,
  option: 70,
  command: 95,
  shift: 75
};

export const macKeys = {
  alt: 'option',
  meta: 'command'
};

export const documentedShortCuts = {
  'Workspace view': {},
  'Inside folders': {},
  'Detail view': {},
  'Video previewer': {}
};

export const makeTips = (key, modifiers = {}) => {
  let modifierKeys = modifiers;
  if (isMacOs) {
    if (['PageUp', 'PageDown'].includes(key)) {
      modifierKeys['Fn'] = true;
    }
    Object.keys(modifierKeys).forEach((k) => {
      if (modifierKeys[k] && macKeys[k]) {
        modifierKeys[k] = false;
        modifierKeys[macKeys[k]] = true;
      }
    });
  }
  const modKeys = Object.keys(modifierKeys).filter((k) => modifierKeys[k]);

  const keyCode = keyImages[key] || key;

  return modKeys.length === 0
    ? capitalize(keyCode)
    : `${modKeys
        .map((k) => keyImages[k] || capitalize(k))
        .sort()
        .join('+')}+${capitalize(keyCode)}`;
};

export const documentShortcut = (shortCut, category) => {
  let { ctrl, shift, alt, meta, cmd, option } = shortCut;
  alt = alt || option;
  meta = meta || cmd;
  const id = makeIdentifier(shortCut.keyCode, { ctrl, shift, alt, meta });
  documentedShortCuts[category] = documentedShortCuts[category] || {};
  documentedShortCuts[category][id] = shortCut;
  const tip = makeTips(shortCut.keyCode, { ctrl, shift, alt, meta });
  //console.log(shortCut.keyCode, `(${tip})`);

  return { ...shortCut, tip };
};

export const logShortcuts = () => {
  const categories = Object.keys(documentedShortCuts);

  const log = categories.flatMap((c) => {
    const entries = Object.entries(documentedShortCuts[c]);
    return entries.map((e) => {
      const [primary, ...modifiers] = e[0].split(/__/).filter(Boolean);

      return {
        category: c,
        key: primary,
        modifiers: modifiers
          .map((m) => (isMacOs ? macKeys[m] || m : m))
          .join(' + '),
        desc: e[1]['help']
      };
    });
  });

  // eslint-disable-next-line
  console.table(log, ['category', 'key', 'modifiers', 'desc']);
};

// setTimeout(logShortcuts, 2000);
