import { Checkbox } from '@cube3/cubicle/src/core/atoms/Checkbox/Checkbox';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { HBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback, useId } from 'react';
import MaybeTooltip from '../../../Tooltip/MaybeTooltip';
import { generateFileNameTooltip } from '../../../library/Downloads/DownloadItemRowUI';

const useStyles = makeStyles(() =>
  createStyles({
    formatRow: {
      paddingTop: 8,
      paddingBottom: 4
    }
  })
);

const ShareDownloadCheckBoxWithTooltip = ({
  preset,
  group,
  selectedFormats,
  setSelectedFormats,
  rootClass = ''
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (group: string, preset) => {
      const isChecked = !!selectedFormats[group]?.find(
        (n) => n.id === preset.id
      );

      if (isChecked) {
        setSelectedFormats((prev) => ({
          ...prev,
          [group]: prev[group].filter((v) => v.id !== preset.id)
        }));
      } else {
        setSelectedFormats((prev) => {
          if (!prev[group]) {
            return {
              ...prev,
              [group]: [preset]
            };
          }
          return {
            ...prev,
            [group]: [...prev[group], preset]
          };
        });
      }
    },
    [selectedFormats, setSelectedFormats]
  );

  const id = useId();

  return (
    <MaybeTooltip
      key={preset.id}
      disabled={group === 'default'}
      title={generateFileNameTooltip(preset)}
      placement={'bottom'}
    >
      <div style={{ width: '100%' }} className={rootClass}>
        <HBox gap={8} justify="start">
          <Checkbox
            size={SizeVariants.sm}
            value={!!selectedFormats[group]?.find((f) => f?.id === preset.id)}
            onChange={() => handleChange(group, preset)}
            labeledBy={id}
          />
          <Typography variant="md" colorVariant="03">
            <label id={id}>{preset.attributes.display_name}</label>
          </Typography>
        </HBox>
      </div>
    </MaybeTooltip>
  );
};

export default ShareDownloadCheckBoxWithTooltip;
