import { useEffect, useRef } from 'react';

import { useLocation, MemoryRouter } from 'react-router-dom';

export const ScrollbarsFallbackRouter = MemoryRouter;
export const useRememberScrollbar = ({
  resetScroll,
  rememberScroll,
  containerRef,
  scrollPosition
}) => {
  const location = useLocation();

  const scrollKey = location?.pathname;

  const lastPosition = useRef(scrollPosition);
  lastPosition.current = scrollPosition;

  useEffect(() => {
    if (resetScroll && rememberScroll) {
      throw new Error('conflicting props: resetScroll, rememberScroll');
    }
    if (resetScroll) {
      containerRef?.scrollTop(0);
      return;
    }
    if (rememberScroll !== true && rememberScroll !== 'load') {
      return;
    }

    const scrollStore = JSON.parse(
      sessionStorage.getItem('scrollPositions') || 'null'
    );

    const loadedPosition =
      scrollStore &&
      parseInt(
        scrollStore.find((s) => s.split('::')[0] === scrollKey)?.split('::')[1]
      );

    if (loadedPosition) {
      containerRef?.scrollTop(loadedPosition);
    }
  }, [scrollKey, containerRef, rememberScroll, resetScroll]);

  useEffect(() => {
    const store = () => {
      if (rememberScroll !== true && rememberScroll !== 'save') {
        return;
      }
      const scrollPos = lastPosition.current;

      const scrollStore =
        JSON.parse(sessionStorage.getItem('scrollPositions') || 'null') || [];

      if (scrollPos !== undefined && scrollPos !== null) {
        // value 0 just clears the item from storage
        if (scrollPos === 0) {
          sessionStorage.setItem(
            'scrollPositions',
            JSON.stringify(
              [
                ...scrollStore.filter((s) => s.split('::')[0] !== scrollKey)
              ].slice(0, 10)
            )
          );
        } else {
          sessionStorage.setItem(
            'scrollPositions',
            JSON.stringify(
              [
                `${scrollKey}::${scrollPos?.toString()}`,
                ...scrollStore.filter((s) => s.split('::')[0] !== scrollKey)
              ].slice(0, 10)
            )
          );
        }
      }
    };

    window.addEventListener('beforeunload', store);
    return () => {
      store();
      window.removeEventListener('beforeunload', store);
    };
  }, [rememberScroll, scrollKey, containerRef]);
};
