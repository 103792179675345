import { cx } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { SizeVariants } from '../../../theme/themes';
import { makeCSS } from '../../../utils/makeCSS';
import { Icon } from '../Icon';

/**Checkbox allows the user to select one or more items from a set or an option by checking a checkbox to state their choice as well as to toggle between a checked, unchecked, and optional indeterminate state. */
export interface CheckBoxProps {
  /**Didderent sizes */
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
  /**Indicates whether the checkBox is checked or not*/
  value?: boolean;
  /** @deprecated use "value" instead. Indicates whether the checkBox is checked or not*/
  state?: boolean;
  /**Indicates whether it has been a change at the coomponent*/
  onChange?(newState: boolean): void;
  /**Status of the checkbox, whether it can be used or not*/
  disabled?: boolean;
  /**When inderterminate is true the checkBox does not use the check icon */
  indeterminateValue?: boolean;
  /** label used aria */
  label?: string;
  /** id of label element */
  labeledBy?: string;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      cursor: 'pointer',
      position: 'relative',
      display: 'inline-box',
      padding: 0,
      flexShrink: 0,
      borderRadius: theme.borderRadius.md,
      overflow: 'hidden',
      boxSizing: 'border-box',

      '&:focus': {
        outline: 'none',
        boxShadow: `0px 0px 0px 4px rgba(255, 212, 35, 0.12)`
      },

      '&.disabled': {
        cursor: 'default',
        opacity: '0.4',
        '&:focus': {
          boxShadow: 'none'
        }
      },
      '& svg': {
        position: 'absolute',
        top: -1,
        left: -1
      }
    },

    icon: {
      pointerEvents: 'none'
    },

    unchecked_style: {
      background: theme.color.underlay['01'],
      border: `1px solid ${theme.color.line['02']}`,
      '&:hover': {
        background: theme.color.overlay.hovered,
        border: `1px solid ${theme.color.line['02']}`
      },
      '&:focus': {
        border: `1px solid ${theme.color.foreground.primary}`
      },
      '&.disabled': {
        cursor: 'default',
        border: `1px solid ${theme.color.line['02']}`,
        '&:hover': {
          background: theme.color.underlay['01'],
          border: `1px solid ${theme.color.line['02']}`
        }
      }
    },
    checked_style: {
      background: theme.color.background.primary,
      border: `1px solid ${theme.color.background.primary}`,
      '&:hover': {
        background: theme.color.yellow[40]
      },
      '&.disabled': {
        cursor: 'default',
        border: `1px solid ${theme.color.line['03']}`,
        background: theme.color.background.secondary,
        '&:hover': {
          background: theme.color.background.secondary
        }
      }
    },
    'size-sm': {
      width: theme.spacing[4],
      height: theme.spacing[4]
    },
    'size-md': {
      width: theme.spacing[5],
      height: theme.spacing[5]
    },
    'size-lg': {
      width: theme.spacing[6],
      height: theme.spacing[6]
    }
  };
});

export const Checkbox = (props: CheckBoxProps) => {
  const {
    size = SizeVariants.lg,
    state,
    value = state,
    onChange,
    disabled,
    indeterminateValue = false,
    label,
    labeledBy
  } = props;

  const classes = useCSS();

  const [checked, setChecked] = useState(value || false);
  const [inderteminateClickCount, setInderteminateClickCount] = useState(0);

  useEffect(() => {
    setChecked(value || false);
  }, [value]);

  const handleClick = () => {
    //when it's disabled it shouldn't be able to have click events
    if (disabled) {
      console.log('checkBox disabled');
    } else {
      if (props.indeterminateValue) {
        console.log('handleIndeterminateClick: ');
        if (inderteminateClickCount === 0) {
          //when inderteminateClickCount is 0 the we use the inderterminate icon
          setInderteminateClickCount(1);
        } else if (inderteminateClickCount === 1) {
          //when inderteminateClickCount is 1 the we use the check icon
          setInderteminateClickCount(2);
        } else if (inderteminateClickCount === 2) {
          //when inderteminateClickCount is 2  the checkbox is empty
          setInderteminateClickCount(1);
        }
        setChecked(!checked);
        if (onChange) {
          onChange(!checked);
        }
      } else {
        setChecked(!checked);
        if (onChange) {
          onChange(!checked);
        }
      }
    }
  };

  //when key is pushed
  const handleKeyDown = (event) => {
    //when ENTER and SPACE are pushed
    //   //keycode for enter is 13
    //   //keycode for space is 32
    if (event.keyCode === 13 || event.keyCode === 32) {
      handleClick();
    }
  };

  return (
    <div>
      {indeterminateValue ? (
        <div
          role="checkbox"
          aria-label={label}
          aria-labelledby={labeledBy}
          aria-checked={checked} //it needs to be changed dynamically when the checkbox is activated
          tabIndex={0} //helps making the checkbox focusable
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={cx(
            { disabled: disabled },
            classes.root,
            inderteminateClickCount === 2
              ? classes.unchecked_style
              : classes.checked_style,
            classes[`size-${size}`]
          )}
        >
          {inderteminateClickCount != 2 ? (
            <div>
              {inderteminateClickCount === 0 ? (
                <div className={classes.icon}>
                  <Icon icon="indeterminate" color="light" size={size} />
                </div>
              ) : (
                <div className={classes.icon}>
                  <Icon icon="check" color="light" size={size} />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div
          role="checkbox"
          aria-label={label}
          aria-labelledby={labeledBy}
          aria-checked={checked}
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={cx(
            { disabled: disabled },
            classes.root,
            checked === false ? classes.unchecked_style : classes.checked_style,
            classes[`size-${size}`]
          )}
        >
          {checked && (
            <div className={classes.icon}>
              <Icon icon="check" color="light" size={size} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
