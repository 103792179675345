import * as React from 'react';
// import ui
import { LinkResentModalUI } from '@cube3/ui/src/Prefabs/Modals/Sharables/LinkResentModalUI';

// interfaces
import { ShareIntentType } from '@cube3/state/types';
import { useModalActions } from '../../../layout/Modals/modalActions';
import { useLinkSettingsActions } from '../../FileRequest/hooks/useLinkSettingsActions';

interface PublicProps {}

type ModalReceiverProps = {
  modalContext: {
    intent: ShareIntentType;
    isResent: boolean;
    // share & review-link
    token?: string;
    password?: string;
    passwordEnabled?: boolean;
    notNew?: boolean;
  };
};
type Properties = PublicProps & ModalReceiverProps;

const ConfirmationLinkSent: React.FunctionComponent<Properties> = (props) => {
  const { modalContext } = props;
  const {
    password,
    token,
    intent,
    passwordEnabled,
    isResent = false,
    notNew
  } = modalContext;

  const { closeAllModals } = useModalActions();

  const { onClickOpenLink, onCopyLink } = useLinkSettingsActions({
    intent,
    token
  });

  let title = notNew ? 'Link info' : isResent ? 'Link resent' : 'Link sent';

  if (intent === 'share' && password) {
    if (passwordEnabled) {
      title = 'Password changed';
    } else {
      title = 'Password set';
    }
  }

  return (
    <LinkResentModalUI
      title={title}
      password={password}
      isResend={isResent}
      showOpenPersonalLink={!!token}
      onClickOpenLink={onClickOpenLink}
      onCloseEvent={closeAllModals}
      onCopyLink={onCopyLink}
      message={notNew ? 'Visit or copy your link' : undefined}
    />
  );
};

/**
 * @summary Prompts the user that the link has been sent and provides the ability to open the link.
 * @author Simon
 */
export default ConfirmationLinkSent;
