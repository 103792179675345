import { iconDuplicate } from '@cube3/cubicle/src/icons/GeneralSVG';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FontWeightProperty } from 'csstype';
import * as React from 'react';
import Button from '../../../Buttons/Button';
import { iconOpenExternal } from '../../../icons/GeneralSVG';
import { ModalNoticeUI } from '../../../Modal/ModalNoticeUI';
import { Divider } from '../../../Surface/Divider';
import { Typography } from '../../../typography/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    mainText: {
      marginBottom: theme.customSpacing.margin[16]
    },
    password: {
      marginBottom: theme.customSpacing.margin[16],
      fontWeight: theme.typography.fontWeightBold as FontWeightProperty
    },
    divider: {
      marginBottom: theme.customSpacing.margin[16]
    }
  })
);

interface Properties {
  title: string;
  onCloseEvent: () => void;
  onClickOpenLink?: () => void;
  onCopyLink?: () => void;
  people?: string[];
  /** use for sharelink to notify password changes */
  isResend?: boolean;
  showOpenPersonalLink?: boolean;
  password?: string;
  message?: string;
}

const message_share_password =
  'Please share this password with the recipients of this share link now. Cube will not show it again.';
const message_password_changed = `You’ve set a new password on this share link. ${message_share_password}`;

const LinkResentModalUIC: React.FC<Properties> = (props) => {
  const {
    onCloseEvent,
    onClickOpenLink,
    onCopyLink,
    people = undefined,
    title,
    isResend,
    password,
    showOpenPersonalLink = true,
    message
  } = props;

  const classes = useStyles();

  const mainText = message
    ? message
    : people && people.length === 1
    ? `We will send ${people[0]} a link to the share.`
    : `We will send the selected recipients links to the share.`;

  /*if resending personal link to a member, we don't want to show the password messages */
  const resendLinkMessage = password ? message_password_changed : mainText;

  const sendLinkMessage =
    mainText + `${password ? message_share_password : ''}`;

  return (
    <ModalNoticeUI
      title={title}
      onCloseEvent={onCloseEvent}
      footerRightComponent={
        <div data-cy={'notice'}>
          <Button
            colorVariant={'filled1'}
            text={'Done'}
            onClick={onCloseEvent}
          />
        </div>
      }
    >
      <Typography className={classes.mainText}>
        {isResend ? resendLinkMessage : sendLinkMessage}
      </Typography>

      {password && (
        <Typography className={classes.password}>{password}</Typography>
      )}

      {password && !isResend && <Divider className={classes.divider} />}

      {!isResend && showOpenPersonalLink && (
        <>
          <Button
            path={iconOpenExternal}
            text={'Open link'}
            onClick={onClickOpenLink}
            marginCollapse={'left'}
            colorVariant={'ghost1'}
          />
          <Button
            size="small"
            path={iconDuplicate}
            onClick={onCopyLink}
            text="Copy link"
            colorVariant={'ghost1'}
          />
        </>
      )}
    </ModalNoticeUI>
  );
};

/**
 * @summary `Modal Notice` that prompts the user that the link is resent to an X amount of people, and provides the ability to open the sent link.
 */
const LinkResentModalUI = LinkResentModalUIC;
export { LinkResentModalUI };
