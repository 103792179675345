import { ContentTreeNode } from '@cube3/common/model/schema/resources/content-tree-node';
import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Share } from '@cube3/common/model/schema/resources/share';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';
import { VerifyType } from '@cube3/state/types';
import { generateValidPassword } from '@cube3/ui/src/Prefabs/shareLink/Forms/fields/PasswordFieldComponent';
import { passwordValidator } from '../../../../../forms/helpers/validators';
import { getOffsetDate } from './getOffsetDate';

const DOWNLOAD_TYPE = {
  DOWNLOAD_TYPE_ORIGINAL: 'Original',
  DOWNLOAD_TYPE_PREVIEW: 'Preview'
} as const;

/**
 * map Workspace resource attributes and other defaults to form values
 */
const defaultsLookup: Record<
  string,
  (
    ws: Workspace,
    intent: Share['intent'],
    renderPresets: RenderPreset[],
    n: ContentTreeNode
  ) => any
> = {
  display_name: (workspace, intent, renderPresets, node) => node?.display_name,
  who_can_access: (workspace) =>
    workspace.default_shares_verify_type === VerifyType.recipients_only
      ? 'recipients_only'
      : 'everyone',
  require_verification: (workspace) =>
    workspace.default_shares_verify_type !== VerifyType.never,
  add_message: (workspace) => false,
  expires: (workspace) => workspace.default_shares_expiration_enabled,
  expires_at: (workspace, intent) => getOffsetDate(workspace, intent),
  recipients: (workspace) => [],
  allow_downloads: (workspace) => workspace.default_shares_download_enabled,
  download_presets: (workspace, intent, renderPresets) => {
    const workspacePreferedDownloadTypeInShare =
      DOWNLOAD_TYPE[workspace.default_shares_download_type];

    renderPresets.filter(
      (r) => r.display_name === workspacePreferedDownloadTypeInShare
    );
  },
  set_password: (workspace) => workspace.default_shares_password_enabled,
  password: (workspace) => generateValidPassword(passwordValidator, 6)
};

export const valueFromDefaults = (
  { workspace, intent, renderPresets, node },
  a
) => {
  return defaultsLookup[a]?.(workspace, intent, renderPresets, node);
};
