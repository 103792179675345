import React, { EventHandler, useState } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { cx } from '@emotion/css';
import { Typography } from '../../atoms/Typography';
import Truncate from '../../utility/Truncate';

/**
 * A visual indicator for the different options of rating.
 * We use this component for the rating of an asset.
 */
export interface SelectOptionProps extends React.PropsWithChildren {
  /** the selected value */
  value: string;
  onClick?: EventHandler<React.MouseEvent>;
  /**Whether its active/responsive or not */
  disabled?: boolean;
  /** start `InputAdornment` for input component. */
  prefix?: React.ReactNode;
  /** start `InputAdornment` for input component. */
  startAdornment?: React.ReactNode;
  /** end `InputAdornment` for input component */
  endAdornment?: React.ReactNode;
}

export const useOptionCSS = makeCSS(({ theme }) => {
  return {
    root: {
      userSelect: 'none',
      cursor: 'pointer',
      display: 'flex',
      width: '100%',
      height: theme.spacing[10],
      padding: theme.spacing[2],
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: theme.borderRadius.lg,
      boxSizing: 'border-box',
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:active': {
        background: theme.color.overlay.pressed
      },
      '&.disabled': {
        cursor: 'default',
        opacity: 0.4,
        '&:hover': {
          background: 'none'
        }
      }
    },
    spacing: {
      width: 'inherit',
      paddingLeft: theme.spacing[2]
    },
    endAdornment: {
      marginLeft: 'auto'
    }
  };
});

export const Option = (props: SelectOptionProps) => {
  const {
    value = 'pending',
    startAdornment,
    endAdornment,
    onClick,
    disabled,
    children
  } = props;

  const [container, setContainer] = useState<HTMLDivElement>(null);

  const str = value.trim().charAt(0).toUpperCase() + value.slice(1);
  const padding = getPadding(container);
  const containerWidth = container?.getBoundingClientRect().width;

  const classes = useOptionCSS();

  return (
    <div
      className={cx(classes.root, {
        disabled: disabled
      })}
      onClick={onClick}
      ref={setContainer}
      data-pw={value + '__option'}
    >
      {startAdornment}
      <Typography variant="md" colorVariant="01" weight="body">
        <div className={`${classes.spacing}`}>
          {children ? (
            children
          ) : container ? (
            <Truncate
              str={str}
              widthLimit={containerWidth - padding}
              toolTip={true}
              cachePrefix="option"
            />
          ) : (
            str
          )}
        </div>
      </Typography>
      {endAdornment ? (
        <div className={classes.endAdornment}>{endAdornment}</div>
      ) : null}
    </div>
  );
};
const toNumber = (wid: string | number) =>
  typeof wid === 'number' ? wid : Number((wid || '').replace('px', ''));
const getPadding = (div: Element) => {
  if (!div) return 0;
  const { paddingLeft, paddingRight } = window.getComputedStyle(div);
  return toNumber(paddingLeft) + toNumber(paddingRight);
};
