// #region imports
import * as React from 'react';

// helpers
import { compose } from '../../../../../utils/component-helpers';

// HOCS
import { withModalActions, ModalActionProps } from '../../Modals/modalActions';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import {
  withRouter,
  RouteComponentProps,
  generatePath
} from 'react-router-dom';

import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';

// UI
import DeleteProjectModalUI from '@cube3/ui/src/Projects/Modals/DeleteProjectModalUI';
import { Project } from '@cube3/common/model/schema/resources/project';
import { useSelectionActions } from '@cube3/state/src/redux/components/useSelection';

// #endregion

/**
 * @summary: Modal for deleting a project, redirects user to project overview when deleted project
 * @author Simon
 */

// #region interfaces
type ModalContextType = {
  project?: Project;
  projects?: Project[];
  workspaceId: string;
};
type Properties = ModalActionProps & ModalReceiverProps & RouteComponentProps;
// #endregion

const emptyArray: Project[] = [];

function DeleteProjectModal(props: Properties): JSX.Element {
  const { previousModal, closeAllModals, modalContext } = props;
  const { clearItems, clearActiveItem } = useSelectionActions();

  // we get the resource info from the project scope header.

  // we expect that the previous modal (scope header) gives us the project info
  const {
    project,
    projects = emptyArray,
    workspaceId
  } = modalContext as ModalContextType;

  const projectsToDelete = React.useMemo(
    () => [project, ...projects].filter(Boolean),
    [project, projects]
  );

  const [deleteResource] = useDeleteResource__ALPHA({
    cacheInvalidator: React.useCallback(
      () => [
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'projects'
        },
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'search'
        }
      ],
      [workspaceId]
    )
  });

  return (
    <DeleteProjectModalUI
      projectCount={projectsToDelete.length}
      projectName={
        projectsToDelete?.length > 1
          ? undefined
          : projectsToDelete?.[0].display_name
      }
      onClickDelete={() => {
        if (projectsToDelete?.length) {
          // clear selection
          clearItems();
          clearActiveItem();

          projectsToDelete.forEach((p) => {
            deleteResource({
              type: 'project',
              id: p.id
            });
          });
        } else {
          console.warn('No project id for project to delete');
          return;
        }

        closeAllModals();

        // generate a path for the project overview
        const newPath: string = generatePath('/workspace/:ws/projects', {
          ws: workspaceId
        });

        // redirect to project root, since the project is deleted and no longer available.
        props.history.push(newPath);
      }}
      onCloseEvent={() => previousModal()}
    />
  );
}

export default compose(DeleteProjectModal)(withRouter, withModalActions);
