import React from 'react';
import { getPopupSubText, getTextClass } from './helpers';
import { UploadUIStates } from './types';
import { Typography } from '../../typography/Typography';
import { Time } from '../../human';
import { makeCSS } from '@cube3/cubicle/src/utils/makeCSS';

interface Props {
  uploadState: UploadUIStates;
  eta: number;
  isHovered: boolean;
  gridview: boolean;
  progress?: number;
}

const useCSS = makeCSS(({ theme }) => ({
  defaultText: {
    textAlign: 'left'
  },
  uploadText: {
    color: theme.color.background.primary,
    textAlign: 'left'
  },
  uploadTime: {
    color: theme.color.text['01'],
    fontSize: '10px',
    textAlign: 'left'
  },
  uploadTextError: {
    color: theme.color.background.critical,
    textAlign: 'left'
  }
}));

export const ProgressEta: React.FunctionComponent<Props> = (props) => {
  let { gridview, uploadState, isHovered, eta, progress } = props;

  const classes = useCSS();

  let text = getPopupSubText(isHovered, uploadState);
  let textClass = getTextClass(isHovered, uploadState, classes);

  //#region determine some edge cases for the upload texts.
  if (isHovered && uploadState === 'uploading') {
    text = 'Click to pause upload. ';
    textClass = classes.defaultText;
  }

  if (isHovered && uploadState === 'paused') {
    text = 'Click to resume upload. ';
    textClass = classes.defaultText;
  }

  if (isHovered && uploadState === 'upload_pending') {
    text = 'Click to start upload. ';
    textClass = classes.defaultText;
  }

  //#region determine text class
  if (
    uploadState === 'upload_failed' ||
    uploadState === 'upload_initialization_failed' ||
    uploadState === 'upload_missing' ||
    (uploadState === 'paused' && !isHovered)
  ) {
    textClass = classes.uploadTextError;
  }

  return (
    gridview && (
      <React.Fragment>
        <Typography typographyStyle={'body2'} className={textClass}>
          {text}
        </Typography>
        {uploadState === 'uploading' &&
          eta &&
          eta !== Infinity &&
          progress !== 100 && (
            <Typography
              typographyStyle={'body2'}
              className={classes.uploadTime}
            >
              {' '}
              Done{' '}
              <Time
                date={new Date(eta)}
                fullForm={false}
                toolTip={false}
                placement="bottom-start"
                now={new Date()}
                relativeTime={true}
              />
            </Typography>
          )}
      </React.Fragment>
    )
  );
};
