import React, { useCallback } from 'react';
//
import { addFormId } from '../middleware/redux-form-middleware';
import { useSelection } from './useSelection';
import { useModalActions } from '../Hooks/useModalActions';
import { useCreateResource__ALPHA } from './Hooks/useCreateResource';
import {
  RelationshipIdentifier,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { useCurrentWorkspace } from './Administration/withCurrentWorkspace';

/// <Summary>
/// Helper wrapper component that takes care of the mapping for you to with connecting
/// to redux
/// </Summary>

export interface AddFolderActionProps {
  addFolder: (folderName: string, formId?: string) => void;
  canAddFolder: boolean;
  openAddFolderPrompt: () => void;
}

interface PrivateProps extends AddFolderActionProps {}

const withAddFolderActions =
  (WrappedComponent) =>
  <T extends PrivateProps>({ ...props }: T) => {
    const { canAddFolder, handleOpenAddFolderModal, handleAddFolder } =
      useAddFolderActions();

    return (
      <WrappedComponent
        openAddFolderPrompt={handleOpenAddFolderModal}
        addFolder={handleAddFolder}
        canAddFolder={canAddFolder}
        {...props}
      />
    );
  };

export const useAddFolderActions = (
  overrideFolder?: ResourceIdentifier,
  invalidators?: Array<ResourceIdentifier | RelationshipIdentifier>
) => {
  const selection = useSelection(true);
  const { openModal } = useModalActions<'add_folder'>();
  const currentFolder = overrideFolder || selection?.visiting;

  const canAddFolder =
    selection && selection.isVisiting && selection.visiting.type === 'folder';

  const handleOpenAddFolderModal = useCallback(
    () => window.setTimeout(() => openModal('add_folder'), 500), // TODO: clean up contextmenu focus stealing issues
    [openModal]
  );

  const [workspaceId] = useCurrentWorkspace();
  const [createFolder] = useCreateResource__ALPHA({
    resourceType: 'folder',
    ancestor: { type: 'folder', id: currentFolder?.id },
    relationship: 'folders',
    actionDecorators: [
      addFormId('addFolder', {
        formState: undefined,
        useRequestStatus: true,
        delegate: true
      })
    ],
    cacheInvalidator: (res, anc, rel) => [
      { type: anc.type, id: anc.id, relationship: 'folders' },
      { type: 'workspace', id: workspaceId, relationship: 'search' },
      ...(invalidators || [])
    ]
  });

  const handleAddFolder = useCallback(
    (folderName: string) => {
      createFolder({
        type: 'folder',
        display_name: folderName
      });
    },
    [createFolder]
  );

  return {
    canAddFolder,
    handleOpenAddFolderModal,
    handleAddFolder
  };
};

export default (WrappedComponent) => withAddFolderActions(WrappedComponent);
