import { add } from '@cube3/ui/src/exports/subcomponents/MultiStringInput/utils/add';
import { Field, Validator, Value } from './useGenericExportOptions';
import { validate } from './utils/validate';

export enum ValidityState {
  Valid,
  Invalid,
  Warning,
  Validating
}

export interface GenericExportOptionsState {
  fields: readonly Field[];
  values: Value[];
  validators: Validator[];
  valid: ValidityState;
  errors: Error[];
  touched: string[];
}

export enum ActionTypes {
  CHANGE_VALUE = 'CHANGE_VALUE',
  INITIALIZE = 'INITIALIZE'
}

// TODO: improve strictness
type GenericExportOptionsPayloadTypes = Record<ActionTypes, any>;

export interface GenericExportOptionsAction<
  T extends ActionTypes = ActionTypes
> {
  type: T;
  payload?: GenericExportOptionsPayloadTypes[T];
}

export const genericExportOptionsReducer = (
  state: GenericExportOptionsState,
  action: GenericExportOptionsAction<ActionTypes>
) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE:
      return action.payload as GenericExportOptionsState;
    case ActionTypes.CHANGE_VALUE: {
      // build new values array, replacing previous entry for field
      const newValues = state.values
        .filter((v) => v.id !== action.payload.id)
        .concat({ id: action.payload.id, value: action.payload.value });
      const errors = validate(state.validators, newValues, state.fields);
      return {
        ...state,
        values: newValues,
        valid: !errors?.length,
        errors: errors,
        touched: add(state.touched, action.payload.id as string) as string[]
      };
    }
    default:
      return state;
  }
};
