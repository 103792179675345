import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { Password } from '@cube3/cubicle/src/core/atoms/InputField/Password';
import { HBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { generateValidPassword } from '@cube3/ui/src/Prefabs/shareLink/Forms/fields/PasswordFieldComponent';
import React, { useCallback } from 'react';
import { passwordValidator } from '../../../../forms/helpers/validators';

export const PasswordRow = (props) => {
  const { value, onChange, touched, label } = props;

  const onCopyPassword = useCallback(() => {
    if (value) {
      navigator.clipboard.writeText(value);
    }
  }, [value]);

  return (
    <HBox justify="start" align="baseline" gap={8}>
      <Password
        startVisible={true}
        value={value}
        onChange={onChange}
        label={!touched ? `●●●●●●` : `${label}...`}
      />

      <Button
        size={SizeVariants.sm}
        iconRight={'clipboard'}
        onClick={onCopyPassword}
        disabled={!value}
        buttonStyle="ghost"
        background="secondary"
      >
        Copy
      </Button>

      <Button
        buttonStyle="ghost"
        background="secondary"
        iconRight={'refresh'}
        size={SizeVariants.sm}
        onClick={() => onChange(generateValidPassword(passwordValidator))}
      >
        New
      </Button>
    </HBox>
  );
};
