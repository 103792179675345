import { Validator } from '../useGenericExportOptions';
import { getFieldValue } from './getFieldValue';

export const makeRequiredValidator = (fieldId) => {
  return ((values, fields) => {
    const field = fields.find((f) => f.id === fieldId);

    const hidden =
      typeof field.visible === 'function'
        ? !field.visible(values)
        : field.visible === false;

    if (hidden) {
      return undefined;
    }

    return [null, undefined, ''].includes(
      getFieldValue(values, fieldId)?.value as never
    ) ||
      (Array.isArray(getFieldValue(values, fieldId)?.value) &&
        (getFieldValue(values, fieldId)?.value as never[]).length === 0)
      ? [
          {
            field: fieldId,
            message: 'Required',
            code: 'ERROR_REQUIRED',
            type: 'error'
          }
        ]
      : undefined;
  }) as Validator;
};
