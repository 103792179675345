import { Share } from '@cube3/common/model/schema/resources/share';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';
import moment from 'moment';

export const getOffsetDate = (workspace: Workspace, intent: Share['intent']) =>
  moment()
    .endOf('day')
    .add(
      workspace
        ? intent === 'request'
          ? workspace.default_filerequests_expiration_duration
          : workspace.default_shares_expiration_duration
        : 30,
      'day'
    )
    .toISOString();
