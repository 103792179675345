import { documentShortcut } from '../../../keyboard-shortcuts/documentShortcut';
import { useCallback } from 'react';
import { useShortCut } from '../../../keyboard-shortcuts/useShortCut';

const shortCutPlay = documentShortcut(
  {
    keyCode: 'Space',
    help: 'Play / pause',
    icon: 'play'
  },
  'Video previewer'
);

const shortCutMute = documentShortcut(
  {
    keyCode: 'm',
    help: 'Mute / unmute audio',
    icon: 'volume_mute'
  },
  'Video previewer'
);

const shortCutJumpBack = documentShortcut(
  {
    keyCode: 'ArrowLeft',
    help: 'Go back 10 seconds',
    icon: 'replay_10'
  },
  'Video previewer'
);

const shortCutJumpForward = documentShortcut(
  {
    keyCode: 'ArrowRight',
    help: 'Go forward 10 seconds',
    icon: 'forward_10'
  },
  'Video previewer'
);

const shortCutFrameBack = documentShortcut(
  {
    keyCode: 'Comma',
    help: 'Go back 1 frame',
    icon: 'chevron_left'
  },
  'Video previewer'
);

const shortCutFrameForward = documentShortcut(
  {
    keyCode: 'Period',
    help: 'Go forward 1 frame',
    icon: 'chevron_right'
  },
  'Video previewer'
);

const shortCutLoop = documentShortcut(
  {
    keyCode: 'l',
    help: 'Toggle looped playback',
    icon: 'replay'
  },
  'Video previewer'
);

const shortCutFullscreen = documentShortcut(
  {
    keyCode: 'f',
    help: 'Enter / leave fullscreen',
    icon: 'expand_content'
  },
  'Video previewer'
);

export const videoPlayerShortCutsTips = {
  frameControls: {
    back: shortCutFrameBack.tip,
    forward: shortCutFrameForward.tip
  },
  playControls: {
    jumpBack: shortCutJumpBack.tip,
    jumpForward: shortCutJumpForward.tip,
    loop: shortCutLoop.tip,
    play: shortCutPlay.tip
  },
  fullscreen: shortCutFullscreen.tip,
  mute: shortCutMute.tip
};

export const useVideoPlayerKeyboardShortcuts = ({
  focus,
  otherModalsActive,
  playPause,
  toggleMute,
  jumpBack,
  jumpForward,
  toggleLoop,
  toggleFullscreenAction,
  stepFrame
}) => {
  // #region Keyboard video control handlers
  useShortCut({
    ...shortCutPlay,
    action: useCallback(() => {
      focus();
      playPause();
    }, [focus, playPause]),
    disabled: otherModalsActive
  });

  useShortCut({
    ...shortCutMute,
    action: useCallback(() => {
      focus();
      toggleMute();
    }, [focus, toggleMute]),
    disabled: otherModalsActive
  });

  useShortCut({
    ...shortCutJumpBack,
    action: useCallback(() => {
      focus();
      jumpBack();
    }, [focus, jumpBack]),
    disabled: otherModalsActive
  });

  useShortCut({
    ...shortCutJumpForward,
    action: useCallback(() => {
      focus();
      jumpForward();
    }, [focus, jumpForward]),
    disabled: otherModalsActive
  });

  useShortCut({
    ...shortCutFrameBack,
    action: useCallback(() => {
      focus();
      stepFrame(-1);
    }, [focus, stepFrame]),
    disabled: otherModalsActive
  });

  useShortCut({
    ...shortCutFrameForward,
    action: useCallback(() => {
      focus();
      stepFrame(1);
    }, [focus, stepFrame]),
    disabled: otherModalsActive
  });

  useShortCut({
    ...shortCutLoop,
    action: useCallback(() => {
      focus();
      toggleLoop();
    }, [focus, toggleLoop]),
    disabled: otherModalsActive
  });

  useShortCut({
    ...shortCutFullscreen,
    action: useCallback(() => {
      focus();
      toggleFullscreenAction();
    }, [focus, toggleFullscreenAction]),
    disabled: otherModalsActive
  });
  // #endregion
};
