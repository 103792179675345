import React, { useMemo } from 'react';

// ui
import ShareLinkOverViewUI from '@cube3/ui/src/Prefabs/shareLink/Modals/LinksOverview/LinksOverviewUI';
import { SharedLinkUIProps } from '@cube3/ui/src/Prefabs/shareLink/Subcomponents/SharedLinkUI';

/* Import interface */
import { ModalReceiverProps } from '../../Modals/ModalManager';

// helper class
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import moment from 'moment';
import { useModalActions } from '../../../layout/Modals/modalActions';

/// <Summary>
/// Functional component that retrieves the share for a resource (asset etc) and displays them
/// in a UI
/// </Summary>

interface PublicProps {}

type Properties = PublicProps & ModalReceiverProps;

function ShareLinkOverview(props: Properties): JSX.Element {
  const modalActions = useModalActions();

  const id = props.modalContext.id;
  const type = props.modalContext.type;

  const [accountId] = useCurrentAccount();

  const params = useMemo(() => {
    return {
      account: accountId,
      sort: '-created_at',
      page: { size: -1 }
    };
  }, [accountId]);

  const retrievedSharedlinks = useResourceList__ALPHA({
    resourceType: type === 'asset' ? 'asset' : 'folder',
    resourceId: id,
    edgeType: 'share',
    edgeLabel: 'shares',
    strategy: 'fetch-on-mount',
    params
  });

  // maps over retrieved shares and parses them to a format that is compatible with
  // the UI component.
  function mapShares(): SharedLinkUIProps[] {
    const mapped: SharedLinkUIProps[] = retrievedSharedlinks.resources?.map(
      (item) => {
        const expiryTime = moment(item.expires_at);
        const today = moment();
        const inPast: boolean = today.diff(expiryTime, 'seconds') > 0;

        const uiShare: SharedLinkUIProps = {
          created_at: item.created_at,
          email_addresses: item.email_addresses,
          expired: inPast,
          expires: item.expires,
          expires_at: item.expires_at,
          onClickShare: () =>
            modalActions.openModal(
              'ShareSettings',
              {
                shareId: item.id,
                intent: 'share',
                nodeId: id
              },
              false
            )
        };

        return uiShare;
      }
    );

    return mapped;
  }

  // use the hook useMemo to memoize the value so that a rerender with the same props
  // does not need remapping of the values.
  const uiShares: SharedLinkUIProps[] = useMemo(() => mapShares(), [mapShares]);

  const loading =
    retrievedSharedlinks.status &&
    retrievedSharedlinks.status !== statuses.SUCCESS &&
    retrievedSharedlinks.status !== statuses.FAILED;

  React.useEffect(() => {
    if (
      retrievedSharedlinks.status === statuses.SUCCESS &&
      uiShares?.length === 0
    ) {
      modalActions.openModal('ShareSettings', {
        intent: 'share',
        nodeId: id,
        first: true
      });
    }
  }, [uiShares, retrievedSharedlinks.status, modalActions, type, id]);

  const [tookLong, setTookLong] = React.useState(false);
  React.useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setTookLong(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [loading, setTookLong]);

  return uiShares.length > 0 &&
    retrievedSharedlinks.status === statuses.SUCCESS ? (
    <ShareLinkOverViewUI
      loading={loading || !id}
      onClickNewShare={() =>
        modalActions.openModal(
          'ShareSettings',
          {
            intent: 'share',
            nodeId: id
          },
          false
        )
      }
      onCloseEvent={modalActions.previousModal}
      sharedlinks={uiShares}
    />
  ) : tookLong ? (
    <ModalNoticeUI loading={true}>
      <div style={{ height: 60 }} />
    </ModalNoticeUI>
  ) : null;
}

export default ShareLinkOverview;
