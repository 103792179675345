import { makeIdentifier, normalizeKey } from './utils';

interface Modifiers {
  ctrl?: boolean;
  alt?: boolean;
  shift?: boolean;
  meta?: boolean;
}
export interface KeyConfig {
  id: string | number;
  icon?: string;
  keyCode: string;
  action?(ev: KeyboardEvent, next: () => void);
  modifiers?: Modifiers;
  help?: string;
}

const REGISTER_BOUNDARY = 'REGISTER_BOUNDARY';
const UNREGISTER_BOUNDARY = 'UNREGISTER_BOUNDARY';
const REGISTER_KEY = 'REGISTER_KEY';
const UNREGISTER_KEY = 'UNREGISTER_KEY';

export const actionTypes = {
  REGISTER_BOUNDARY,
  UNREGISTER_BOUNDARY,
  REGISTER_KEY,
  UNREGISTER_KEY
} as const;

type ActionTypes = (typeof actionTypes)[keyof typeof actionTypes];
type KeyActionTypes = typeof REGISTER_KEY | typeof UNREGISTER_KEY;
type BoundaryActionTypes =
  | typeof REGISTER_BOUNDARY
  | typeof UNREGISTER_BOUNDARY;

type Payload<T> = T extends KeyActionTypes
  ? {
      keyConfig: KeyConfig;
      path: string;
    }
  : T extends BoundaryActionTypes
  ? { boundary: string; el: HTMLElement; isolate?: 'down' }
  : unknown;

interface Action<T = unknown> {
  type: ActionTypes;
  payload: Payload<T>;
}

interface State {
  keys: { [key: string]: { [key: string]: KeyConfig } };
  boundaries: { [key: string]: HTMLElement };
  isolate?: string[];
}

export const initialState = {
  boundaries: {},
  isolate: [],
  keys: {}
};

export const reducer = (state: State, actionRaw: Action<unknown>): State => {
  switch (actionRaw.type) {
    case REGISTER_BOUNDARY: {
      const action = actionRaw as Action<BoundaryActionTypes>;
      const { boundary, isolate } = action.payload;
      return {
        ...state,
        isolate: isolate
          ? state.isolate
              .concat(boundary)
              .sort((a, b) => (a.includes(b) ? -1 : 1))
          : state.isolate,
        boundaries: {
          ...state.boundaries,
          [boundary]: action.payload.el
        }
      };
    }

    case UNREGISTER_BOUNDARY: {
      const action = actionRaw as Action<BoundaryActionTypes>;
      const { boundary } = action.payload;

      const boundaries = { ...state.boundaries };
      if (boundaries[boundary]) {
        delete boundaries[boundary];

        return {
          ...state,
          isolate: state.isolate.includes(boundary)
            ? state.isolate.filter((e) => e !== boundary)
            : state.isolate,
          boundaries
        };
      }
      return state;
    }

    case REGISTER_KEY: {
      const action = actionRaw as Action<KeyActionTypes>;
      const config = action.payload.keyConfig;
      const key = makeIdentifier(
        normalizeKey(config.keyCode),
        config.modifiers
      );
      config.action = config.action
        ? config.action
        : () => {
            // eslint-disable-next-line
            console.info(`Key ${key} pressed`);
          };

      const path = action.payload.path;

      const m = state.keys[key] || {};
      m[path] = config;
      return {
        ...state,
        keys: { ...state.keys, [key]: { ...m } }
      };
    }
    case UNREGISTER_KEY: {
      const action = actionRaw as Action<KeyActionTypes>;
      const config = action.payload.keyConfig;
      const key = makeIdentifier(
        normalizeKey(config.keyCode),
        config.modifiers
      );
      const path = action.payload.path;

      const m = state.keys[key] || {};
      if (m[path] && m[path].id === config.id) {
        delete m[path];
        return { ...state, [key]: { ...m } };
      }
      return { ...state };
    }
    default:
      return state;
  }
};
