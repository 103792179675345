import React from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../../Buttons/Button';
import { ModalMenuUI } from '../../../Modal/ModalMenuUI';
import { Typography } from '../../../typography/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Surface from '../../../Surface/Surface';
import { ImageCropper } from '../UserAvatar/ImageCropper';
import { CircularProgress } from '../../../Progress/CircularProgress';
import { useClassName } from '../../../utils/useClassName';

type PreviewPicture = { name: string; src: any };

interface ModalProps {
  loading?: boolean;
  onCloseEvent?: () => void;
  onClickSave?: (image) => void;
}

interface ImageCropperProps {
  image: PreviewPicture;
  shouldCrop: boolean;
  disabled?: boolean;
  setAvatar: (img) => void;
}

interface DropzoneProps extends ImageCropperProps {
  onDrop?: (pic) => void;
  readingFile?: boolean;
}

type Props = ModalProps & DropzoneProps & ImageCropperProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dropArea: {
      minHeight: 300,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      alignItems: 'stretch',
      justifyItems: 'stretch'
    },
    dropActive: {
      border: `1px ${theme.customPalette.secondary.light} solid`,
      backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
        'transparent',
        'secondary',
        ['dragOver']
      )
    },
    dropRejected: {
      border: `1px ${theme.customPalette.dangerError} solid`
    },
    mainText: {
      margin: 'auto',
      paddingBottom: 10
    },
    spinner: {
      position: 'absolute',
      top: '60%',
      left: '50%'
    }
  })
);

const ProfilePictureDropzone: React.FC<DropzoneProps> = (props) => {
  const { image, disabled, shouldCrop, onDrop, setAvatar, readingFile } = props;
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: { 'image/*': [] },
    useFsAccessApi: false //doesn't work with playwright testing
  });

  return (
    <Surface
      border={isDragActive}
      background="transparent"
      cornerRadius="mediumEmphasis"
      corners="rounded"
      className={useClassName(
        classes.root,
        isDragActive && classes.dropActive,
        isDragReject && classes.dropRejected
      )}
    >
      <div
        {...getRootProps({
          className: classes.dropArea
        })}
      >
        <input {...getInputProps()} />

        <Typography
          typographyProps={{ gutterBottom: true }}
          className={classes.mainText}
        >
          {isDragAccept ? (
            <>Drop the picture here</>
          ) : isDragReject ? (
            <>Only pictures will be accepted</>
          ) : (
            <span>
              Drag picture here or{' '}
              <Button
                disableHover={true}
                marginCollapse="both"
                text="select a picture"
                onClick={open}
              />{' '}
              from your device
            </span>
          )}
        </Typography>
        {readingFile && <CircularProgress className={classes.spinner} />}
        {!readingFile && image && (
          <ImageCropper
            image={image}
            width={500}
            height={250}
            scale={1}
            radius={150}
            minRadius={50}
            setAvatar={setAvatar}
            shouldCrop={shouldCrop}
            disabled={disabled}
          />
        )}
      </div>
    </Surface>
  );
};

export const ChangeProfilePictureModalUI: React.FunctionComponent<Props> = (
  props
) => {
  const {
    onCloseEvent,
    onDrop,
    image,
    onClickSave,
    setAvatar,
    shouldCrop,
    loading,
    readingFile
  } = props;

  return (
    <ModalMenuUI
      title={'Change profile picture'}
      loading={loading}
      onCloseEvent={onCloseEvent}
      footerRightComponent={
        <>
          <Button text="Cancel" colorVariant="ghost2" onClick={onCloseEvent} />
          <Button
            text="Save"
            disabled={!image}
            colorVariant="filled1"
            onClick={onClickSave}
          />
        </>
      }
    >
      <ProfilePictureDropzone
        onDrop={onDrop}
        image={image}
        setAvatar={setAvatar}
        shouldCrop={shouldCrop}
        disabled={loading} // disable the cropper while processing the new avatar
        readingFile={readingFile}
      />
    </ModalMenuUI>
  );
};
