import * as React from 'react';

import { isMobile } from 'react-device-detect';
import { createStyles, Theme, makeStyles } from '@material-ui/core';

import { useClassName } from '../../../utils/useClassName';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    root: {
      width: '100%',
      minHeight: '48px',
      display: 'flex',
      alignContent: 'center',
      '$mobile&': {
        flexDirection: 'column',
        height: '100%'
      }
    },
    menubutton: {
      height: 40,
      marginTop: 'auto',
      marginBottom: 'auto',
      '$mobile &': {
        margin: 'unset',
        marginTop: theme.customSpacing.margin[16]
      }
    },
    buttonGroup: {
      display: 'flex',
      alignContent: 'center',
      '$mobile&': {
        flexDirection: 'column',
        height: '100%'
      }
    },
    // menuButton container
    menugroup: {
      minWidth: '225px'
    },

    primaryButtons: {
      justifySelf: 'flex-start',
      flexGrow: 0
    },
    children: { justifySelf: 'center', flexGrow: 1, alignItems: 'center' },
    secondaryButtons: {
      justifySelf: 'flex-end',
      flexGrow: 0,
      '$mobile &': {
        display: 'none'
      }
    }
  })
);

interface BaseProps {
  primaryButtons?: React.ReactNode;
  secondaryButtons?: React.ReactNode;
}

export const CommandBarBaseUI: React.FC<React.PropsWithChildren<BaseProps>> = (
  props
) => {
  const { children, primaryButtons, secondaryButtons } = props;

  const classes = useStyles(props);

  return (
    <div
      data-type="librarydeselectoverride"
      data-cy="commandBar"
      className={`${classes.root} ${isMobile ? classes.mobile : ''}`}
    >
      <div
        className={useClassName(classes.primaryButtons, classes.buttonGroup)}
      >
        {primaryButtons}
      </div>

      <div className={useClassName(classes.children, classes.buttonGroup)}>
        {children}
      </div>

      <div
        className={useClassName(classes.secondaryButtons, classes.buttonGroup)}
      >
        {secondaryButtons}
      </div>
    </div>
  );
};

export { useStyles as useCommandBarBaseStyles };
