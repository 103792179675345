import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Share } from '@cube3/common/model/schema/resources/share';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ResourceIdentifier } from '@cube3/state/types';
import { ShareDownloadOptions } from '@cube3/ui/src/Prefabs/shareLink/Subcomponents/ShareDownloadFormats';
import React, { useLayoutEffect } from 'react';
import { useShareDownloadFormats } from '../../ShareLink/hooks/useShareDownloadFormats';

interface Props {
  value: RenderPreset[];
  onChange(
    renderpresets: Array<ResourceIdentifier<'render-preset'> | RenderPreset>
  ): void;
  share?: Share;
  watermark?: boolean;
}

const requiredPermission = [
  'DOWNLOAD_TYPE_ORIGINAL',
  'DOWNLOAD_TYPE_PREVIEW'
] as Privileges[];

export const DownloadPresetsPicker = (props: Props) => {
  const { value, onChange, share, watermark } = props;

  const { resource: node } = useResource__ALPHA({
    resourceType: 'content-tree-node',
    resourceId: share?.relationships.node.id
  });

  const [canDownloadPreview] = useWorkspacePermissions(requiredPermission);

  const {
    basicDownloadTypes,
    onClickChooseFormats,
    selectedFormats,
    setDownloadFormats,
    noOtherFormats,
    loading
  } = useShareDownloadFormats(
    share?.id,
    node?.relationships.resource.type === 'asset' && node.id,
    watermark
  );

  useLayoutEffect(() => {
    if (
      loading ||
      selectedFormats === undefined ||
      value === selectedFormats ||
      (value?.length === selectedFormats?.length &&
        value.every((p) => selectedFormats.find((s) => s.id === p.id)))
    ) {
      return;
    }

    onChange(selectedFormats);
  }, [selectedFormats]);

  return (
    <ShareDownloadOptions
      basicDownloadTypes={
        basicDownloadTypes as unknown as Array<
          RenderPreset & { display_name: string; id: string }
        >
      }
      visible={true}
      onClick={onClickChooseFormats}
      selectedFormats={value}
      setSelectedFormats={setDownloadFormats}
      loading={loading}
      noOtherFormats={noOtherFormats || !canDownloadPreview}
    />
  );
};
