import { Recipient } from '@cube3/common/model/schema/resources/recipient';
import { InputFieldTempate } from '@cube3/cubicle/src/core/atoms/InputField/InputFieldTempate';
import { Panel } from '@cube3/cubicle/src/core/atoms/Panel';
import { Scrollbar } from '@cube3/cubicle/src/core/atoms/Scrollbar';
import { Tag } from '@cube3/cubicle/src/core/atoms/Tag/Tag';
import { ContextMenu } from '@cube3/cubicle/src/core/molecules/ContextMenu';
import { ContextMenuListItem } from '@cube3/cubicle/src/core/molecules/ContextMenu/ContextMenuListItem';
import { Container } from '@cube3/cubicle/src/core/templates/layout/Flex';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { handleKeyDown } from '@cube3/ui/src/exports/subcomponents/ExportInputPicker';
import { add } from '@cube3/ui/src/exports/subcomponents/MultiStringInput/utils/add';
import { remove } from '@cube3/ui/src/exports/subcomponents/MultiStringInput/utils/remove';
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { emailValidators } from '../../AccountPages/views/LoginView/utils/validators';

interface Props {
  value: Recipient[];
  locked?: Recipient[];
  onChange(recipients: Recipient[]): void;
}

export const RecipientsPicker = (props: Props) => {
  const { value, onChange, locked } = props;

  const [query, setQuery] = useState('');

  const [accountId] = useCurrentAccount();

  const params = useMemo(() => {
    return query?.trim()
      ? {
          filter: {
            query: query.trim()
          },
          page: { size: -1 }
        }
      : undefined;
  }, [query]);

  const recipients = useResourceList__ALPHA({
    resourceId: accountId,
    resourceType: 'account',
    edgeType: 'recipient',
    edgeLabel: 'recipients',
    params
  });

  const suggestions = useMemo(() => {
    return recipients.resources?.filter(
      (r) =>
        r.email_address &&
        !value.find((v) => v.email_address === r.email_address) &&
        !locked?.find((v) => v.email_address === r.email_address)
    );
  }, [recipients.resources, value]);

  const inputRef = useRef(null);
  const [containerRef, setContainerRef] = useState(null);

  const [focus, handleFocus, handleBlur] = useEventBasedToggle();
  const [menuHovered, handleMenuEnter, handleMenuLeave] = useEventBasedToggle();

  const isValid = useMemo(() => {
    return !emailValidators(query, undefined, undefined, undefined);
  }, [query]);

  const [highlightSuggestion, setHighlightSuggestion] = useState(null);
  const handleSuggestionKeys = useCallback(
    (ev: React.KeyboardEvent) => {
      if (suggestions.length) {
        if (ev.key === 'ArrowUp') {
          ev.stopPropagation();
          ev.preventDefault();
          setHighlightSuggestion(
            (c) => (c + suggestions.length - 1) % suggestions.length
          );
        } else if (ev.key === 'ArrowDown') {
          ev.stopPropagation();
          ev.preventDefault();
          setHighlightSuggestion((c) => (c + 1) % suggestions.length);
        } else if (ev.key === 'Enter' && !isValid) {
          if (!ev.shiftKey) {
            setQuery('');
          }
          onChange(
            add(value, {
              email_address: suggestions[highlightSuggestion]?.email_address
            } as Recipient)
          );
        }
      }
      if (ev.key === 'Backspace' || ev.key === 'Delete') {
        if (!query) {
          onChange(value.slice(0, -1));
        }
      }
    },
    [suggestions, value, query, setQuery, highlightSuggestion, isValid]
  );

  const handleCommit = useCallback(
    (evt) =>
      handleKeyDown({
        evt,
        inputValue: query,
        values: value,
        onClear: () => setQuery(''),
        onAdd: (r) => onChange(add(value, { email_address: r } as Recipient)),
        onDelete: (r) =>
          onChange(
            remove(
              value,
              value.find((v) => v.email_address === r)
            )
          ),
        isValid
      }),
    [isValid, query, value, setQuery, onChange]
  );

  useLayoutEffect(() => {
    if (highlightSuggestion !== 0) {
      setHighlightSuggestion(0);
    }
  }, [suggestions]);

  return (
    <div onKeyDownCapture={handleSuggestionKeys} ref={setContainerRef}>
      <InputFieldTempate
        label=""
        useMultiline={true}
        onFocus={() => inputRef.current?.focus()}
      >
        <Container
          direction="row"
          style={{
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'start',
            marginTop: 8,
            marginBottom: 8
          }}
          gap={4}
        >
          {locked?.map((r) => (
            <Tag
              size={SizeVariants.sm}
              key={r.id}
              label={r.email_address}
              // onCrossClick={() => onChange(remove(value, r))}
              chipStyle="outlined"
              color="gray"
            />
          ))}

          {value
            .filter(
              (r) => !locked?.find((l) => l.email_address === r.email_address)
            )
            .map((r) => (
              <Tag
                size={SizeVariants.sm}
                key={r.id}
                label={r.email_address}
                onCrossClick={() => onChange(remove(value, r))}
                chipStyle="filled"
                color="gray"
              />
            ))}
          <input
            data-prevent-submit-when-focused={true}
            value={query}
            ref={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(ev) => setQuery(ev.target.value)}
            style={{
              display: 'block',
              background: 'transparent',
              color: 'white',
              flexGrow: 1,
              flexShrink: 1,
              minWidth: 50,
              width: 'auto',
              border: 'none',
              minHeight: 32,
              outline: 'none'
            }}
            onKeyDown={handleCommit}
            placeholder={
              locked?.length ? 'Other recipients...' : 'Recipients...'
            }
          />
        </Container>
      </InputFieldTempate>

      {(focus || menuHovered) && suggestions.length > 0 && (
        <ContextMenu
          anchor={containerRef}
          width={`${containerRef?.clientWidth}px`}
        >
          <div
            onMouseEnter={handleMenuEnter}
            onMouseLeave={handleMenuLeave}
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <Scrollbar autoHeight={true} autoHeightMax={300}>
              {suggestions.map((s, idx) => (
                <Panel
                  backgroundColor={(theme) =>
                    idx === highlightSuggestion
                      ? theme.color.overlay.primary
                      : 'transparent'
                  }
                  cornerRadius={SizeVariants.lg}
                  key={s.id}
                >
                  <ContextMenuListItem
                    showChevron={false}
                    name={s.email_address}
                    onClick={() => {
                      inputRef.current.focus();
                      onChange(add(value, s));
                    }}
                    modifiers={
                      idx === highlightSuggestion ? 'Enter' : undefined
                    }
                  />
                </Panel>
              ))}
            </Scrollbar>
          </div>
        </ContextMenu>
      )}
    </div>
  );
};

const useEventBasedToggle = (invert = false) => {
  const [toggled, setToggled] = useState(false);

  const handleOn = useCallback(() => setToggled(!invert), [setToggled]);
  const handleOff = useCallback(() => setToggled(invert), [setToggled]);

  return [toggled, handleOn, handleOff] as [boolean, () => void, () => void];
};
