import { Share } from '@cube3/common/model/schema/resources/share';
import { VerifyType } from '@cube3/state/types';

/**
 * map Share resource attributes to form values
 */
const shareAttributeLookup: Record<string, (s: Share) => any> = {
  who_can_access: (share) =>
    share.verify === VerifyType.recipients_only
      ? 'recipients_only'
      : 'everyone',
  recipients: (share) =>
    share.email_addresses?.map((e) => ({ email_address: e })) || [],
  require_verification: (share) => share.verify !== VerifyType.never,
  add_message: (share) => !!share.message,
  message: (share) => share.message,
  set_password: (share) => share.password_enabled,
  password: (share) => (share.password_enabled ? '' : undefined),
  expires: (share) => share.expires || share.expires_at,
  expires_at: (share) => share.expires_at,
  watermark_videos: (share) => share.watermark,
  allow_downloads: (share) => share.download_enabled,

  download_presets: (share) => {
    return (
      share.relationships['render-presets']?.data ||
      share.relationships['render-presets'] ||
      (share.download_enabled ? [] : undefined) // only return undefined if we are sure presets is not intentionally empty
    );
  }
};

export const valueFromShare = ({ share }, a) => {
  return shareAttributeLookup[a]?.(share) ?? share.attributes[a];
};
