import { ContentTreeNode } from '@cube3/common/model/schema/resources/content-tree-node';
import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Share } from '@cube3/common/model/schema/resources/share';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useMemo } from 'react';
import { useGenericExportOptions } from '../../../Exports/state/useGenericExportOptions';
import { valueFromDefaults } from './valueFromDefaults';
import { valueFromShare } from './valueFromShare';
import { Feature } from '@cube3/state/types';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';

const requiredPermission = [
  'DOWNLOAD_TYPE_ORIGINAL',
  'DOWNLOAD_TYPE_PREVIEW'
] as Privileges[];

const requiredFeatures = {
  features: ['/WORKSPACE/SHARING/WATERMARK'] as Feature[]
};

interface Config {
  share: Share;
  intent: Share['intent'];
  renderPresets: RenderPreset[];
  node: ContentTreeNode;
}

export const useSharesForm = ({
  share,
  intent,
  renderPresets,
  node
}: Config) => {
  const [, workspace = {}] = useCurrentWorkspace();

  const [canDownloadOriginal, canDownloadPreview] =
    useWorkspacePermissions(requiredPermission);

  const [hasWatermarkFeature] = useFeatures(requiredFeatures);

  const fields = useMemo(() => {
    return [
      {
        id: 'display_name',
        label: 'Title',
        type: 'string',
        required: true,
        visible: () => intent === 'request'
      },
      {
        id: 'who_can_access',
        label: 'Who can access',
        type: 'select',
        required: true
      },
      {
        id: 'require_verification',
        label: 'Require email verification',
        type: 'toggle',
        visible: (values) => {
          return (
            values.find((v) => v.id === 'who_can_access')?.value === 'everyone'
          );
        }
      },
      {
        id: 'recipients',
        label: 'Recipients',
        type: 'multistring',
        visible: (values) => {
          return (
            values.find((v) => v.id === 'who_can_access')?.value ===
            'recipients_only'
          );
        }
      },
      {
        id: 'add_message',
        label: 'Add message',
        type: 'toggle',

        visible: (values) =>
          values.find((v) => v.id === 'who_can_access')?.value ===
          'recipients_only'
      },
      {
        id: 'message',
        label: 'Message',
        type: 'string',
        visible: (values) => values.find((v) => v.id === 'add_message')?.value
      },
      {
        id: 'set_password',
        label: 'Set password',
        type: 'toggle'
      },
      {
        id: 'password',
        label: 'Password',
        type: 'string',
        visible: (values) => values.find((v) => v.id === 'set_password')?.value
      },
      {
        id: 'expires',
        label: 'Set expiration date',
        type: 'toggle'
      },
      {
        id: 'expires_at',
        label: 'Expires at',
        type: 'string',
        visible: (values) => values.find((v) => v.id === 'expires')?.value
      },
      {
        id: 'watermark_videos',
        label: 'Watermark videos',
        type: 'toggle',
        visible: () => hasWatermarkFeature && intent === 'share'
      },
      {
        id: 'allow_downloads',
        label: 'Allow downloads',
        type: 'toggle',
        visible: () => intent === 'share'
      },
      {
        id: 'download_presets',
        label: 'Download presets',
        type: 'multistring',
        visible: (values) =>
          values.find((v) => v.id === 'allow_downloads')?.value
      }
    ] as const;
  }, [hasWatermarkFeature]);

  const initialValues = useMemo(() => {
    return fields.map((f) => {
      const { id } = f;
      return {
        id: id,
        value: share
          ? valueFromShare({ share }, id) ??
            valueFromDefaults({ workspace, intent, renderPresets, node }, id)
          : valueFromDefaults({ workspace, intent, renderPresets, node }, id)
      };
    });
  }, [fields, share, workspace, node]);

  const validators = useMemo(() => {
    return [
      (values) => {
        if (
          values.find((v) => v.id === 'allow_downloads').value &&
          !canDownloadOriginal &&
          !canDownloadPreview
        ) {
          return [
            {
              field: 'allow_downloads',
              code: 'NO_DOWNLOAD_PRIVILEGES',
              message: 'User does not have download privileges',
              type: 'error' as const
            }
          ];
        }
      },
      (values) => {
        const message = values.find((v) => v.id === 'message');
        if (message.value?.length > 500) {
          return [
            {
              field: 'message',
              code: 'MAX_LENGTH_EXCEEDED',
              message: 'Message is too long',
              type: 'error' as const
            }
          ];
        }

        if (message.value?.length === 0) {
          return [
            {
              field: 'message',
              code: 'MIN_LENGTH_REQUIRED',
              message: 'Message is too short',
              type: 'error' as const
            }
          ];
        }
      }
    ];
  }, []);

  const { fieldProps, values, valid, dirty, errors } = useGenericExportOptions({
    fields,
    initialValues,
    validators
  });

  return { fieldProps, values, valid, dirty, errors };
};
