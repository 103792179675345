import { Checkbox } from '@cube3/cubicle/src/core/atoms/Checkbox/Checkbox';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { HBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import React, { useId } from 'react';

export const CheckboxRow = (props) => {
  const { value, onChange, label } = props;
  const id = useId();
  return (
    <HBox justify="start" align="center" gap={16}>
      <Checkbox
        value={value}
        onChange={onChange}
        labeledBy={id}
        label={label}
      />
      <Typography>
        <label id={id}>{label}</label>
      </Typography>
    </HBox>
  );
};
