import { cx } from '@emotion/css';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import UseOutsideClick from '../../../helpers/hooks/UseOutsideClick';
import { SizeVariants, Theme } from '../../../theme/themes';
import { makeCSS } from '../../../utils/makeCSS';
import { withCubicleFallback } from '../../../utils/CubicleFallback';
import { ContextMenu } from '../../molecules/ContextMenu';
import {
  InputFieldTempate,
  InputFieldTempateProps
} from '../InputField/InputFieldTempate';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

/**
 *The Select component allows users pick a value from a predefined list of options.
 Each option item inside the Select component needs to be wrap with the Option component.
 */
export interface SelectProps
  extends React.PropsWithChildren,
    Pick<InputFieldTempateProps, 'width'> {
  /**Label of the options or first option to be displayed */
  label?: string;
  /** selected value */
  value: string;
  disabled?: boolean;
  onChange?(value: string): void;
  /** A prop that specifies how to render the selected value. */
  renderValue?: (props) => JSX.Element;
}

const useCSS = makeCSS(({ theme, width }: SelectProps & { theme: Theme }) => {
  return {
    root: {
      cursor: 'pointer',
      minWidth: width || '100%',
      position: 'relative'
    },
    contextMenu: {
      display: 'flex',
      alignContent: 'start',
      position: 'absolute',
      zIndex: 999,
      width: '100%',
      minWidth: width
    },
    placeholder: {
      pointerEvents: 'none'
    },
    extraPadding: {
      paddingTop: theme.spacing[3],
      paddingBottom: theme.spacing[3]
    }
  };
});

const Select = (props: SelectProps) => {
  const {
    label = 'Options',
    children,
    value,
    width,
    disabled,
    onChange,
    renderValue
  } = props;

  const classes = useCSS({ width });

  const state = false;

  const [icon, setIcon] = useState<'unfold_more' | 'unfold_less'>(
    'unfold_more'
  );
  const [opened, setOpened] = useState(state || false);
  const [selectedOption, setSelectedOption] = useState<React.ReactNode>(
    React.Children.toArray(children).find(
      (c) => (c as ReactElement).props.value === value
    ) || null
  );

  useEffect(() => {
    setOpened(state || false);
  }, [state]);

  const handleOnOpen = useCallback(() => {
    setOpened(!opened);
    setIcon(opened ? 'unfold_more' : 'unfold_less');
  }, [opened, setOpened]);

  React.useLayoutEffect(() => {
    if ((selectedOption as ReactElement)?.props.value === value) {
      return;
    }

    React.Children.forEach(children, (child) => {
      if ((child as ReactElement).props.value === value) {
        setSelectedOption(child || null);
      }
    });
  }, [value]);

  useLayoutEffect(() => {
    if ((selectedOption as ReactElement)?.props.value === value) {
      return;
    }

    onChange?.((selectedOption as ReactElement)?.props.value);
  }, [selectedOption]);

  //close options menu when clicked anywhere else
  const handleClickOutside = useCallback(() => {
    setOpened(false);
    setIcon('unfold_more');
  }, [setOpened]);

  const options = useMemo(() => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child as React.ReactElement, {
        onSelect: () => setSelectedOption(child as React.ReactElement)
      });
    });
  }, [children, setSelectedOption]);

  const ref = UseOutsideClick(handleClickOutside);

  const onRightIconClick = useCallback(
    (event) => {
      event.stopPropagation();
      handleOnOpen();
    },
    [handleOnOpen]
  );
  return (
    <div ref={ref} className={`${classes.root}`}>
      <div onClick={!disabled ? handleOnOpen : undefined}>
        <InputFieldTempate
          width={width}
          label={value ? '' : label}
          size={SizeVariants.md}
          iconRight={icon}
          criticalState={false}
          disabled={disabled}
          onRightIconClick={onRightIconClick}
        >
          <div
            className={cx(
              classes.placeholder,
              selectedOption === null ? classes.extraPadding : ''
            )}
          >
            {renderValue ? renderValue({ value }) : selectedOption}
          </div>
        </InputFieldTempate>
      </div>
      <div className={`${classes.contextMenu}`} onClick={handleOnOpen}>
        {opened && <ContextMenu width={'100%'}>{options}</ContextMenu>}
      </div>
    </div>
  );
};

const Default = withCubicleFallback(Select);
export default Default;
export { Default as Select };
