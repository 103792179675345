import { Theme, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Field } from 'redux-form';

/* Import components */

import Time from '../../../human/Time';
import { Typography } from '../../../typography/Typography';
// import GenericFormField from '../../forms/GenericFormField';
import { PasswordInputComponent } from './fields/PasswordFieldComponent';

/* Import fieldFormatter */
// import { dateFormatter } from '../../helpers/FieldFormatters';
// import Cube3TextField from '../../forms/textfields/Cube3TextField';
import moment from 'moment';

import { createStyles } from '@material-ui/core/styles';
import { ShareDownloadOptions } from '../Subcomponents/ShareDownloadFormats';
import { ExpiresDatePicker } from './fields/ExpiresDatePicker';

import { LinkSettingsCheckbox } from './fields/LinkSettingsCheckbox';
import {
  VerfiyUserFieldsetProps,
  VerifyUserFieldset
} from './VerifyUserFieldset';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column' as 'column'
    },
    inputFields: {
      display: 'flex',
      flexFlow: 'row-reverse',
      justifyContent: 'flex-end',
      margin: '4px 0px'
    },
    inputLabel: {
      marginLeft: '4px'
    },
    expirationContainer: {
      marginLeft: theme.customSpacing.margin[32]
    },
    expiryText: {
      margin: `${theme.customSpacing.margin[8]} 0px 0px 0px`
    },
    radioButton: {
      marginLeft: theme.customSpacing.margin[24]
    },
    originalFilesCheckbox: {
      marginLeft: theme.customSpacing.margin[24]
    },
    originalFilesLabel: {
      marginBottom: theme.customSpacing.margin[16]
    },
    checkbox: {
      margin: '8px 0',
      '&:last-child': {
        marginBottom: 0
      },
      '&:first-child': {
        marginTop: 0
      }
    },
    inputPrimary: {
      marginBottom: theme.customSpacing.margin[4]
    },
    inputIndent: {
      marginBottom: theme.customSpacing.margin[4],
      marginLeft: theme.customSpacing.margin[32]
    },

    setExpirationDate: {
      marginBottom: theme.customSpacing.margin[8]
    },

    hidden: {
      visibility: 'hidden'
    }
  })
);

export interface LinkSettingsReceivedProps {
  basicDownloadTypes?: any[];
  /** An array of selected formats: list all formats by default */
  selectedFormats?: any[];
  canDownloadOriginal?: boolean;
  canDownloadPreview?: boolean;
  setSelectedFormats?: Function;
  onClickChooseFormats?: () => void;
  presetsLoading?: boolean;
  noOtherFormats?: boolean;
}

// import this interface in your component when you would like to get the redux form values.
export interface LinkSettingsFormProperties extends VerfiyUserFieldsetProps {
  expires?: boolean;
  expiryDate?: string;
  /** true if download checkbox is checked */
  downloadFiles?: boolean;
  passwordEnabled?: boolean;
  password?: string;
  passwordValidator?: (value: string, minLength?: number) => boolean;
  watermark?: boolean;
  hasWatermarkFeature?: boolean;
}

type LinkSettingsFormProps = LinkSettingsFormProperties &
  LinkSettingsReceivedProps;

const LinkSettingsForm: React.FC<LinkSettingsFormProps> = React.memo(
  (props) => {
    const {
      expiryDate,
      expires,
      //download
      basicDownloadTypes,
      canDownloadOriginal,
      canDownloadPreview,
      noOtherFormats,
      downloadFiles,
      setSelectedFormats,
      selectedFormats,
      presetsLoading,
      onClickChooseFormats,
      // password
      passwordEnabled,
      password,
      passwordValidator,
      hasWatermarkFeature,
      watermark,
      verifyUser,
      verifyType,
      allowRequestAccess,
      hasVerifyUserFeature
    } = props;

    const classes = useStyles();

    const isValidDate: boolean = !Number.isNaN(Date.parse(expiryDate));

    return (
      <form className={classes.form}>
        {hasWatermarkFeature && (
          <Field
            name="watermark"
            type="checkbox"
            component={LinkSettingsCheckbox}
            props={{ label: 'Watermark videos' }}
            className={classes.inputPrimary}
          />
        )}

        {(canDownloadPreview || canDownloadOriginal) && (
          <Field
            name="downloadFiles"
            type="checkbox"
            component={LinkSettingsCheckbox}
            props={{ label: 'Allow downloads' }}
            className={classes.inputPrimary}
          />
        )}
        <ShareDownloadOptions
          basicDownloadTypes={basicDownloadTypes}
          visible={downloadFiles}
          onClick={onClickChooseFormats}
          selectedFormats={selectedFormats}
          setSelectedFormats={setSelectedFormats}
          loading={presetsLoading}
          noOtherFormats={noOtherFormats || !canDownloadPreview}
          watermark={watermark}
        />

        <VerifyUserFieldset
          verifyUser={verifyUser}
          verifyType={verifyType}
          allowRequestAccess={allowRequestAccess}
          hasVerifyUserFeature={hasVerifyUserFeature}
        />

        {(passwordEnabled !== undefined || password !== undefined) && (
          <Field
            name="passwordEnabled"
            type="checkbox"
            component={LinkSettingsCheckbox}
            props={{ label: 'Set password' }}
            className={classes.inputPrimary}
          />
        )}
        {passwordEnabled && (
          <Field
            name="password"
            placeholder="●●●●●●"
            component={PasswordInputComponent}
            props={{
              password: password,
              passwordValidator: passwordValidator
            }}
          />
        )}
        {/* <Divider/>  */}
        {/* Not part of MVP, but please do not remove as we can use it in the future */}
        {/* <Field
        name="seePreviousVersions"
        type="checkbox"
        component={({ input }) => (
          <CheckBoxStateless
            classes={{root: classes.checkbox}}
            checked={input.value}
            text={'See Previous Versions'}
            typographyProps={{style: 'body2', color: 'contrast1'}}
            onChange={(checked) => input.onChange(checked)}
          />                    
        )}        
      /> */}

        <Field
          name="expires"
          type="checkbox"
          component={LinkSettingsCheckbox}
          props={{ label: 'Set expiration date' }}
          className={classes.setExpirationDate}
        />
        <div className={classes.expirationContainer}>
          {expires && (
            <Field
              name="expiryDate"
              type="date"
              format={dateToMoment}
              component={ExpiresDatePicker}
            />
          )}

          {isValidDate && expires && expiryDate && (
            <Typography
              component="span"
              typographyStyle={'body2'}
              className={classes.expiryText}
              color="contrast2"
            >
              {`This link ${
                new Date(expiryDate) < new Date() ? 'expired ' : 'will expire '
              }`}
              <Time
                date={expiryDate}
                fullForm={false}
                toolTip={true}
                placement="bottom"
                relativeTime={true}
              />
            </Typography>
          )}
        </div>
      </form>
    );
  }
);

const dateToMoment = (date) => (date ? moment(date) : undefined);

export default LinkSettingsForm;
