import { Share } from '@cube3/common/model/schema/resources/share';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ResourceIdentifier, VerifyType } from '@cube3/state/types';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';

const cacheInvalidator = (res, anc, rel) => [
  {
    type: anc.type,
    id: anc.id,
    relationship: 'shares'
  }
];

export const useShareSubmit = ({
  fieldProps,
  shareId,
  tempId,
  nodeId,
  intent
}) => {
  const node = useResource__ALPHA({
    resourceType: 'content-tree-node',
    resourceId: nodeId
  });

  const [createShare, createShareStatus] = useCreateResource__ALPHA({
    resourceType: 'share',
    ancestor: node.resource?.relationships.resource,
    cacheInvalidator
  });

  const [workspaceId, workspace] = useCurrentWorkspace();
  const [mutateShare, mutateShareStatus] = useMutateResource__ALPHA(
    React.useMemo(
      () => ({
        cacheInvalidator: (r) => [
          r,
          { type: undefined, id: undefined, relationship: 'render-presets' },
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'render-presets'
          },
          { type: 'workspace', id: workspaceId, relationship: 'shares' }
        ]
      }),
      []
    )
  );

  const configuredShare = useMemo(() => {
    const expirydate = moment(fieldProps['expires_at']?.value).toISOString();
    // format emails array to be accepted by backend.
    const emailStrings = fieldProps['recipients']?.value.map(
      (element) => element.email_address
    );

    // download options
    const selectedDownloadFormats = fieldProps['allow_downloads']?.value
      ? (fieldProps['download_presets'].value?.map((f) => ({
          id: f.id,
          type: f.type
        })) as ResourceIdentifier<'render-preset'>[])
      : undefined;

    // build the information for create share with

    const newShare: Partial<Share> & { temporaryId?: string; type: 'share' } = {
      type: 'share' as const,
      intent: shareId ? undefined : intent,
      id: shareId,
      temporaryId: shareId ? undefined : tempId,
      display_name: fieldProps['display_name']?.value,
      email_addresses: emailStrings || [],
      download_enabled: !!fieldProps['allow_downloads']?.value,
      verify:
        fieldProps['who_can_access'].value === 'recipients_only'
          ? VerifyType.recipients_only
          : fieldProps['require_verification']?.value
          ? VerifyType.everyone
          : VerifyType.never,
      allow_request_access: workspace.default_shares_allow_request_access,
      password_enabled: !!fieldProps['set_password'].value,
      password: fieldProps['set_password'].value
        ? fieldProps['password'].value
        : undefined,
      expires: !!fieldProps['expires'].value,
      expires_at: fieldProps['expires'].value ? expirydate : undefined,
      message_enabled: !!fieldProps['message']?.value,
      message: fieldProps['message']?.value,

      watermark: !!fieldProps['watermark']?.value,
      relationships: {
        node: shareId
          ? undefined
          : ({
              data: {
                id: nodeId,
                type: 'node'
              },
              id: nodeId,
              type: 'node'
            } as any),
        'render-presets': {
          data: selectedDownloadFormats
        } as any
      } as Share['relationships']
    };

    return newShare;
  }, [fieldProps, tempId, nodeId, workspace]);

  const [createShareInProgress, setCreateShareInProgress] = useState(false);

  const commitShare = useCallback(() => {
    if (!shareId) {
      createShare(configuredShare);
    } else {
      mutateShare(configuredShare);
    }

    // set loading boolean to true
    setCreateShareInProgress(true);
  }, [configuredShare, createShare, shareId]);

  return {
    commitShare,
    createShareInProgress,
    createShareStatus,
    mutateShareStatus
  };
};
