import { iconClosePath } from './index';

import { ReactComponent as Test } from '../collection/test_icon.svg';
import { ReactComponent as Refresh } from '../collection/refresh.svg';
import { ReactComponent as MediaQueue } from '../collection/media_queue.svg';
import { ReactComponent as Tag } from '../collection/tag.svg';
import { ReactComponent as QuickNav } from '../collection/quickNav1.svg';
import { ReactComponent as YouTube } from '../collection/youtube_red.svg';
import { ReactComponent as Jigsaw } from '../collection/Jigsaw.svg';
import { ReactComponent as ProgressDone } from '../collection/progress_done.svg';
import { ReactComponent as ProgressZero } from '../collection/progress_zero.svg';
import { ReactComponent as ProgressOneThird } from '../collection/progress_one_third.svg';
import { ReactComponent as ProgressTwoThirds } from '../collection/progress_two_thirds.svg';
import { ReactComponent as ProgressError } from '../collection/progress_error.svg';
import { ReactComponent as IconWarn } from '../collection/iconWarn.svg';

import { ReactComponent as IconOverView } from '../collection/iconOverView.svg';
import { ReactComponent as IconProperties } from '../collection/iconProperties.svg';
import { ReactComponent as IconClose } from '../collection/iconClose.svg';
import { ReactComponent as IconCamera } from '../collection/iconCamera.svg';
import { ReactComponent as IconCameraAdd } from '../collection/iconCameraAdd.svg';
import { ReactComponent as IconAlertWarning } from '../collection/iconAlertWarning.svg';
import { ReactComponent as IconHeart } from '../collection/iconHeart.svg';
import { ReactComponent as IconRename } from '../collection/iconRename.svg';
import { ReactComponent as IconExport } from '../collection/iconExport.svg';
import { ReactComponent as IconYoutube } from '../collection/iconYoutube.svg';
import { ReactComponent as IconPaste } from '../collection/iconPaste.svg';
import { ReactComponent as IconCut } from '../collection/iconCut.svg';
import { ReactComponent as IconHome } from '../collection/iconHome.svg';
import { ReactComponent as IconKid } from '../collection/iconKid.svg';
import { ReactComponent as IconLabel } from '../collection/iconLabel.svg';
import { ReactComponent as IconChart } from '../collection/iconChart.svg';
import { ReactComponent as IconUpload } from '../collection/iconUpload.svg';
import { ReactComponent as IconDuplicate } from '../collection/iconDuplicate.svg';
import { ReactComponent as IconAddFolder } from '../collection/iconAddFolder.svg';
import { ReactComponent as IconAdd } from '../collection/iconAdd.svg';
import { ReactComponent as IconArrowRight } from '../collection/iconArrowRight.svg';
import { ReactComponent as IconArrowDown } from '../collection/iconArrowDown.svg';
import { ReactComponent as IconUsageContracts } from '../collection/iconUsageContracts.svg';
import { ReactComponent as IconInfoPaper } from '../collection/iconInfoPaper.svg';
import { ReactComponent as IconDiscussions } from '../collection/iconDiscussions.svg';
import { ReactComponent as IconFile } from '../collection/iconFile.svg';
import { ReactComponent as IconContract } from '../collection/iconContract.svg';
import { ReactComponent as IconFolder } from '../collection/iconFolder.svg';
import { ReactComponent as IconArrowShare } from '../collection/iconArrowShare.svg';
import { ReactComponent as IconClipShare } from '../collection/iconClipShare.svg';
import { ReactComponent as IconGroup } from '../collection/iconGroup.svg';
import { ReactComponent as IconSearch } from '../collection/iconSearch.svg';
import { ReactComponent as IconProjects } from '../collection/iconProjects.svg';
import { ReactComponent as IconSharing } from '../collection/iconSharing.svg';
import { ReactComponent as IconCollections } from '../collection/iconCollections.svg';
import { ReactComponent as IconLibraries } from '../collection/iconLibraries.svg';
import { ReactComponent as IconPeopleTeams } from '../collection/iconPeopleTeams.svg';
import { ReactComponent as IconAdminConsole } from '../collection/iconAdminConsole.svg';
import { ReactComponent as IconVisibility } from '../collection/iconVisibility.svg';
import { ReactComponent as IconEdit } from '../collection/iconEdit.svg';
import { ReactComponent as IconDownload } from '../collection/iconDownload.svg';
import { ReactComponent as IconMore } from '../collection/iconMore.svg';
import { ReactComponent as IconSlash } from '../collection/iconSlash.svg';
import { ReactComponent as IconKeyboardArrowRight } from '../collection/iconKeyboardArrowRight.svg';
import { ReactComponent as IconKeyboardArrowLeft } from '../collection/iconKeyboardArrowLeft.svg';
import { ReactComponent as IconKeyboardArrowDown } from '../collection/iconKeyboardArrowDown.svg';
import { ReactComponent as IconCloudUpload } from '../collection/iconCloudUpload.svg';
import { ReactComponent as IconInfo } from '../collection/iconInfo.svg';
import { ReactComponent as IconWrench } from '../collection/iconWrench.svg';
import { ReactComponent as IconCross } from '../collection/iconCross.svg';
import { ReactComponent as IconSettings } from '../collection/iconSettings.svg';
import { ReactComponent as IconDelete } from '../collection/iconDelete.svg';
import { ReactComponent as IconElepsis } from '../collection/iconElepsis.svg';
import { ReactComponent as IconError } from '../collection/iconError.svg';
import { ReactComponent as IconBlancFile } from '../collection/iconBlancFile.svg';
import { ReactComponent as IconNotification } from '../collection/iconNotification.svg';
import { ReactComponent as IconNotificationClear } from '../collection/iconNotificationClear.svg';
import { ReactComponent as IconJumpBack10Sec } from '../collection/iconJumpBack10Sec.svg';
import { ReactComponent as IconFullscreen } from '../collection/iconFullscreen.svg';
import { ReactComponent as IconPlay } from '../collection/iconPlay.svg';
import { ReactComponent as IconPause } from '../collection/iconPause.svg';
import { ReactComponent as IconMaxVolume } from '../collection/iconMaxVolume.svg';
import { ReactComponent as IconExitFullscreen } from '../collection/iconExitFullscreen.svg';
import { ReactComponent as IconReplay } from '../collection/iconReplay.svg';
import { ReactComponent as IconMuteVolume } from '../collection/iconMuteVolume.svg';
import { ReactComponent as IconListLayout } from '../collection/iconListLayout.svg';
import { ReactComponent as IconGridLayout } from '../collection/iconGridLayout.svg';
import { ReactComponent as IconArrowUpwards } from '../collection/iconArrowUpwards.svg';
import { ReactComponent as IconArrowDownWards } from '../collection/iconArrowDownWards.svg';
import { ReactComponent as IconCircle } from '../collection/iconCircle.svg';
import { ReactComponent as IconCheck } from '../collection/iconCheck.svg';
import { ReactComponent as IconDash } from '../collection/iconDash.svg';
import { ReactComponent as IconRepeatArrowsUpper } from '../collection/iconRepeatArrowsUpper.svg';
import { ReactComponent as IconRepeatArrow } from '../collection/iconRepeatArrow.svg';
import { ReactComponent as IconRepeatArrowDown } from '../collection/iconRepeatArrowDown.svg';
import { ReactComponent as IconZoomIn } from '../collection/iconZoomIn.svg';
import { ReactComponent as IconZoomIn2ndPath } from '../collection/iconZoomIn2ndPath.svg';
import { ReactComponent as IconZoomOut } from '../collection/iconZoomOut.svg';
import { ReactComponent as IconExitToApp } from '../collection/iconExitToApp.svg';
import { ReactComponent as IconAccountSettings } from '../collection/iconAccountSettings.svg';
import { ReactComponent as IconSwap } from '../collection/iconSwap.svg';
import { ReactComponent as IconOpenExternal } from '../collection/iconOpenExternal.svg';
import { ReactComponent as IconPerson } from '../collection/iconPerson.svg';
import { ReactComponent as IconWorkspace } from '../collection/iconWorkspace.svg';
import { ReactComponent as IconSignOut } from '../collection/iconSignOut.svg';
import { ReactComponent as IconSend } from '../collection/iconSend.svg';
import { ReactComponent as IconRestoreArrow } from '../collection/iconRestoreArrow.svg';
import { ReactComponent as IconGoToLocation } from '../collection/iconGoToLocation.svg';
import { ReactComponent as IconTimes } from '../collection/iconTimes.svg';
import { ReactComponent as IconUncheckedCheckbox } from '../collection/iconUncheckedCheckbox.svg';
import { ReactComponent as IconCheckbox } from '../collection/iconCheckbox.svg';
import { ReactComponent as IconCheckboxChecked } from '../collection/iconCheckboxChecked.svg';
import { ReactComponent as IconCheckboxIndeterminate } from '../collection/iconCheckboxIndeterminate.svg';
import { ReactComponent as IconCheckboxFilled } from '../collection/iconCheckboxFilled.svg';
import { ReactComponent as IconRadioBorder } from '../collection/iconRadioBorder.svg';
import { ReactComponent as IconRadioDot } from '../collection/iconRadioDot.svg';
import { ReactComponent as IconPauseLeft } from '../collection/iconPauseLeft.svg';
import { ReactComponent as IconPauseRight } from '../collection/iconPauseRight.svg';
import { ReactComponent as IconStop } from '../collection/iconStop.svg';
import { ReactComponent as IconSoundWaveLarge } from '../collection/iconSoundWaveLarge.svg';
import { ReactComponent as IconSoundWaveSmall } from '../collection/iconSoundWaveSmall.svg';
import { ReactComponent as IconSpeaker } from '../collection/iconSpeaker.svg';
import { ReactComponent as IconMuteCross } from '../collection/iconMuteCross.svg';
import { ReactComponent as IconMuteSpeaker } from '../collection/iconMuteSpeaker.svg';
import { ReactComponent as IconMuteSpeakerWave } from '../collection/iconMuteSpeakerWave.svg';
import { ReactComponent as IconMaximizeVector } from '../collection/iconMaximizeVector.svg';
import { ReactComponent as IconMinimizeVector } from '../collection/iconMinimizeVector.svg';
import { ReactComponent as IconChevronLeft } from '../collection/iconChevronLeft.svg';
import { ReactComponent as IconChevronRight } from '../collection/iconChevronRight.svg';
import { ReactComponent as IconFileRequest } from '../collection/iconFileRequest.svg';
import { ReactComponent as IconPlayPlay } from '../collection/iconPlayPlay.svg';
import { ReactComponent as IconArchived } from '../collection/iconArchived.svg';
import { ReactComponent as IconUnarchived } from '../collection/iconUnarchived.svg';
import { ReactComponent as IconShield } from '../collection/iconShield.svg';
import { ReactComponent as IconTV } from '../collection/iconTV.svg';
import { ReactComponent as IconClosePath } from '../collection/iconClosePath.svg';
import { ReactComponent as AtlassianOriginal } from '../collection/AtlassianOriginal.svg';
import { ReactComponent as AtlassianWhite } from '../collection/AtlassianWhite.svg';
import { ReactComponent as AzureOriginal } from '../collection/AzureOriginal.svg';
import { ReactComponent as BitbucketOriginal } from '../collection/BitbucketOriginal.svg';
import { ReactComponent as BitbucketWhite } from '../collection/BitbucketWhite.svg';
import { ReactComponent as GitLabOriginal } from '../collection/GitLabOriginal.svg';
import { ReactComponent as GitLabWhite } from '../collection/GitLabWhite.svg';
import { ReactComponent as GoogleOriginal } from '../collection/GoogleOriginal.svg';
import { ReactComponent as AppleOriginal } from '../collection/AppleOriginal.svg';
import { ReactComponent as AppleWhite } from '../collection/AppleWhite.svg';
import { ReactComponent as Auth0Original } from '../collection/Auth0Original.svg';
import { ReactComponent as Auth0White } from '../collection/Auth0White.svg';
import { ReactComponent as MetaOriginal } from '../collection/FacebookOriginal.svg';
import { ReactComponent as MetaWhite } from '../collection/FacebookWhite.svg';
import { ReactComponent as GitHubOriginal } from '../collection/GitHubOriginal.svg';
import { ReactComponent as GitHubWhite } from '../collection/GitHubWhite.svg';
import { ReactComponent as LinkedInOriginal } from '../collection/LinkedInOriginal.svg';
import { ReactComponent as LinkedInWhite } from '../collection/LinkedInWhite.svg';
import { ReactComponent as TwitterOriginal } from '../collection/TwitterOriginal.svg';
import { ReactComponent as TwitterWhite } from '../collection/TwitterWhite.svg';
import { ReactComponent as OktaOriginal } from '../collection/OktaOriginal.svg';
import { ReactComponent as OktaWhite } from '../collection/OktaWhite.svg';
import { ReactComponent as Microsoft } from '../collection/Microsoft.svg';
import { ReactComponent as IconProjectTemplateFilled } from '../collection/iconProjectTemplateFilled.svg';
import { ReactComponent as IconProjectTemplate } from '../collection/iconProjectTemplate.svg';
import { ReactComponent as IconProjectNew } from '../collection/iconProjectNew.svg';
import { ReactComponent as IconProjectNewFilled } from '../collection/iconProjectNewFilled.svg';
import { ReactComponent as IconStarFilled } from '../collection/iconStarFilled.svg';
import { ReactComponent as IconStar } from '../collection/iconStar.svg';
import { ReactComponent as IconGesture } from '../collection/iconGesture.svg';
import { ReactComponent as IconEditUnFilled } from '../collection/iconEditUnFilled.svg';
import { ReactComponent as IconEraser } from '../collection/iconEraser.svg';
import { ReactComponent as IconEraserFill } from '../collection/iconEraserFill.svg';
import { ReactComponent as IconRedo } from '../collection/iconRedo.svg';
import { ReactComponent as IconRemove } from '../collection/iconRemove.svg';
import { ReactComponent as ViewSidebarLeft } from '../collection/viewSidebarLeft.svg';
import { ReactComponent as ViewSidebarLeftFill } from '../collection/viewSidebarLeftFill.svg';
import { ReactComponent as IconUrlAdd } from '../collection/iconUrlAdd.svg';
import { ReactComponent as IconArrowRightWards } from '../collection/iconArrowRightWards.svg';
import { ReactComponent as Meta } from '../collection/meta.svg';
import { ReactComponent as CM360 } from '../collection/cm360.svg';
import { ReactComponent as GoogleAds } from '../collection/googleads.svg';
import { ReactComponent as Contentful } from '../collection/contentful.svg';

export const iconFiles = {
  test: Test,
  refresh: Refresh,
  mediaQueue: MediaQueue,
  tag: Tag,
  quickNav: QuickNav,
  youtube: YouTube,
  jigsaw: Jigsaw,
  iconWarn: IconWarn,
  // progress badges
  progressDone: ProgressDone,
  progressZero: ProgressZero,
  progressOneThird: ProgressOneThird,
  progressTwoThirds: ProgressTwoThirds,
  progressError: ProgressError,

  iconOverView: IconOverView,
  iconProperties: IconProperties,
  iconClose: IconClose,
  iconCamera: IconCamera,
  iconCameraAdd: IconCameraAdd,
  iconAlertWarning: IconAlertWarning,
  iconHeart: IconHeart,
  iconRename: IconRename,
  iconExport: IconExport,
  iconYoutube: IconYoutube,
  iconPaste: IconPaste,
  iconCut: IconCut,
  iconHome: IconHome,
  iconKid: IconKid,
  iconLabel: IconLabel,
  iconChart: IconChart,
  iconUpload: IconUpload,
  iconDuplicate: IconDuplicate,
  iconAddFolder: IconAddFolder,
  iconAdd: IconAdd,
  iconArrowRight: IconArrowRight,
  iconArrowDown: IconArrowDown,
  iconUsageContracts: IconUsageContracts,
  iconInfoPaper: IconInfoPaper,
  iconDiscussions: IconDiscussions,
  iconFile: IconFile,
  iconContract: IconContract,
  iconFolder: IconFolder,
  iconArrowShare: IconArrowShare,
  iconClipShare: IconClipShare,
  iconGroup: IconGroup,
  iconSearch: IconSearch,
  iconProjects: IconProjects,
  iconSharing: IconSharing,
  iconCollections: IconCollections,
  iconLibraries: IconLibraries,
  iconPeopleTeams: IconPeopleTeams,
  iconAdminConsole: IconAdminConsole,
  iconVisibility: IconVisibility,
  iconEdit: IconEdit,
  iconDownload: IconDownload,
  iconMore: IconMore,
  iconSlash: IconSlash,
  iconKeyboardArrowRight: IconKeyboardArrowRight,
  iconKeyboardArrowLeft: IconKeyboardArrowLeft,
  iconKeyboardArrowDown: IconKeyboardArrowDown,
  iconCloudUpload: IconCloudUpload,
  iconInfo: IconInfo,
  iconWrench: IconWrench,
  iconCross: IconCross,
  iconSettings: IconSettings,
  iconDelete: IconDelete,
  iconElepsis: IconElepsis,
  iconError: IconError,
  iconBlancFile: IconBlancFile,
  iconNotification: IconNotification,
  iconNotificationClear: IconNotificationClear,
  iconJumpBack10Sec: IconJumpBack10Sec,
  iconFullscreen: IconFullscreen,
  iconPlay: IconPlay,
  iconPause: IconPause,
  iconMaxVolume: IconMaxVolume,
  iconExitFullscreen: IconExitFullscreen,
  iconReplay: IconReplay,
  iconMuteVolume: IconMuteVolume,
  iconListLayout: IconListLayout,
  iconGridLayout: IconGridLayout,
  iconArrowUpwards: IconArrowUpwards,
  iconArrowDownWards: IconArrowDownWards,
  iconCircle: IconCircle,
  iconCheck: IconCheck,
  iconDash: IconDash,
  iconRepeatArrow: IconRepeatArrow,
  iconRepeatArrowsUpper: IconRepeatArrowsUpper,
  iconRepeatArrowDown: IconRepeatArrowDown,
  iconZoomIn: IconZoomIn,
  iconZoomIn2ndPath: IconZoomIn2ndPath,
  iconZoomOut: IconZoomOut,
  iconExitToApp: IconExitToApp,
  iconAccountSettings: IconAccountSettings,
  iconSwap: IconSwap,
  iconOpenExternal: IconOpenExternal,
  iconPerson: IconPerson,
  iconWorkspace: IconWorkspace,
  iconSignOut: IconSignOut,
  iconSend: IconSend,
  iconRestoreArrow: IconRestoreArrow,
  iconGoToLocation: IconGoToLocation,
  iconTimes: IconTimes,
  iconUncheckedCheckbox: IconUncheckedCheckbox,
  iconCheckbox: IconCheckbox,
  iconCheckboxChecked: IconCheckboxChecked,
  iconCheckboxIndeterminate: IconCheckboxIndeterminate,
  iconCheckboxFilled: IconCheckboxFilled,
  iconRadioBorder: IconRadioBorder,
  iconRadioDot: IconRadioDot,
  iconPauseLeft: IconPauseLeft,
  iconPauseRight: IconPauseRight,
  iconStop: IconStop,
  iconSoundWaveLarge: IconSoundWaveLarge,
  iconSoundWaveSmall: IconSoundWaveSmall,
  iconSpeaker: IconSpeaker,
  iconMuteCross: IconMuteCross,
  iconMaximizeVector: IconMaximizeVector,
  iconMinimizeVector: IconMinimizeVector,
  iconChevronLeft: IconChevronLeft,
  iconChevronRight: IconChevronRight,
  iconFileRequest: IconFileRequest,
  iconPlayPlay: IconPlayPlay,
  iconArchived: IconArchived,
  iconUnarchived: IconUnarchived,
  iconShield: IconShield,
  iconTV: IconTV,
  iconClosePath: IconClosePath,
  iconMuteSpeaker: IconMuteSpeaker,
  iconMuteSpeakerWave: IconMuteSpeakerWave,
  atlassianOriginal: AtlassianOriginal,
  atlassianWhite: AtlassianWhite,
  azureOriginal: AzureOriginal,
  bitbucketOriginal: BitbucketOriginal,
  bitbucketWhite: BitbucketWhite,
  gitLabOriginal: GitLabOriginal,
  gitLabWhite: GitLabWhite,
  googleOriginal: GoogleOriginal,
  appleOriginal: AppleOriginal,
  appleWhite: AppleWhite,
  auth0Original: Auth0Original,
  auth0White: Auth0White,
  metaOriginal: MetaOriginal,
  metaWhite: MetaWhite,
  gitHubOriginal: GitHubOriginal,
  gitHubWhite: GitHubWhite,
  twitterOriginal: TwitterOriginal,
  twitterWhite: TwitterWhite,
  linkedInOriginal: LinkedInOriginal,
  linkedInWhite: LinkedInWhite,
  oktaOriginal: OktaOriginal,
  oktaWhite: OktaWhite,
  microsoft: Microsoft,
  iconProjectTemplate: IconProjectTemplate,
  iconProjectTemplateFilled: IconProjectTemplateFilled,
  iconProjectNew: IconProjectNew,
  iconProjectNewFilled: IconProjectNewFilled,
  iconGesture: IconGesture,
  iconEditUnFilled: IconEditUnFilled,
  iconEraserFill: IconEraserFill,
  iconEraser: IconEraser,
  iconRedo: IconRedo,
  iconStar: IconStar,
  iconStarFilled: IconStarFilled,
  iconRemove: IconRemove,
  viewSidebarLeftFill: ViewSidebarLeftFill,
  viewSidebarLeft: ViewSidebarLeft,
  iconUrlAdd: IconUrlAdd,
  iconArrowRightWards: IconArrowRightWards,
  meta: Meta,
  cm360: CM360,
  googleAds: GoogleAds,
  contentful: Contentful
} as const;
