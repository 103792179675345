import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { actionCreators as nodeActionCreators } from '../../ducks/resource-nodes';
import { actionCreators as edgeActionCreators } from '../../ducks/resource-edges/actions';
import { mutations } from '../../ducks/resource-edges';
import {
  RelationshipIdentifier,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { useCurrentWorkspace } from '../Administration/withCurrentWorkspace';
import { useModalActions } from '../../Hooks/useModalActions';
import { useSelection } from '../useSelection';
import { useResourceList__ALPHA } from './useResourceList';
import { useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useCurrentAccount } from '../Administration/withCurrentUserAccount';

/// <Summary>
/**
- paginated list of items in the bin as nodes: `GET:/workspaces/<id>/deleted-nodes/?page[size]=25&page[number]=1`

- list all items in the bin as resource identifiers: `GET:/workspaces/<id>/relationships/deleted-nodes/`

*/
/// </Summary>

interface InjectedProps {
  canDelete: boolean;
  //// SOFT DELETE
  /** move the selected items to trash bin */
  deleteResourcesFromSelection: () => void;
  /** move the target resources to trash bin */
  deleteResources: (
    targetResources: ResourceIdentifier[],
    invalidateCache?: Array<ResourceIdentifier | RelationshipIdentifier>
  ) => void;
  //// PERMANENTLY DELETE
  /** PATCH: remove items from the bin (select all)
   * - remove all items in bin permanently (select all)
   * - remove all items in bin except for the inverted selection, permanently (select all -> deselect 2)
   */
  permanentlyDeleteFromInvertedSelection: (optimize?: boolean) => void;
  /** DELETE: permanently delete the selected items in trash bin */
  permanentlyDeleteFromSelection: (optimize?: boolean) => void;
  /// RESTORE
  /** PATCH: restore items from the bin:
   * - restore all items in bin permanently (select all)
   * - restore all items in bin except for the unselected items (select all -> deselect 2)
   */
  restoreFromInvertedSelection: (optimize?: boolean) => void;
  /** DELETE: restore the selected items from the bin */
  restoreFromSelection: (optimize?: boolean) => void;
}

interface InjectedModalActions {
  openDeletePrompt: () => void;
  openDeletePermanentlyPrompt: () => void;
}

export type DeleteActionProps = InjectedProps & InjectedModalActions;
type CacheInvalidators = Array<ResourceIdentifier | RelationshipIdentifier>;

const emptyArray = [];

export const useDeleteActions = (workspaceOverride?: string): InjectedProps => {
  const [sessionWorkspaceId] = useCurrentWorkspace();

  const workspaceId = workspaceOverride || sessionWorkspaceId;

  const [accountId] = useCurrentAccount();
  const { selection = emptyArray, types } = useSelection();
  const location = useLocation();

  const inSearchRoot = matchPath(location.pathname, {
    path: '/workspace/:workspaceId/search/'
  })?.isExact;

  const { resources: ancestors } = useResourceList__ALPHA({
    resourceType:
      selection?.length > 0 && (types.asset || types.folder)
        ? selection[0]?.type
        : undefined,
    resourceId: selection[0]?.id,
    edgeType: selection[0] ? 'folder' : undefined,
    edgeLabel: 'ancestors'
  });

  const { first: library } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'library',
    edgeLabel: 'libraries'
  });

  // NOTE: don't think this ever worked ?
  //const canDelete = selection?.filter(item => item.locked).length === 0
  const canDelete = true;

  const workspaceIdentifier: ResourceIdentifier = useRef({
    type: 'workspace',
    id: workspaceId
  } as ResourceIdentifier).current;

  const parentIdentifier = useMemo(
    () => ({
      type: 'folder',
      id:
        ancestors && ancestors.length > 0
          ? ancestors[ancestors.length - 1].id
          : library?.id
    }),
    [ancestors, library]
  ) as ResourceIdentifier;

  const dispatch = useDispatch();

  /** move items to trash bin (soft delete)
   * - POST: `/workspaces/<id>/relationships/deleted-nodes/`
   */
  const deleteResources = useCallback(
    (resources: ResourceIdentifier[], invalidateCache?: CacheInvalidators) => {
      resources.forEach((i) => {
        dispatch(nodeActionCreators.disposeResource(i.type, i.id, true));
      });

      dispatch(
        edgeActionCreators.mutateManyResourceEdges({
          mutations: resources.map((i) => ({
            ancestor: workspaceIdentifier,
            mutationType: mutations.MUTATION_ADD,
            relationshipLabel: 'deleted-nodes',
            resource: {
              type: 'content-tree-node',
              id: i.id
            }
          })),
          options: {
            optimize: true,
            JSONApi: true
          },
          invalidatesCache: [
            ...(invalidateCache ? invalidateCache : []),
            {
              type: 'workspace',
              id: workspaceId,
              relationship: 'search-results'
            },
            {
              type: 'workspace',
              id: workspaceId,
              relationship: 'search'
            },
            {
              ...parentIdentifier,
              relationship: 'assets'
            },
            {
              ...parentIdentifier,
              relationship: 'folders'
            },
            {
              type: 'account',
              id: accountId,
              relationship: 'favorites'
            }
          ].filter(Boolean) as CacheInvalidators
        })
      );
    },
    [
      dispatch,
      selection,
      parentIdentifier,
      inSearchRoot,
      workspaceId,
      workspaceIdentifier,
      accountId
    ]
  );

  /** POST: move selected items to trash bin */
  const deleteResourcesFromSelection = useCallback(() => {
    if (!selection?.length) return;
    const targetResources = selection.map((s) => ({ id: s.id, type: s.type }));
    deleteResources(targetResources);
  }, [deleteResources, selection]);

  //#region trash bin

  const trashBinCacheInvalidators = useMemo(() => {
    return [
      {
        type: 'workspace' as const,
        id: workspaceId,
        relationship: 'deleted-nodes'
      },
      {
        type: 'workspace' as const,
        id: workspaceId,
        relationship: 'relationships/deleted-nodes'
      },
      {
        type: 'account' as const,
        id: accountId,
        relationship: 'favorites'
      }
    ];
  }, [workspaceId, accountId]);

  /** update to-many relationship by sending a `DELETE` request
   * - `DELETE: /workspaces/<id>/relationships/deleted-nodes/?action[${action}]=true`
   */
  const onMutationRemove = useCallback(
    (
      targetResources: ResourceIdentifier[],
      action: 'delete_permanently' | 'restore',
      optimize = false
    ) => {
      dispatch(
        edgeActionCreators.mutateManyResourceEdges({
          mutations: targetResources.map((i) => ({
            ancestor: workspaceIdentifier,
            mutationType: mutations.MUTATION_REMOVE,
            relationshipLabel: `deleted-nodes/?action[${action}]=true`,
            resource: {
              type: 'content-tree-node',
              id: i.id
            }
          })),
          options: { optimize, JSONApi: true },
          invalidatesCache: trashBinCacheInvalidators
        })
      );
    },
    [dispatch, trashBinCacheInvalidators, workspaceIdentifier]
  );
  /** update to-many relationship by sending a `PATCH` request */
  const onMutationPatch = useCallback(
    (
      exception: ResourceIdentifier[],
      action: 'delete_permanently' | 'restore',
      optimize = false,
      invalidatesCache = []
    ) => {
      const relationshipLabel = `deleted-nodes/?action[${action}]=true`;
      // clear the relationship (delete/restore all)
      if (exception.length === 0) {
        dispatch(
          edgeActionCreators.updateResourceEdge({
            resourceType: 'workspace',
            resourceId: workspaceId,
            edgeType: 'content-tree-node',
            edgeLabel: relationshipLabel,
            edgeResource: [],
            options: { optimize },
            invalidatesCache: trashBinCacheInvalidators
          })
        );
        return;
      }
      dispatch(
        edgeActionCreators.mutateManyResourceEdges({
          mutations: exception.map((i) => ({
            ancestor: workspaceIdentifier,
            mutationType: mutations.MUTATION_REPLACE,
            relationshipLabel: relationshipLabel,
            resource: {
              type: 'content-tree-node',
              id: i.id
            }
          })),
          options: { optimize, JSONApi: true },
          invalidatesCache: [...trashBinCacheInvalidators, ...invalidatesCache]
        })
      );
    },
    [dispatch, workspaceId, trashBinCacheInvalidators, workspaceIdentifier]
  );

  /** DELETE: permanently delete selected items (with `selecteAll === false`) */
  const permanentlyDeleteFromSelection = useCallback(
    (optimize = false) => {
      onMutationRemove(selection, 'delete_permanently', optimize);
    },
    [selection, onMutationRemove]
  );
  /** PATCH: with `selectAll === true`
   * - remove all items in bin permanently (select all)
   * - remove all items in bin except for two, permanently (select all -> deselect 2)
   */
  const permanentlyDeleteFromInvertedSelection = useCallback(
    (optimize = false) => {
      onMutationPatch(selection, 'delete_permanently', optimize);
    },
    [onMutationPatch, selection]
  );

  /** PATCH: use to
   * - restore all items in bin permanently (select all)
   * - restore all items in bin except for two (select all -> deselect 2)
   */
  const restoreFromInvertedSelection = useCallback(
    (optimize = false) => {
      onMutationPatch(selection, 'restore', optimize, [
        {
          type: 'account',
          id: accountId,
          relationship: 'favorites'
        }
      ]);
    },
    [onMutationPatch, selection, accountId]
  );

  /** DELETE: restore selected items in bin (with `selectAll === false`) */
  const restoreFromSelection = useCallback(
    (optimize: boolean = false) => {
      onMutationRemove(selection, 'restore', optimize);
    },
    [onMutationRemove, selection]
  );

  return {
    canDelete,
    // soft delete
    deleteResources,
    deleteResourcesFromSelection,
    // permanently delete
    permanentlyDeleteFromInvertedSelection,
    permanentlyDeleteFromSelection,
    // restore
    restoreFromInvertedSelection,
    restoreFromSelection
  };
};

export const useDeleteModals = () => {
  const { openModal } = useModalActions<
    'assets_delete' | 'assets_delete_permanent'
  >();
  const openDeletePrompt = useCallback(
    () => openModal('assets_delete'),
    [openModal]
  );

  return useMemo(
    () => ({
      openDeletePrompt
    }),
    [openDeletePrompt]
  );
};
