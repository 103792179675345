import * as React from 'react';
import { makeCSS, WithTheme } from '../../../utils/makeCSS';

/*A visual indicator used to separate or organize content and showcase different sections inside Cube. */
export interface DividerProps {
  /** fixed width or height */
  length?: number | string;
  thickness?: number;
  /**THe orientation of the divider */
  direction?: 'vertical' | 'horizontal';
  /**The color it can have */
  color?: 'default' | 'line1' | 'line2' | 'line3'; //only the default color should be used, the rest are just to showcase the different color it can have
}

const useCSS = makeCSS(
  ({ theme, length, thickness = 1 }: WithTheme<DividerProps>) => {
    return {
      root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch'
      },
      vertical: {
        width: thickness,
        height: length || 'auto'
      },
      horizontal: {
        height: thickness,
        width: length || 'auto'
      },
      /**
       * The default background uses the current color of the page
       * and it is the one that will be used for the final Divider
       * the background color of the Divider should be one of the thre.
       */
      default: {
        background: 'currentcolor'
      },
      line1: {
        background: theme.color.line['01']
      },
      line2: {
        background: theme.color.line['02']
      },
      line3: {
        background: theme.color.line['03']
      }
    };
  }
);

export const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  (props, ref) => {
    const { direction = 'horizontal', color = 'line2' } = props;

    const classes = useCSS(props);

    return (
      <div
        ref={ref}
        className={`${classes.root} ${classes[color]} ${classes[direction]} `}
      ></div>
    );
  }
);
