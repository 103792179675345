import { useDispatch } from 'react-redux';
import { useModalActions } from '../../Modals/modalActions';
import { useCallback } from 'react';
import { destroy } from 'redux-form';
import {
  basePath,
  useOpenInternalLink
} from '@cube3/state/src/Hoocs/Routing/withOpenInternalLink';
import { ShareIntentType } from '@cube3/state/types';

export const linkPath = {
  share: '/share-link/' as basePath,
  'review-link': '/review-link/' as basePath,
  'review-legacy': '/review-link/' as basePath,
  review: '/review/' as basePath,
  request: '/file-request/' as basePath
};

/**
 * @param id : resource id
 * @param intent: "review" | "review-legacy" | "share" | "request"
 * @param token : use to open share/review link in a new tab
 */
export const useLinkSettingsActions = (props: {
  id?: string;
  intent: ShareIntentType;
  token?: string;
}) => {
  const { id, intent, token } = props;

  const { openModal, previousModal, closeAllModals } = useModalActions();
  const dispatch = useDispatch();
  const { openInNewTab } = useOpenInternalLink();

  const onCloseEvent = useCallback(() => {
    previousModal();
    dispatch(destroy(id));
  }, [dispatch, destroy, closeAllModals, id]);

  const onClickDeleteLink = useCallback(() => {
    openModal('sharelink_delete', { id });
  }, [openModal, id, intent]);

  const onClickManageAccess = useCallback(() => {
    openModal('sharelink_manageaccess', {
      id,
      intent
    });
  }, [openModal, id, intent]);

  const onClickOpenLink = useCallback(() => {
    if (token && intent && linkPath[intent]) {
      openInNewTab(linkPath[intent], token, {
        __UNSAFE: true
      });
    }
  }, [openModal, token, intent]);

  const onClickAddUser = useCallback(() => {
    openModal('sharelink_invitepeople', {
      id,
      intent
    });
  }, [openModal, id, intent]);

  const onCopyLink = useCallback(() => {
    if (token && intent) {
      navigator.clipboard.writeText(
        `${window.location.origin}${linkPath[intent]}${token}`
      );
    }
  }, [token, intent]);

  return {
    onCloseEvent,
    onClickDeleteLink,
    onClickManageAccess,
    onClickOpenLink,
    onClickAddUser,
    onCopyLink
  };
};
