import {
  ContentTreeContext,
  SortableListType
} from '@cube3/common/model/resource-types';
import { ns } from './config';
import { QueueTab } from './types';

export const TRACK_FULLSCREEN = `${ns}/TRACK_FULLSCREEN`;
export const TOGGLE_FULLSCREEN = `${ns}/TOGGLE_FULLSCREEN`;
//
export const TOGGLE_GRIDVIEW = `${ns}/TOGGLE_GRIDVIEW`;
export const TOGGLE_LISTVIEW = `${ns}/TOGGLE_LISTVIEW`;
export const SET_CONTAINER_WIDTH = `${ns}/SET_CONTAINER_WIDTH`;

export const SET_ORDERTYPE = `${ns}/SET_ORDERTYPE`;
export const SET_ORDERDIRECTION = `${ns}/SET_ORDERDIRECTION`;

export const SET_TOUR = `${ns}/SET_TOUR`;

export const TOGGLE_INFOPANE = `${ns}/TOGGLE_INFOPANE`;
export const TOGGLE_MEDIAQUEUE = `${ns}/TOGGLE_MEDIAQUEUE`;
export const SET_UPLOAD_QUEUE_BUSY = `${ns}/SET_UPLOAD_QUEUE_BUSY`;

// search
export const SET_COLUMN_WIDTH = `${ns}/SET_COLUMN_WIDTH`;
export const TOGGLE_COLUMN = `${ns}/TOGGLE_COLUMN`;
export const SET_COLUMNS = `${ns}/SET_COLUMNS`;
export const MOVE_COLUMN = `${ns}/MOVE_COLUMN`;
/** used for resizable columns */
export const SET_MAX_LIST_COLUMNS = `${ns}/SET_MAX_LIST_COLUMNS`;

export const actions = {
  TRACK_FULLSCREEN,
  TOGGLE_FULLSCREEN,
  TOGGLE_GRIDVIEW,
  TOGGLE_LISTVIEW,
  SET_CONTAINER_WIDTH,
  SET_ORDERTYPE,
  SET_ORDERDIRECTION,
  TOGGLE_INFOPANE,
  TOGGLE_MEDIAQUEUE,
  SET_UPLOAD_QUEUE_BUSY,
  SET_TOUR,
  TOGGLE_COLUMN,
  SET_COLUMN_WIDTH,
  SET_COLUMNS,
  SET_MAX_LIST_COLUMNS,
  MOVE_COLUMN
};

export function toggleFullscreen() {
  return {
    type: TOGGLE_FULLSCREEN
  };
}

export function trackFullscreen(enabled) {
  return {
    type: TRACK_FULLSCREEN,
    payload: enabled
  };
}

export function toggleInfoPane() {
  return {
    type: TOGGLE_INFOPANE
  };
}

export function toggleMediaqueue(
  force: boolean = undefined,
  tab: QueueTab = undefined
) {
  return {
    type: TOGGLE_MEDIAQUEUE,
    payload: {
      tab,
      force
    }
  };
}

export function toggleGridView() {
  return {
    type: TOGGLE_GRIDVIEW
  };
}

export function toggleListView() {
  return {
    type: TOGGLE_LISTVIEW
  };
}

export function setContainerWidth(width: number) {
  return {
    type: SET_CONTAINER_WIDTH,
    payload: width
  };
}

export function setSortType(value: {
  field: string;
  listType: ContentTreeContext;
}) {
  return {
    type: SET_ORDERTYPE,
    payload: {
      listType: value.listType || 'library',
      value: value.field
    }
  };
}

export function setOrderDirection(value: {
  fieldParent: string;
  field: string;
  listType: ContentTreeContext;
}) {
  return {
    type: SET_ORDERDIRECTION,
    payload: {
      listType: value.listType || 'library',
      value: value.field,
      fieldParent: value.fieldParent
    }
  };
}

export function setUploadQueueBusy(busy: boolean) {
  return {
    type: SET_UPLOAD_QUEUE_BUSY,
    payload: busy
  };
}

export const setTour = (tour: boolean) => {
  return {
    type: SET_TOUR,
    payload: tour
  };
};

export const toggleColumn = (
  variant: ContentTreeContext,
  column: SortableListType,
  show?: boolean
) => {
  return {
    type: TOGGLE_COLUMN,
    payload: { variant, column, show }
  };
};

export const moveColumn = (
  variant: ContentTreeContext,
  column: SortableListType,
  direction: 'left' | 'right'
) => {
  return {
    type: MOVE_COLUMN,
    payload: { variant, column, direction }
  };
};

export const setColumns = (
  variant: ContentTreeContext,
  value: { columns: SortableListType[]; widths: number[] }
) => {
  return {
    type: SET_COLUMNS,
    payload: { variant, ...value }
  };
};

export const setColumnWidth = (
  variant: ContentTreeContext,
  index: number,
  width: number | string
) => {
  return {
    type: SET_COLUMN_WIDTH,
    payload: { variant, index, width }
  };
};

export const setMaxListColumns = (value: number) => {
  return {
    type: SET_MAX_LIST_COLUMNS,
    payload: value
  };
};

export const actionCreators = {
  toggleFullscreen,
  trackFullscreen,
  toggleGridView,
  toggleListView,
  setContainerWidth,
  setOrderDirection,
  setSortType,
  toggleMediaqueue,
  setUploadQueueBusy,
  setTour,
  toggleColumn,
  setColumnWidth,
  setColumns,
  setMaxListColumns,
  moveColumn
};
