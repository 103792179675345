import * as React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { compareProps } from '../../utils/compareProps';
import useTruncateText from '../../helpers/hooks/useTruncateText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      wordBreak: 'break-all'
    },
    customWidth: {
      maxWidth: 'none'
    },
    content: {
      display: 'flex',
      flexDirection: 'column'
    },
    singleLine: {
      maxHeight: 20,
      overflowY: 'hidden'
    },
    //if the str is not truncated then hide it
    hidden: {
      opacity: 0
    }
  })
);

interface InterfaceTruncateProps {
  str: string;
  fontsLoaded?: boolean;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start';
  toolTip?: boolean;
  widthLimit?: number;
  lines?: number;
  suffixComponent?: JSX.Element;
  /** use to generate cacheKey to store the last truncated text */
  cachePrefix?: string;
}

const fontsLoaded = true; // NOTE: we use a preloader now

const arrayToString = (arr: string[]) =>
  arr.reduce((acc, str) => acc + str, '');

export const Truncate = React.memo<
  React.PropsWithChildren<InterfaceTruncateProps>
>((props) => {
  const classes = useStyles();
  const {
    str,
    placement,
    toolTip = true,
    widthLimit,
    lines = 1,
    suffixComponent,
    cachePrefix
  } = props;

  const suffixComponentRef = React.useRef<HTMLSpanElement>(null);

  const { containerRef, stringsArray } = useTruncateText({
    str,
    fontsLoaded,
    widthLimit,
    lines,
    suffixComponentRef,
    cachePrefix
  });

  const classOverrides = React.useRef({ tooltip: classes.customWidth });

  return (
    <div ref={containerRef} className={classes.root}>
      <Tooltip
        title={str ? str : ''}
        placement={placement}
        disableFocusListener={true}
        disableTouchListener={true}
        disableHoverListener={!toolTip || arrayToString(stringsArray) === str}
        classes={classOverrides.current}
      >
        <div
          className={`${classes.content} ${
            lines === 1 ? classes.singleLine : ''
          }`}
        >
          {lines === 1 && !stringsArray.length ? (
            <span data-cy="truncateSpan" className={classes.hidden}>
              {str}
              {suffixComponent && (
                <span
                  ref={suffixComponentRef}
                  style={{ display: 'inline-block', flexShrink: 0 }}
                >
                  {suffixComponent}
                </span>
              )}
            </span>
          ) : (
            stringsArray.map((txt, idx) => (
              <span
                key={idx}
                data-cy="truncateSpan"
                data-pw={str}
                style={{
                  wordBreak: stringsArray.length === 1 ? 'break-word' : 'unset',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {txt}
                {idx === stringsArray.length - 1
                  ? suffixComponent && (
                      <span
                        ref={suffixComponentRef}
                        style={{ display: 'inline-block', flexShrink: 0 }}
                      >
                        {suffixComponent}
                      </span>
                    )
                  : null}
              </span>
            ))
          )}
        </div>
      </Tooltip>
    </div>
  );
}, compareProps(['classes']));

export default Truncate;
