import React from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { isMacOs } from 'react-device-detect';
import { keyImages, macKeys } from './documentShortcut';
import { cx } from '@emotion/css';

/**The Key component represents the different keyboard keys.
 * We use the Key variant for the key shortcuts.
 * We display the modifier variant inside the ContextMenuListItem.
 */
interface KeyProps {
  /**Choose among different keys for a shortcut*/
  keyCode?: string;
  /**Choose between key and  modifier*/
  variant?: 'key' | 'modifier';
}

/**Adds style to the key component */
const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: theme.fontFamilies.body,
      fontWeight: theme.fontWeights.regular,
      fontStyle: 'normal'
    },
    key: {
      whiteSpace: 'pre',
      boxSizing: 'border-box',
      height: theme.spacing[12],
      minWidth: theme.spacing[12],
      background: theme.color.overlay['01'],
      fontSize: theme.fontSize[3],
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      alignContent: 'horizontal',
      justifyContent: 'center',
      borderRadius: theme.borderRadius.md,
      padding: [theme.spacing[0], theme.spacing[4]],
      border: `1px solid ${theme.color.line['03']}`,
      color: theme.color.text['01']
    },
    modifier: {
      height: 20,
      display: 'inline-flex',
      paddingLeft: theme.spacing[2],
      paddingRight: theme.spacing[2],
      alignItems: 'center',
      borderRadius: theme.borderRadius.md,
      border: `1px solid ${theme.color.line['03']}`,
      background: theme.color.overlay['02'],
      fontSize: theme.fontSize[2],
      color: theme.color.text['03']
    }
  };
});

/**The Key component takes keyCode as a prop and displays an image from keyImage if the image is available, otherwise it shows the text that represents the key. */

export const Key = (props: KeyProps) => {
  const { keyCode = 'a', variant = 'key' } = props;

  const classes = useCSS();

  return (
    <>
      <div className={classes.root}>
        <div className={cx(variant === 'key' ? classes.key : classes.modifier)}>
          {keyImages[keyCode]
            ? keyImages[keyCode]
            : keyCode.replace(/(Key|Digit)/, '')}
        </div>
      </div>
    </>
  );
};
