import { useClassName } from '@cube3/common/utils/useClassName';
import React, { EventHandler, useState } from 'react';
import { SizeVariants } from '../../../theme/themes';
import { makeCSS } from '../../../utils/makeCSS';
import { Icon } from '../Icon';
import { iconFiles } from '../Icon/files';
import { cx } from '@emotion/css';

/**
 * The Inputfield components have the same style and mostly the same layout.
 * We use the InputFieldTemplate to avoid repetition.
 */

export interface InputFieldTempateProps extends React.PropsWithChildren {
  /**The width of the inputfield */
  width?: number | string;
  /**Different size of the inputfield */
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
  /**Icon on the left side of the inputfield */
  iconLeft?: keyof typeof iconFiles;
  /**Funtionality of the left icon */
  onLeftIconClick?: EventHandler<React.MouseEvent>;
  /**Default label of the inputfield */
  label?: string;
  /**Icon on the right side of the inputfield */
  iconRight?: keyof typeof iconFiles;
  /**Funtionality of the right icon */
  onRightIconClick?: EventHandler<React.MouseEvent>;
  /**Whether the inputfield can be reached or not */
  disabled?: boolean;
  /**When there is something wrong with the inputfield */
  criticalState?: boolean;
  children?: React.ReactNode;
  /**Multiline is used in the InputField component*/
  useMultiline?: boolean;
  /** propagate focus event */
  onFocus?(ev: React.FocusEvent): void;
  /** propagate blur event */
  onBlur?(ev: React.FocusEvent): void;
}

const useCSS = makeCSS(
  ({ theme, width }: InputFieldTempateProps & { theme }) => {
    return {
      root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        fontFamily: theme.fontFamilies.body,
        fontWeight: theme.fontWeights.regular,
        paddingLeft: theme.spacing[3],
        paddingRight: theme.spacing[3],
        flexDirection: 'column',
        marginBottom: theme.spacing[2],
        borderRadius: theme.spacing[2],
        background: theme.color.underlay['01'],
        border: `1px solid ${theme.color.line['02']}`,
        color: theme.color.text['03'],
        '&:focus-within': {
          outline: 'none',
          border: `1px solid ${theme.color.foreground.primary}`,
          boxShadow: '0px 0px 0px 4px rgba(255, 212, 35, 0.12)',
          input: { color: theme.color.text['01'] },
          textarea: { color: theme.color.text['01'] }
        },
        '&.disabled': {
          cursor: 'default',
          opacity: '40%',
          pointerEvents: 'none',
          '&:focus, &:focus-within': {
            border: `1px solid ${theme.color.line['02']}`,
            input: { color: theme.color.text['03'] },
            boxShadow: 'none',
            outline: 'none',
            color: theme.color.text['03']
          }
        }
      },
      width: {
        width: typeof width == 'string' ? width : theme.spacing[width]
      },
      extraPadding: {
        padding: theme.spacing[3]
      },
      criticalState: {
        border: `1px solid ${theme.color.background.critical}`,
        boxShadow: '0px 0px 0px 4px rgba(218, 49, 67, 0.12)',
        '&:focus-within': {
          border: `1px solid ${theme.color.background.critical}`,
          boxShadow: '0px 0px 0px 4px rgba(218, 49, 67, 0.12)'
        }
      },
      //we need to set it from this file so we can manipulate the height of the whole component
      multilineHeight: {
        height: 'auto'
      },

      /**Sizes */
      'size-sm': {
        height: theme.spacing[8],
        fontSize: theme.fontSize[0]
      },
      'size-md': {
        height: theme.spacing[10],
        fontSize: theme.fontSize[1]
      },
      'size-lg': {
        height: theme.spacing[12],
        fontSize: theme.fontSize[3]
      },

      outsideWrapper: {
        display: 'inline-flex',
        alignItems: 'start'
      },

      message_wrapper: {
        color: theme.color.text['03'],
        alignSelf: 'center',
        resize: 'none',
        leadingTrim: 'both',
        width: '100%',
        overflow: 'hidden',
        lineHeight: '17.16px'
      },

      iconLeft: {
        cursor: 'pointer',
        margin: 'auto',
        top: 0,
        bottom: 0,
        paddingRight: theme.spacing[2],
        '&.disabled': {
          cursor: 'default'
        }
      },

      iconRight: {
        cursor: 'pointer',
        margin: 'auto',
        top: 0,
        bottom: 0,
        paddingLeft: theme.spacing[2],
        '&.disabled': {
          cursor: 'default'
        }
      }
    };
  }
);

export const InputFieldTempate = (props: InputFieldTempateProps) => {
  const {
    width = 80,
    size = SizeVariants.md,
    iconLeft,
    onLeftIconClick,
    label = 'This text',
    iconRight,
    onRightIconClick,
    disabled,
    criticalState = false,
    children,
    useMultiline = false,
    onFocus,
    onBlur
  } = props;

  const classes = useCSS(props);
  const [colorVariant, setColorVariant] = useState<'01' | '03'>('03');

  const handleFocus = (ev) => {
    onFocus?.(ev);
    setColorVariant('01');
  };
  const handleBlur = (ev) => {
    onBlur?.(ev);
    setColorVariant('03');
  };

  return (
    <div
      tabIndex={0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={useClassName(
        classes.root,
        width && classes.width,
        { disabled: 'disabled' },
        criticalState ? classes.criticalState : null
      )}
    >
      <div
        className={cx(
          classes.outsideWrapper,
          classes[`size-${size}`],
          {
            disabled: disabled
          },
          useMultiline ? classes.multilineHeight : ''
        )}
      >
        {iconLeft && (
          <div
            onClick={onLeftIconClick}
            className={cx(classes.iconLeft, {
              disabled: disabled
            })}
          >
            <Icon icon={iconLeft} colorVariant={colorVariant} size={size} />
          </div>
        )}
        {children !== undefined && (
          <div className={cx(classes.message_wrapper)}>{children}</div>
        )}
        {label != '' ? (
          <div className={cx(classes.message_wrapper, classes.extraPadding)}>
            {label}
          </div>
        ) : (
          ''
        )}

        {iconRight && (
          <div
            onClick={onRightIconClick}
            className={cx(classes.iconRight, {
              disabled: disabled
            })}
          >
            <Icon icon={iconRight} colorVariant="03" size={size} />
          </div>
        )}
      </div>
    </div>
  );
};
