import { useCallback } from 'react';
import { documentShortcut } from '../../../keyboard-shortcuts/documentShortcut';
import { useShortCut } from '../../../keyboard-shortcuts/useShortCut';

export const shortCutFullscreen = documentShortcut(
  {
    keyCode: 'f',
    help: 'Enter / leave fullscreen',
    icon: 'expand_content'
  },
  'Image & Document previewer'
);

export const shortCutZoomIn = documentShortcut(
  {
    keyCode: '=',
    help: 'Zoom in',
    icon: 'zoom_in'
  },
  'Image & Document previewer'
);

export const shortCutZoomOut = documentShortcut(
  {
    keyCode: '-',
    help: 'Zoom out',
    icon: 'zoom_out'
  },
  'Image & Document previewer'
);

export const shortCutZoomReset = documentShortcut(
  {
    keyCode: '0',
    help: 'Zoom to fit',
    icon: 'zoom_reset'
  },
  'Image & Document previewer'
);

export const shortCutDocumentTop = documentShortcut(
  {
    keyCode: 'Home',
    help: 'Scroll to document top'
  },
  'Document previewer'
);

export const useImagePreviewKeyboardShortCuts = ({
  toggleFullscreenAction,
  zoomIn,
  zoomOut,
  zoomReset,
  canZoomIn,
  canZoomOut,
  canFitZoom,
  canGoToPage = false,
  goToPage = undefined
}) => {
  useShortCut({
    ...shortCutFullscreen,
    action: useCallback(() => {
      toggleFullscreenAction();
    }, [toggleFullscreenAction])
  });

  useShortCut({
    ...shortCutZoomIn,
    action: useCallback(() => {
      zoomIn();
    }, [zoomIn]),
    disabled: !canZoomIn
  });

  useShortCut({
    ...shortCutZoomOut,
    action: useCallback(() => {
      zoomOut();
    }, [zoomOut]),
    disabled: !canZoomOut
  });

  useShortCut({
    ...shortCutZoomReset,
    action: useCallback(() => {
      zoomReset();
    }, [zoomReset]),
    disabled: !canFitZoom
  });

  useShortCut({
    ...shortCutDocumentTop,
    action: useCallback(() => {
      goToPage();
    }, [goToPage]),
    disabled: !goToPage || !canGoToPage
  });
};
