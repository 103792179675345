import { cx } from '@emotion/css';
import React, { useState } from 'react';
import { uuidv4 } from '../../../../../state/src/utils/uuid';
import { SizeVariants } from '../../../theme/themes';
import { withCubicleFallback } from '../../../utils/CubicleFallback';
import { makeCSS } from '../../../utils/makeCSS';
import { iconFiles } from '../Icon/files';
import { InputFieldTempate } from './InputFieldTempate';
import { Message } from './Message';

/**
 * This component allows users to enter a password, with an option to show what they’ve entered as plain text.
 * This allows users to visually check their password before they submit it, which helps them reduce errors and choose passwords that are more unique and secure.
 *
 * The Password component uses the Message component to display a message for invalid password or other extra information.
 */
export interface PasswordProps {
  /** Label of the search input */
  label?: string;
  /***/
  onEnter?(value: string): void;
  /** Different sizes*/
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
  /** Disable the search input*/
  disabled?: boolean;
  /** Text for the message */
  messageLabel?: keyof typeof iconFiles;
  /** Display or hide message*/
  showMessage?: boolean;
  /** don't ubfuscate the password initially */
  startVisible?: boolean;
  /** Controlled input value */
  value?: string;
  /** change handler callback */
  onChange?(value: string): void;
  /** set to false to try to prevent browser autofill */
  autoFill?: boolean;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    passwordField: {
      width: '100%',
      alignContent: 'bottom',
      resize: 'none',
      color: theme.color.text['01'],
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      background: 'inherit',
      marginTop: 0,
      outline: 'none',
      border: 'none',
      paddingTop: theme.spacing[3],
      paddingBottom: theme.spacing[3],
      '&::placeholder': {
        color: theme.color.text['03']
      }
    }
  };
});

const Password = (props: PasswordProps) => {
  const {
    label = 'Password...',
    size = SizeVariants.md,
    disabled,
    onEnter,
    messageLabel = 'Invalid password',
    showMessage,
    onChange,
    startVisible,
    value,
    autoFill = false
  } = props;

  const [visible, setVisible] = useState(startVisible);
  const [passwordInput, setPasswordInput] = useState(props.value || '');

  const classes = useCSS();

  const handleVisibility = (event) => {
    setVisible(!visible);
  };
  const handleChange = (event) => {
    setPasswordInput(event.target.value);
    onChange?.(event.target.value);
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      onEnter(passwordInput);
    }
  };

  React.useLayoutEffect(() => {
    if (passwordInput !== value) {
      setPasswordInput(value);
    }
  }, [value]);

  return (
    <>
      <InputFieldTempate
        width={'80'}
        label={''}
        size={size}
        iconLeft={'lock'}
        iconRight={visible ? 'visibility' : 'visibility_off'}
        disabled={disabled}
        onRightIconClick={handleVisibility}
      >
        <input
          disabled={disabled}
          className={cx(classes.passwordField)}
          type={visible || !passwordInput ? 'text' : 'password'}
          placeholder={label}
          onKeyDown={handleEnter}
          onChange={handleChange}
          value={passwordInput}
          id={!autoFill ? uuidv4() : 'password'}
          name={!autoFill ? uuidv4() : 'password'}
          autoCorrect={!autoFill && 'off'}
          spellCheck={!autoFill}
          autoComplete={!autoFill ? uuidv4() : undefined}
        />
      </InputFieldTempate>

      {showMessage && (
        <Message
          label={messageLabel}
          messageColor={'critical'}
          // icon={''}
        />
      )}
    </>
  );
};

const Default = withCubicleFallback(Password);
export default Default;
export { Default as Password };
