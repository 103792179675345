import { ContentTreeContext } from '@cube3/common/model/types';
import { UiScreenDefaultState as defaultState } from './state';
import { getNumberOfColumns, getNumberOfGridItems } from './utils';

export const getOrderBy = (variant: ContentTreeContext) => (state) => {
  const orderBy = state.ui.sorting[variant].orderBy;
  const defaultOrderBy = defaultState.sorting.library.orderBy;

  return orderBy || defaultOrderBy;
};

export const getOrderDirection = (variant: ContentTreeContext) => (state) => {
  const orderDirection = state.ui.sorting[variant].orderDirection;
  const defaultOrderDirection = defaultState.sorting.library.orderDirection;

  return orderDirection || defaultOrderDirection;
};

export const getMaxColumns = () => (state) => {
  const { containerWidth } = state.ui.sorting;
  return getNumberOfColumns(containerWidth);
};

export const getMaxGridColumns = () => (state) => {
  const { containerWidth } = state.ui.sorting;
  return getNumberOfGridItems(containerWidth);
};

export const getMaxListColumns = () => (state) => state.ui.maxListColumns;

export const getUiState = () => (state) => state.ui;
export const getSortingState = (variant: ContentTreeContext) => (state) => {
  return state.ui.sorting[variant];
};

export const uiSelectors = {
  getUiState,
  getSortingState,
  getOrderBy,
  getOrderDirection,
  getMaxColumns,
  getMaxGridColumns,
  getMaxListColumns
};
