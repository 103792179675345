import { CommandBarGenericUI } from '@cube3/ui/src/library/CommandBar/CommandBarUI';
import React from 'react';

import { useContractButtonConfig } from '../../hooks/useContractButtonConfig';
import { useButtonConfig } from '../../../CommandBar/useButtonConfig';

interface Props {
  contractId?: string;
}

export const ContractCommandBar = ({ contractId }: Props) => {
  const buttonFactories = useContractButtonConfig(contractId);
  const buttons = useButtonConfig(buttonFactories);

  return <CommandBarGenericUI primaryButtonsConfig={buttons} />;
};
