import { ContextVariant, SortableListType } from '../model/types';

export const INITIAL_COLUMNS = {
  library: [],
  projects: [],
  trash: [],
  search: [
    'switch_view',
    'display_name',
    'search_results',
    'tags',
    'project_status_access',
    'updated_at',
    'duration',
    'file_size',
    'type',
    'created_at'
  ],
  sharelinkView: [],
  favorites: [],
  sharelinkViewNoDates: [],
  projectTemplates: []
} as {
  [key in ContextVariant]: SortableListType[];
};

/** fields that always stay visible */
export const LOCKED_COLUMNS = [
  'switch_view',
  'display_name'
] as SortableListType[];

export const LARGE_COLUMNS = [
  'display_name',
  'search_results',
  'tags'
] as SortableListType[];

export const COLUMN_MIN_WIDTH = 128 as const;
export const COLUMN_MAX_WIDTH = 192 as const;
export const LARGE_COLUMN_MIN_WIDTH = 192 as const;
export const LARGE_COLUMN_MAX_WIDTH = 768 as const;

export const columnLabels: { [key in SortableListType]: string } = {
  switch_view: '',
  thumbNail: '',
  created_at: 'Creation date',
  display_name: 'Name',
  search_results: 'Search results',
  project_status_access: 'Status',
  updated_at: 'Last modified',
  duration: 'Duration',
  file_size: 'Size',
  type: 'Type',
  removed_date: 'Removed date',
  deleted_at: 'Removed date',
  members: 'Members',
  tags: 'Tags'
};
