import { Share } from '@cube3/common/model/schema/resources/share';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import React from 'react';

interface Props {
  intent: Share['intent'];
  action: 'create' | 'edit';
}

export const intentMapping: Record<Share['intent'], string> = {
  'review-legacy': 'Review Link',
  review: 'Review',
  share: 'Share link',
  request: 'File Request'
};

const actionMapping = {
  create: 'Create',
  edit: 'Edit'
};

export const ShareSettingsTitle = (props: Props) => {
  const { intent, action } = props;

  const resource = intentMapping[intent];
  const verb = actionMapping[action];

  return (
    <Typography variant="lg" weight="label">
      {verb} {resource}
    </Typography>
  );
};
