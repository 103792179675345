import { iconEdit } from '@cube3/ui/src/icons/GeneralSVG';
import {
  archivedProjectTooltip,
  readOnlyProjectTooltip
} from '../../../../CommandBar/factories/tooltip';
import {
  ButtonFactory,
  ButtonFactoryConfig
} from '../../../../CommandBar/useButtonConfig';

/** edit contract inside a project */
export const EditAttachedContractButtonFactory = (
  onClick: () => void,
  contract
): ButtonFactory => {
  const factory = (config: ButtonFactoryConfig) => {
    const { canWriteContract } = config.privileges;
    const { isProjectArchived, isProjectRoleReadOnly } = config;

    const disabledReason = (() => {
      switch (true) {
        case !canWriteContract:
          return 'This account has no permission to edit contract';
        case isProjectRoleReadOnly:
          return readOnlyProjectTooltip;
        case isProjectArchived:
          return archivedProjectTooltip;
        default:
          return undefined;
      }
    })();

    return {
      title: 'Edit Contract',
      visible: true,
      disabled:
        !contract ||
        !canWriteContract ||
        isProjectRoleReadOnly ||
        isProjectArchived,
      disabledReason,
      icon: iconEdit,
      onClick: onClick
    };
  };

  return {
    factory
  };
};
