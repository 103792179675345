import {
  ContentTreeContext,
  SortableListType
} from '@cube3/common/model/types';
import {
  COLUMN_MAX_WIDTH,
  INITIAL_COLUMNS
} from '@cube3/common/static/columns';
import { isMobile } from 'react-device-detect';

export function getNumberOfGridItems(width: number) {
  let col;

  if (isMobile) {
    return 1;
  }

  if (width < 320) {
    col = 1;
  } else if (width < 501) {
    col = 2;
  } else if (width < 761) {
    col = 3;
  } else if (width < 1021) {
    col = 4;
  } else if (width < 1281) {
    col = 5;
  } else if (width < 1541) {
    col = 6;
  } else if (width < 1801) {
    col = 7;
  } else if (width < 2061) {
    col = 8;
  } else {
    col = 9;
  }
  return col;
}

export function getNumberOfColumns(width: number) {
  let col;
  if (width < 860) {
    col = 1;
  } else if (width >= 860 && width < 960) {
    col = 3;
  } else if (width >= 960 && width < 1160) {
    col = 4;
  } else if (width >= 1160 && width < 1360) {
    col = 5;
  } else if (width >= 1360 && width < 1560) {
    col = 6;
  } else {
    col = 7;
  }
  return col;
}

/** Funtion to insert or delete a column in the original order */
export const spliceColumn = (
  variant: ContentTreeContext,
  config,
  item: SortableListType
) => {
  const allColumns = INITIAL_COLUMNS[variant];
  let { activeColumns, columnsWidth } = config;
  const isActive = activeColumns.includes(item);
  /** handle hiding a column */
  if (isActive) {
    // should remove the column from the state
    activeColumns = activeColumns.filter((a) => a !== item);
    // and remove the width of the target column
    const targetIndex = allColumns.findIndex((ac) => ac === item);
    columnsWidth = columnsWidth.toSpliced(targetIndex, 1);
  }
  // handle adding a new column
  else {
    const target = allColumns.findIndex((al) => al === item);
    activeColumns = activeColumns.toSpliced(target, 0, item);
    // add a default width to the new column
    columnsWidth = columnsWidth.toSpliced(target, 0, COLUMN_MAX_WIDTH);
  }
  return {
    activeColumns,
    columnsWidth
  };
};

export const moveColumn = (
  config,
  item: SortableListType,
  dir: 'left' | 'right'
) => {
  const { activeColumns, columnsWidth } = config;
  const columns = [...activeColumns];
  const widths = [...columnsWidth];

  const target = activeColumns.findIndex((acol) => acol === item);
  const width = columnsWidth[target];
  const moveTo = dir === 'left' ? target - 1 : target + 1;

  columns[target] = columns[moveTo];
  columns[moveTo] = item;

  widths[target] = widths[moveTo];
  widths[moveTo] = width;

  return {
    activeColumns: columns,
    columnsWidth: widths
  };
};
