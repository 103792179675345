import * as React from 'react';

// State
import { FileRequest } from '@cube3/common/model/resource-types';
import { User } from '@cube3/common/model/schema/resources/user';

import { ModalActionProps } from '../../Modals/modalActions';

// UI
import { FileRequestUI } from '@cube3/ui/src/Prefabs/FileRequests/SubComponents/FileRequestUI';

// helpers
import { expiredCheck } from '@cube3/common/utils/expiredCheck';
import moment from 'moment';
import { compose } from '../../../../../utils/component-helpers';
import { useModalActions } from '../../Modals/modalActions';

interface PublicProps {
  request: FileRequest;
}

interface PrivateProps extends ModalActionProps {
  retrievedUsers: User[];
}

type Properties = PrivateProps & PublicProps;

function FileRequestItemC(props: Properties) {
  const { request } = props;

  const modalActions = useModalActions();
  const inPast = expiredCheck(request.expires_at);

  return (
    <FileRequestUI
      created_at={moment(request.created_at).format()}
      email_addresses={request.email_addresses || []}
      expired={inPast}
      expires_at={moment(request.expires_at).format()}
      onClickFilerequest={() =>
        modalActions.openModal('ShareSettings', {
          intent: 'request',
          shareId: request.id
        })
      }
      title={request.display_name}
    />
  );
}

/**
 * @summary FileRequest item displayed as a row that is part of `Filerequest overview modal`
 * @author Simon
 */
const FileRequestItem = compose(
  FileRequestItemC
)() as React.ComponentType<PublicProps>; // put wrappers here.

export { FileRequestItem };
