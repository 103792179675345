import { cx } from '@emotion/css';
import React, { EventHandler, useRef, useState } from 'react';
import { SizeVariants } from '../../../theme/themes';
import { withCubicleFallback } from '../../../utils/CubicleFallback';
import { makeCSS } from '../../../utils/makeCSS';
import { iconFiles } from '../Icon/files';
import { InputFieldTempate } from './InputFieldTempate';
import { Message } from './Message';

/**
 * InputFields provides users the means to enter non-standardized responses.
 * It's used to create interactive controls, in order to collect and retrieve user data.
 */
export interface InputFieldProps {
  /**Label of the search input */
  label?: string;
  /**When new text is filled or when the enter is clicked */
  onSubmit?(value: string): void;
  /**Icon on the left side of the inputfield */
  iconLeft?: keyof typeof iconFiles;
  /**Funtionality of the left icon */
  onLeftIconClick?: EventHandler<React.MouseEvent>;
  /**Icon on the right side of the inputfield */
  iconRight?: keyof typeof iconFiles;
  /**Funtionality of the right icon */
  onRightIconClick?: EventHandler<React.MouseEvent>;
  autoIconSize?: boolean;
  /**Different sizes*/
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
  /** use full available width  */
  fullwidth?: boolean;
  /**Disable the search input*/
  disabled?: boolean;
  /**When there is something wrong with the inputfield */
  criticalState?: boolean;
  /**Number of rows for the message field */
  multiline?: number; //Only for InputField/Message
  /**Show or hide characher count*/
  showCount?: boolean;
  /**How many characters to be allowed */
  characterCount?: number;
  /**Count how many character are left or how many have been already added */
  countVariant?: 'charLeft' | 'charIn' | 'custom';
  /**Customizable number for counting area, users can add their on functionality*/
  customNumber?: number;
  /** */
  value?: string;
  onChange?(value: string): void;
  /*________ */
  /**Message underneath the inputfield, used for comments or notifications*/
  messageLabel?: string;
  /**Use the message*/
  showMessage?: boolean;
  /***/
  messageIcon?: keyof typeof iconFiles;
  /**Different colors of the icons and the text */ //Only for InputField/Message
  messageColor?: 'default' | 'positive' | 'warning' | 'critical';
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'start',
      paddingTop: theme.spacing[3],
      paddingBottom: theme.spacing[3]
    },
    textarea: {
      width: '100%',
      alignContent: 'bottom',
      resize: 'none',
      color: theme.color.text['01'],
      fontFamily: 'inherit',
      fontSize: 'inherit',
      background: 'inherit',
      padding: 0,
      paddingTop: 1,
      marginTop: 0,
      outline: 'none',
      border: 'inherit',
      '&::placeholder': {
        color: theme.color.text['03']
      }
    },
    word_count: {
      position: 'absolute',
      right: 8,
      // marginLeft: theme.spacing[2],
      paddingLeft: theme.spacing['0,5'],
      paddingRight: theme.spacing['0,5'],
      borderRadius: theme.spacing[1],
      background: theme.color.background.white,
      color: theme.color.text.light['01'],
      fontWeight: theme.fontWeights.medium,
      userSelect: 'none'
    }
  };
});

const InputField = (props: InputFieldProps) => {
  const {
    label = 'Start typing...',
    onSubmit,
    iconLeft,
    onLeftIconClick,
    iconRight,
    onRightIconClick,
    size = SizeVariants.md,
    fullwidth = false,
    disabled = false,
    criticalState = false,
    multiline = 2,
    showCount = false,
    characterCount = 99,
    countVariant = 'charIn',
    customNumber,
    onChange,
    //
    messageLabel = 'Message',
    showMessage = false,
    messageIcon = 'add',
    messageColor = 'default',
    value = ''
  } = props;

  const classes = useCSS();
  const inputRef = useRef(null);

  const [input, setInput] = useState(props.value || '');

  const characterCountValue =
    countVariant === 'charIn'
      ? input.replace(/\s/g, '').length
      : characterCount - input.replace(/\s/g, '').length;

  const inputHandler = (event) => {
    setInput(event.target.value);
    onChange?.(event.target.value);
  };

  //when user clicks on the inputfield the textarea inside of it gets focused
  const startWriting = () => {
    inputRef.current.focus();
  };

  React.useLayoutEffect(() => {
    if (input !== value) {
      setInput(value);
    }
  }, [value]);

  //when the enter button is pushed (inside the text field)
  const handleKeyDown = (event) => {
    if (input != '') {
      if (event.keyCode === 13) {
        event.preventDefault();
        onSubmit?.(input);
      }
    }
  };
  let multilineOn = false;
  if (multiline > 1) {
    multilineOn = true;
  }

  return (
    <div>
      <InputFieldTempate
        width={fullwidth ? 'auto' : 80}
        label={''}
        criticalState={criticalState}
        size={size}
        iconLeft={iconLeft}
        onLeftIconClick={onLeftIconClick}
        iconRight={iconRight}
        onRightIconClick={onRightIconClick}
        disabled={disabled}
        useMultiline={multilineOn}
      >
        <div onClick={startWriting} className={`${classes.root}`}>
          <textarea
            disabled={disabled}
            ref={inputRef}
            onKeyDown={handleKeyDown}
            className={`${classes.textarea}`}
            placeholder={label}
            rows={multiline}
            onChange={inputHandler}
            value={input}
          ></textarea>

          {showCount ? (
            <div
              className={cx(classes.word_count, {
                disabled: disabled
              })}
            >
              {/**whether the number displayed will be the characters left of the character that are already in the text field*/}
              {countVariant === 'custom' ? (
                <div>{customNumber}</div>
              ) : (
                <div>{characterCountValue}</div>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </InputFieldTempate>
      {showMessage && (
        <Message
          label={messageLabel}
          messageColor={criticalState ? 'critical' : messageColor}
          icon={messageIcon}
        ></Message>
      )}
    </div>
  );
};

const Default = withCubicleFallback(InputField);
export default Default;
export { Default as InputField };
