import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import {
  GeneralSVGProperties,
  iconCheck,
  iconKeyboardArrowRight
} from '../icons/GeneralSVG';
import { ButtonProps } from '@material-ui/core/Button/Button';
import Cube3Button, { Cube3ButtonProps } from './Cube3Button';
import MaybeTooltip, { MaybeTooltipProps } from '../Tooltip/MaybeTooltip';
/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// Button with just a left icon which overrides styling in buttonwithtextandicon
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notClickable: {
      pointerEvents: 'none'
    },
    rightSvg: {
      position: 'absolute',
      right: theme.customSpacing.margin[12]
    }
  })
);

interface MenuItemProps extends Cube3ButtonProps, MaybeTooltipProps {
  path?: string;
  borderStyle?: 'squared' | 'rounded';
  hasSubItems?: boolean;
  overrideSubItemsPath?: string;
  extraButtonProps?: ButtonProps & { 'data-type'?: string };
  notClickable?: boolean;
  extraSVGProps?: GeneralSVGProperties;
  checked?: boolean;
}

function MenuItem(props: MenuItemProps) {
  const classes = useStyles(props);
  const {
    path,
    hasSubItems = false,
    extraButtonProps,
    overrideSubItemsPath,
    colorVariant = 'ghost2',
    fullWidth = true,
    disableFocus = true,
    disableHover = false,
    variant = 'navMenuItem',
    disabled = false,
    disabledReason,
    checked = false,
    disabledReasonPlacement = 'bottom'
  } = props;

  const extraProperties: ButtonProps = {
    fullWidth: true
  };

  const mergedProps: ButtonProps = {
    ...extraButtonProps,
    ...extraProperties
  };

  // #region determine rendering of right icon
  let rightIconPath: string = hasSubItems ? iconKeyboardArrowRight : undefined;
  if (overrideSubItemsPath) {
    rightIconPath = overrideSubItemsPath;
  }
  // #endregion

  return (
    <MaybeTooltip
      disabled={!disabled && !!!disabledReason}
      title={disabled && disabledReason}
      placement={disabledReasonPlacement}
    >
      <Cube3Button
        classes={{ rightSvg: `${hasSubItems && classes.rightSvg} ` }}
        pathRightSvg={checked ? iconCheck : rightIconPath}
        pathLeftSvg={path}
        extraButtonProperties={mergedProps}
        colorVariant={colorVariant}
        fullWidth={fullWidth}
        disableFocus={disableFocus}
        variant={variant}
        disableHover={disableHover}
        {...props}
      />
    </MaybeTooltip>
  );
}

export default MenuItem;
