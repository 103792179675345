import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCallback, useMemo, useState } from 'react';
import {
  combinePresets,
  getGroupedPresets
} from '../../Downloads/hooks/useRenderPresets';
import { useShareDownloads } from '../../Downloads/hooks/useShareDownloads';
import { useModalActions } from '../../Modals/modalActions';
import { SelectedFormatsType } from '../Modals/ShareDownloadAdvancedFormatsModal';

export interface ShareDownloadFormatsProps {
  onClickChooseFormats: () => void;
  allPresets: SelectedFormatsType;
  downloadFormats: SelectedFormatsType;
  selectedFormats: {
    id: string;
    type: 'render-preset';
    display_name: string;
  }[];
}

const DOWNLOAD_TYPE = {
  DOWNLOAD_TYPE_ORIGINAL: 'Original',
  DOWNLOAD_TYPE_PREVIEW: 'Preview'
} as const;

const requiredPermission = [
  'DOWNLOAD_TYPE_ORIGINAL',
  'DOWNLOAD_TYPE_PREVIEW'
] as Privileges[];

/** A custom hook to track selected formats in sharelinks
 * @param shareId: use to get the default formats in sharelink modal
 * @param assetId: if item to share is a single asset, should list filtered presets
 * @param watermark: if `Watermarked videos` is checked, `false` as default
 */
export const useShareDownloadFormats = (
  shareId: string,
  assetId?: string,
  watermark = false
) => {
  const { openModal } = useModalActions();

  const [canDownloadOriginal, canDownloadPreview] =
    useWorkspacePermissions(requiredPermission);

  /** use to create checkbox for every formats */
  const [downloadFormats, setDownloadFormats] = useState<SelectedFormatsType>(
    {}
  );
  const [workspaceId, workspace] = useCurrentWorkspace();
  const workspacePreferedDownloadTypeInShare =
    workspace && DOWNLOAD_TYPE[workspace.default_shares_download_type];

  const { sharedDownloadFormats, loading } = useShareDownloads(shareId);

  // if select a asset, should list filtered presets
  // otherwise list all available presets
  const params = useMemo(() => {
    return assetId
      ? {
          filter: {
            asset_ids: assetId
          }
        }
      : undefined;
  }, [assetId]);

  const workspacePresets = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params
  });
  const customPresets = useResourceList__ALPHA({
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params
  });

  const { allPresets, noOtherFormats, basicDownloadTypes } = useMemo(() => {
    let combined = combinePresets(
      customPresets.resources,
      workspacePresets.resources
    ) as RenderPreset[];
    // should only be allowed to set downloads options on a new or existing share, if your workspace role allows those privileges
    if (!canDownloadOriginal) {
      combined = combined?.filter(
        (p) => p.attributes.display_name !== 'Original'
      );
    }
    if (!canDownloadPreview) {
      combined = combined?.filter(
        (p) => p.attributes.display_name === 'Original' // all other presets fall under preview privilege
      );
    }
    const grouped = getGroupedPresets(combined);

    return {
      allPresets: grouped,
      basicDownloadTypes: grouped?.['default'],
      noOtherFormats: noOtherFormatsCheck(combined)
    };
  }, [
    customPresets.resources,
    workspacePresets.resources,
    canDownloadOriginal,
    canDownloadPreview
  ]);

  /** Initialize selected formats in sharelink
   * - if there's any presets has been set in share, should use that as initial value
   * - otherwise use `default_shares_download_type` from workspace preference
   */
  if (
    !loading &&
    !Object.keys(downloadFormats).length &&
    ((workspacePreferedDownloadTypeInShare && basicDownloadTypes) ||
      sharedDownloadFormats?.length)
  ) {
    let grouped = {};
    if (sharedDownloadFormats.length) {
      grouped = getGroupedPresets(sharedDownloadFormats);
    } else if (workspacePreferedDownloadTypeInShare) {
      const real = basicDownloadTypes.find(
        (b) =>
          workspacePreferedDownloadTypeInShare === b.attributes.display_name
      );

      grouped = {
        default: [
          (real?.attributes.display_name === 'Original' &&
            canDownloadOriginal) ||
          (real?.attributes.display_name === 'Preview' && canDownloadPreview)
            ? real
            : undefined
        ].filter(Boolean) // if selected asset has no preview, it will be undefined
      };
    }
    setDownloadFormats(grouped);
  }

  /** Clicking 'Choose formats…' to open the Download Formats Screen in a modal window on top of the current screen. */
  const onClickChooseFormats = useCallback(() => {
    /** if watermark is true, only allows to select watermarked download option */
    const filtered = Object.keys(allPresets).reduce((acc, group) => {
      const watermarkedPresets = allPresets[group].filter(
        (item) => item.attributes.display_name === 'Watermarked'
      );
      if (watermarkedPresets) {
        acc[group] = watermarkedPresets;
      }
      return acc;
    }, {});

    openModal(
      'sharelink_new_download',
      {
        allPresets: watermark ? filtered : allPresets,
        downloadFormats,
        setDownloadFormats
      },
      false
    );
  }, [allPresets, openModal, watermark, downloadFormats]);

  /** NOTE: in the current design, if `watermark` is checked, it only allows to select `Watermarked` preset.*/
  const filtered = useMemo(() => {
    const selected = mapSelectedFormatData(downloadFormats);
    return {
      basicDownloadTypes: watermark ? [] : basicDownloadTypes,
      selectedFormats: watermark
        ? selected?.filter((format) => format.display_name === 'Watermarked')
        : selected
    };
  }, [watermark, basicDownloadTypes, downloadFormats]);

  return {
    /** the selected formats in a shared link */
    sharedDownloadFormats,
    loading: loading || workspacePresets.loading || customPresets.loading,
    /** `Original` and `Preview` presets */
    basicDownloadTypes: filtered.basicDownloadTypes,
    // basicDownloadTypes,
    onClickChooseFormats,
    setDownloadFormats,
    /** grouped presets: `{[groupName: string]: RenderPreset[];}` */
    downloadFormats,
    //selectedFormats: mapSelectedFormatData(downloadFormats), // prepare the data to be send in POST/PATCH request
    selectedFormats: filtered.selectedFormats,
    noOtherFormats
  };
};

export const mapSelectedFormatData = (formats: SelectedFormatsType) => {
  if (!formats || !Object.keys(formats).length) return;
  const result = [];
  Object.keys(formats).forEach((key) => {
    const format = formats[key]?.map((f) => ({
      id: f?.id,
      type: 'render-preset',
      display_name: f?.attributes?.display_name
    }));
    result.push(...format);
  });

  return result as Array<{
    id: string;
    type: 'render-preset';
    display_name: string;
  }>;
};

/** return true if no more presets are available besides `preview` and `original` */
const noOtherFormatsCheck = (presets: RenderPreset[]) => {
  return (
    presets?.filter(
      (c) =>
        c.attributes.display_name !== 'Original' &&
        c.attributes.display_name !== 'Preview'
    )?.length === 0
  );
};
