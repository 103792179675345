// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@-moz-document url-prefix() {
  button::-moz-focus-inner {
    border: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./../main/src/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":["@-moz-document url-prefix() {\n  button::-moz-focus-inner {\n    border: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
