import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { VBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import Time from '@cube3/cubicle/src/core/utility/Time';
import { ExpiresDatePicker } from '@cube3/ui/src/Prefabs/shareLink/Forms/fields/ExpiresDatePicker';
import React from 'react';

interface Props {
  value: Date;
  onChange(recipients: RenderPreset[]): void;
  error?: boolean;
}

export const ExpirationDatePicker = (props: Props) => {
  const { value, onChange, error } = props;

  const hasExpired = new Date(value) < new Date();

  return (
    <VBox gap={8}>
      <ExpiresDatePicker input={{ value, onChange }} />
      {!error && props.value && (
        <Typography
          variant="sm"
          colorVariant="03"
          color={hasExpired ? 'warning' : 'default'}
        >
          {`This link ${hasExpired ? 'expired' : 'will expire'}`}{' '}
          <Time
            date={value}
            fullForm={false}
            toolTip={true}
            placement="bottom"
            relativeTime={true}
          />
        </Typography>
      )}
    </VBox>
  );
};
