import { PublicResource } from '../../resource-types';
import { RawModel } from '../schema';

export interface Recipient extends PublicResource {
  recipient_type: 'email|account';
  email_address?: string;
  account?: string;
  lastused_at?: Date;
}

export const recipient: RawModel = {
  JSONApi: false, // TODO: enable asap
  type: 'recipient',
  plural: 'recipients',
  attributes: {
    id: 'UUID',
    display_name: 'string',
    email_address: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
