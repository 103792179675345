import { iconVisibility } from '@cube3/ui/src/icons/GeneralSVG';
import { ButtonFactory, ButtonFactoryConfig } from '../../useButtonConfig';
import { isMacOs } from 'react-device-detect';
import { documentShortcut } from '@cube3/ui/src/keyboard-shortcuts/documentShortcut';
const shortCut = documentShortcut(
  {
    keyCode: isMacOs ? 'Space' : 'Enter',
    help: 'Visit Folder or preview Asset',
    icon: 'visibility'
  },
  'Inside folders'
);

export const PreviewButtonFactory = (onClick: () => void): ButtonFactory => {
  const f = (config: ButtonFactoryConfig) => {
    const { selection } = config;

    const fileType = selection && selection[0]?.type;

    const enabled = selection?.length === 1;

    const visible = config.variant === 'command-bar' || enabled;

    return {
      title:
        fileType === 'folder'
          ? 'Visit'
          : fileType === 'project'
          ? 'Open project'
          : 'Preview',
      visible,
      disabled: !enabled,
      icon: iconVisibility,
      onClick: onClick,
      shortCut: {
        ...shortCut,
        action: onClick,
        disabled: !enabled
      }
    };
  };

  return {
    factory: f
  };
};
