import { FSA } from '../flux-standard-action';

const searchTypeCompareArray = [
  'all',
  'project',
  'video',
  'audio',
  'image',
  'document',
  'folder',
  'asset',
  'url',
  'contract'
] as const;
const searchByCompareArray = [
  'all',
  'name',
  'metadata-value',
  'tags',
  'contract-value',
  'favorites'
] as const;

export type SearchBy = (typeof searchByCompareArray)[number];
export type SearchType = (typeof searchTypeCompareArray)[number];
export type SearchIn = 'folder' | 'workspace';

export interface SearchState {
  searchType: SearchType;
  searchBy: SearchBy;
  searchIn: SearchIn;
  searchFolderId: string;
  query: string;
}

const initialState: SearchState = {
  searchType: 'all',
  searchBy: 'all',
  searchIn: 'workspace',
  searchFolderId: undefined,
  query: undefined
};

const SET_QUERY = 'cube3-client/search/SET_QUERY';
const SET_FILTER_TYPE = 'cube3-client/search/SET_FILTER_TYPE';
const SET_FILTER_BY = 'cube3-client/search/SET_FILTER_BY';
const SET_FILTER_IN = 'cube3-client/search/SET_FILTER_IN';
const SET_SEARCH_FOLDER = 'cube3-client/search/SET_SEARCH_FOLDER';
const SET_AUTO_FOCUS = 'cube3-client/search/SET_AUTO_FOCUS';
const RESET = 'cube3-client/search/RESET';

export const setSearchQuery = (value: string) => ({
  type: SET_QUERY,
  payload: value
});

export const setSearchType = (value: SearchType) => ({
  type: SET_FILTER_TYPE,
  payload: value
});

export const setSearchBy = (value: SearchBy) => ({
  type: SET_FILTER_BY,
  payload: value
});

export const setSearchInFolder = (value: SearchIn) => ({
  type: SET_FILTER_IN,
  payload: value
});

export const setSavedSearchFolder = (value: string) => {
  return {
    type: SET_SEARCH_FOLDER,
    payload: value
  };
};

export const resetSearch = () => {
  return {
    type: RESET
  };
};

export const reducer = (state = initialState, action: FSA) => {
  const { type } = action;

  switch (type) {
    case SET_QUERY: {
      return {
        ...state,
        query: action.payload
      };
    }
    case SET_FILTER_BY: {
      const validValue = checkIfValidValue(action.payload, [
        ...searchByCompareArray
      ]);
      return {
        ...state,
        searchBy: validValue
      };
    }
    case SET_FILTER_IN: {
      return {
        ...state,
        searchIn: action.payload
      };
    }

    case SET_SEARCH_FOLDER: {
      // when the search folder is made inactive because of an invalid view, we should also set the searchInFolder boolean to false for consistency
      if (action.payload === undefined) {
        return {
          ...state,
          searchIn: 'workspace',
          searchFolderId: action.payload
        };
      }

      return {
        ...state,
        searchFolderId: action.payload
      };
    }

    case SET_FILTER_TYPE: {
      const validValue = checkIfValidValue(action.payload, [
        ...searchTypeCompareArray
      ]);
      return {
        ...state,
        searchType: validValue
      };
    }
    case SET_AUTO_FOCUS:
      return {
        ...state,
        autoFocus: action.payload
      };
    case RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;

/** Checks if the given requested Type is valid, this has to be checked because the value comes from the browser url which can be any string, if it is not valid return the first value in the array */
function checkIfValidValue(key: string, compareArray: Array<string>): string {
  // try to find a match...
  for (let index = 0; index < compareArray.length; index++) {
    if (compareArray[index] === key) {
      return compareArray[index];
    }
  }

  console.warn(
    'Could not find the value in filters: ',
    key,
    ' returning default key'
  );

  // if no match is found, return the first value in the array
  return compareArray[0];
}

//#region selectors
export const getSearchState = () => (state) => state.search as SearchState;
