import { iconEdit } from '@cube3/ui/src/icons/GeneralSVG';
import {
  ButtonFactory,
  ButtonFactoryConfig
} from '../../../../CommandBar/useButtonConfig';

export const EditContractButtonFactory = (
  onClick: () => void
): ButtonFactory => {
  const f = (config: ButtonFactoryConfig) => {
    const { canWriteContract } = config.privileges;

    const disabledReason = (() => {
      switch (true) {
        case !canWriteContract:
          return 'This account has no permission to edit contract';
        default:
          return undefined;
      }
    })();

    return {
      icon: iconEdit,
      title: 'Edit',
      visible: true,
      disabled: !canWriteContract,
      disabledReason: disabledReason,
      onClick: onClick
    };
  };

  return {
    factory: f
  };
};
