import { useSelection } from '@cube3/state/src/redux/components/useSelection';
import { useModalActions } from '../../Modals/modalActions';
import { useCallback, useMemo } from 'react';
import { EditContractButtonFactory } from '../prefabs/command-bars/button-factories/EditContractButtonFactory';
import { DeleteContractButtonFactory } from '../prefabs/command-bars/button-factories/DeleteContractButtonFactory';
import { DuplicateContractButtonFactory } from '../prefabs/command-bars/button-factories/DuplicateContractButtonFactory';
import { useContractDuplication } from './useContractDuplication';
import { matchPath, useLocation } from 'react-router';
import { urlStructureSearch } from '../../../routing/routingPaths';
import { PreviewButtonFactory } from '../../CommandBar/factories/moreButton/PreviewButtonFactory';
import { ButtonFactory } from '../../CommandBar/useButtonConfig';
import { usePreviewActions } from '@cube3/state/src/redux/components/withPreviewActions';

const noop = () => null;
export const useContractButtonConfig = (target?: string) => {
  const { selection } = useSelection();
  const contractId = target || (selection?.length === 1 ? selection[0].id : '');

  const { openModal } = useModalActions();
  const { duplicateContract } = useContractDuplication(contractId);
  const { pathname } = useLocation();
  const searchMatched = matchPath(pathname, urlStructureSearch);
  const { openPreview } = usePreviewActions();

  const onClickEdit = useCallback(() => {
    if (!contractId) return;
    openModal('edit_contract', {
      contractId
    });
  }, [openModal, contractId]);

  const onClickDelete = useCallback(() => {
    if (!contractId) return;
    openModal('delete_contract_prompt', { contractId }, false);
  }, [openModal, contractId]);

  const primaryBtnFactories = useMemo(
    () =>
      [
        searchMatched && PreviewButtonFactory(openPreview),
        EditContractButtonFactory(onClickEdit),
        DeleteContractButtonFactory(onClickDelete),
        DuplicateContractButtonFactory(contractId ? duplicateContract : noop)
      ].filter(Boolean) as ButtonFactory[],
    [
      contractId,
      searchMatched,
      onClickEdit,
      onClickDelete,
      duplicateContract,
      openPreview
    ]
  );

  return primaryBtnFactories;
};
