import { ContextVariant } from '@cube3/common/model/types';
import { ColumnConfig, OrderDirection, QueueTab } from './types';
import { COLUMN_MAX_WIDTH } from '@cube3/common/static/columns';

export const getResourceId = (resource) => (resource ? resource.id : '');

export const UPLOADS = 'UPLOADS';
export const EXPORTS = 'EXPORTS';
export const FEED = 'FEED';

export const queueTabs = {
  UPLOADS,
  EXPORTS,
  FEED
} as const;

export const ASC = 'asc';
export const DESC = 'desc';

export const orderDirection = {
  ASC,
  DESC
} as const;
export interface UiScreenState {
  fullscreen: boolean;
  fullscreenRequested: boolean;
  gridView: boolean;
  /** the contanier width to list library items */
  containerWidth: number;
  /** Used in resizable table: the max amoumt of columns in list view */
  maxListColumns?: number;
  sorting: {
    [key in ContextVariant]: {
      orderBy: string;
      orderDirection: OrderDirection;
    } & ColumnConfig;
  };
  infoPaneToggled: boolean;
  mediaQueueToggled: boolean;
  mediaQueueTab: QueueTab;
  mediaQueueLastOpened: number;
  uploadQueueBusy: boolean;
  // Review-link view
  // reviewSelectedAsset: any;
  tour: boolean;
  resizingColumn?: boolean;
}

export const UiScreenDefaultState: UiScreenState = {
  fullscreen: false,
  fullscreenRequested: false,
  gridView: true,
  maxListColumns: null,
  containerWidth: null,
  sorting: {
    library: {
      orderBy: 'display_name',
      orderDirection: ASC
    },
    projects: {
      orderBy: 'updated_at',
      orderDirection: DESC
    },
    trash: {
      orderBy: 'removed_date',
      orderDirection: ASC
    },
    sharelinkView: {
      orderBy: 'display_name',
      orderDirection: ASC
    },
    favorites: { orderBy: 'display_name', orderDirection: ASC },
    sharelinkViewNoDates: {
      orderBy: 'display_name',
      orderDirection: ASC
    },
    projectTemplates: {
      orderBy: 'updated_at',
      orderDirection: DESC
    },
    search: {
      orderBy: 'display_name',
      orderDirection: ASC,
      activeColumns: [
        'switch_view',
        'display_name',
        'search_results',
        'updated_at',
        'duration',
        'file_size',
        'type'
      ],
      columnsWidth: [
        80,
        320,
        320,
        COLUMN_MAX_WIDTH,
        COLUMN_MAX_WIDTH,
        COLUMN_MAX_WIDTH,
        COLUMN_MAX_WIDTH
      ]
    }
  },
  resizingColumn: false,
  infoPaneToggled: false,
  mediaQueueToggled: false,
  mediaQueueTab: queueTabs.UPLOADS,
  mediaQueueLastOpened: undefined,
  uploadQueueBusy: false,
  tour: false
};

export type SortableList = keyof typeof UiScreenDefaultState.sorting;
