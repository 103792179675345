import { cx } from '@emotion/css';
import React from 'react';
import { SizeVariants } from '../../../theme/themes';
import { withCubicleFallback } from '../../../utils/CubicleFallback';
import { makeCSS } from '../../../utils/makeCSS';
import { Icon } from '../Icon';
import { iconFiles } from '../Icon/files';

/**
 *The Message component is a visual indicator for messages, warnings or extra information.
 */
export interface MessageProps {
  /**Label of the search input */
  label?: string;
  /**Display an icon */
  icon?: keyof typeof iconFiles;
  /**Different colors of the icons and the text */
  messageColor?: 'default' | 'positive' | 'warning' | 'critical';
  /**Different sizes*/
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      display: 'inline-flex',
      fontFamily: theme.fontFamilies.body,
      fontWeight: theme.fontWeights.regular,
      background: 'none',
      border: 'none',
      curson: 'default',
      margin: theme.spacing[2],
      userSelect: 'none',
      alignItems: 'center',
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale'
    },
    /**Sizes */
    'size-sm': {
      minWidth: theme.spacing[10],
      width: theme.spacing[80],
      fontSize: theme.fontSize[0]
    },
    'size-md': {
      minWidth: theme.spacing[10],
      width: theme.spacing[80],
      fontSize: theme.fontSize[1]
    },
    'size-lg': {
      minWidth: theme.spacing[10],
      width: theme.spacing[80],
      fontSize: theme.fontSize[3]
    },
    /**Colors */
    default: {
      color: theme.color.text['02']
    },
    critical: {
      color: theme.color.background.critical
    },
    warning: {
      color: theme.color.background.warning
    },
    positive: {
      color: theme.color.background.positive
    },
    messageIcon: {
      paddingRight: theme.spacing[2]
    }
  };
});

const Message = (props: MessageProps) => {
  const {
    label = 'Message',
    size = SizeVariants.md,
    icon,
    messageColor = 'default'
  } = props;

  const classes = useCSS();
  let iconColor;
  switch (messageColor) {
    case 'warning':
      iconColor = (theme) => theme.color.foreground.warning;
      break;
    case 'positive':
      iconColor = (theme) => theme.color.foreground.positive;
      break;
    case 'critical':
      iconColor = (theme) => theme.color.foreground.critical;
      break;
    default:
      iconColor = '';
      break;
  }

  return (
    <div
      className={cx(
        classes.root,
        classes[messageColor],
        classes[`size-${size}`]
      )}
    >
      {icon && (
        <div className={classes.messageIcon}>
          <Icon icon={icon} size={size} customColor={iconColor} />
        </div>
      )}
      {label}
    </div>
  );
};

const Default = withCubicleFallback(Message);
export default Default;
export { Default as Message };
