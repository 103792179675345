import React from 'react';
//UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Surface from '../Surface/Surface';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    root: {
      height: 56,
      alignItems: 'center',
      display: 'flex',
      '&$mobile': {
        height: theme.sizing[40]
      }
    },
    searchContainer: {
      width: 260 - 48,
      marginRight: '24px'
    },
    scopeHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      flexShrink: 1,
      paddingLeft: 8,
      paddingRight: 8,
      '$mobile &': {
        width: 'calc(100% - 32px)',
        paddingLeft: theme.customSpacing.margin[16],
        paddingRight: theme.customSpacing.margin[16],
        '& > div': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }
      }
    }
  })
);

interface ScopeBarProps {
  searchComponent?: JSX.Element;
  scopeHeaderComponent?: JSX.Element;
}

function ScopeBar(props: ScopeBarProps) {
  const classes = useStyles(props);
  const { root, searchContainer, scopeHeaderContainer, mobile } = classes;
  const { searchComponent, scopeHeaderComponent } = props;

  return (
    <Surface
      background={'transparent'}
      className={`${root} ${isMobile ? mobile : ' '}`}
      style={{ overflow: 'visible' }}
    >
      {searchComponent && (
        <div id="tour-steps__search" className={searchContainer}>
          {searchComponent}
        </div>
      )}

      {scopeHeaderComponent && (
        <div className={scopeHeaderContainer}>{scopeHeaderComponent}</div>
      )}
    </Surface>
  );
}

export default ScopeBar;
