import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { VBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import { capitalize, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { downloadWarnings } from '../../../library/Downloads/downloadErrors';
import { CircularProgress } from '../../../Progress/CircularProgress';
import MaybeTooltip from '../../../Tooltip/MaybeTooltip';
import { Typography } from '../../../typography/Typography';
import ShareDownloadCheckBoxWithTooltip from './ShareDownloadCheckBoxWithTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    displayName: {
      paddingLeft: 16,
      paddingBottom: 8
    },
    defaultPresets: {
      marginLeft: 15
    }
  })
);

interface DownloadOptionsProps {
  visible: boolean;
  selectedFormats: RenderPreset[];
  setSelectedFormats: Function;
  /** original / preview type */
  basicDownloadTypes: Array<{ id: string; display_name: string }>;
  onClick: () => void;
  loading?: boolean;
  noOtherFormats: boolean;
  /** @deprecated not really used for anything */
  watermark?: boolean;
}

export const ShareDownloadOptions = (props: DownloadOptionsProps) => {
  const {
    visible,
    selectedFormats,
    setSelectedFormats,
    basicDownloadTypes,
    loading,
    onClick,
    noOtherFormats
  } = props;

  const classes = useStyles();

  const formatsString = arrayToString(selectedFormats);

  const noFormats = !basicDownloadTypes?.length && noOtherFormats;

  const warning = noFormats
    ? 'No formats available'
    : !selectedFormats?.length
    ? 'Select at least one format'
    : undefined;

  return visible ? (
    loading ? (
      <CircularProgress size="1em" />
    ) : (
      <div style={{ marginLeft: 18, marginBottom: 9 }}>
        <VBox gap={8} style={{ marginTop: 8, marginBottom: 8 }}>
          {basicDownloadTypes?.map((preset) => (
            <ShareDownloadCheckBoxWithTooltip
              key={preset.id}
              preset={preset}
              group={'default'}
              rootClass={classes.defaultPresets}
              selectedFormats={{ default: selectedFormats }}
              setSelectedFormats={setSelectedFormats}
            />
          ))}
        </VBox>

        <MaybeTooltip
          disabled={!noOtherFormats}
          title={downloadWarnings.no_other_formats}
          placement={'bottom'}
        >
          <Button
            buttonStyle={'ghost'}
            disabled={noOtherFormats}
            onClick={onClick}
          >
            {basicDownloadTypes?.length > 0
              ? 'More formats...'
              : 'Select formats...'}
          </Button>
        </MaybeTooltip>

        {formatsString && (
          <Typography color={'contrast2'} className={classes.displayName}>
            {warning}
          </Typography>
        )}

        {warning && (
          <Typography color={'danger1'} className={classes.displayName}>
            {warning}
          </Typography>
        )}
      </div>
    )
  ) : null;
};

const arrayToString = (arr: Array<RenderPreset>, length = 3) => {
  if (!arr?.length) return;
  return (
    arr
      .slice(0, length)
      .map((opt) => opt?.display_name && capitalize(opt.display_name))
      .join(', ') +
    (arr.length > length ? ` and ${arr.length - length} more` : '')
  );
};
