import React, { useCallback, useMemo } from 'react';

// HOC
import { WrappedComponentProps } from './withSelection';
import {
  matchPath,
  generatePath,
  RouteComponentProps,
  useHistory
} from 'react-router-dom';
import {
  useCurrentWorkspace,
  WorkspaceProps
} from './Administration/withCurrentWorkspace';
import { useSelection, useSelectionActions } from './useSelection';
import { useResourceList__ALPHA } from './Hooks/useResourceList';
import { useContextPath } from './Hooks/useContextPath';
import { Library } from '@cube3/common/model/schema/resources/library';
import {
  urlStructureContractView,
  urlStructureSearch,
  urlStructureWorkspace
} from '../../../../main/src/components/app/routing/routingPaths';

/**
* @summary : Helper component that generates a url that goes to detailview
* @author Simon
* @description : Looks at the current url (library or sharelink) and generates a new URL
                that navigates to detailview, and pushes it to React router history.
*/

export interface PreviewActionProps {
  previewAsset: (tab: detailviewTab) => void;
  previewAvailable: () => boolean;
  visitNode: () => void;
}

interface InternalProps
  extends WrappedComponentProps,
    WorkspaceProps,
    RouteComponentProps {
  libraries: Library[];
}

// #region Route match interfaces
interface ShareLinkMatcher {
  linkHash: string;
}

interface LibraryMatcher {
  ws: string;
  projOrLibrary: string;
  lib: string;
}

interface SearchMatcher {
  ws: string;
}
// #endregion

type detailviewTab = '/overview' | '/properties' | '/metadata';

export const usePreviewActions = () => {
  const [workspaceId] = useCurrentWorkspace();

  const libraries = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'library',
    edgeLabel: 'libraries'
  }).resources;

  const { push, location } = useHistory();
  const { selection, types } = useSelection(true);
  const { clearItems, clearActiveItem } = useSelectionActions();

  const openPreview = useCallback(
    (tab?: detailviewTab): void => {
      let generatedURL: string;
      let assetId: string;
      let currentLibrary: string;

      const { params } = matchPath<{ workspaceId: string }>(
        location.pathname,
        urlStructureWorkspace
      );

      if (selection?.length === 1 && selection[0].type === 'contract') {
        (generatedURL = generatePath(urlStructureContractView, {
          workspaceId: params.workspaceId,
          contractId: selection[0].id
        })),
          push(generatedURL, {
            from: location.pathname
          });
        return;
      }

      // try to get the asset id from the current selection
      if (selection?.length === 1 && selection[0].type === 'asset') {
        assetId = selection[0].id;
      } else {
        console.warn(
          'You are trying to open a detailview link under the wrong conditions.'
        );
        return; // early return
      }

      if (libraries) {
        currentLibrary = libraries[0]?.id;
      }

      // #region try to find the current location
      const libraryOrProjMatch = matchPath<LibraryMatcher>(
        location.pathname,
        '/workspace/:ws/:projOrLibrary/:lib/'
      );

      if (libraryOrProjMatch) {
        generatedURL = generatePath(
          '/workspace/:workspaceId/:libraryOrProject/:libraryIdOrProjectId/asset/:assetId/detail' +
            tab,
          {
            workspaceId: libraryOrProjMatch.params.ws,
            libraryOrProject: libraryOrProjMatch.params.projOrLibrary,
            libraryIdOrProjectId: libraryOrProjMatch.params.lib,
            assetId: assetId
          }
        );
      }
      const shareLinkMatch = matchPath<ShareLinkMatcher>(
        location.pathname,
        '/share-link/:linkHash'
      );
      if (shareLinkMatch) {
        generatedURL = generatePath(
          '/share-link/:linkHash/asset/:assetId/detail' + tab,
          {
            linkHash: shareLinkMatch.params.linkHash,
            assetId: assetId
          }
        );
      }
      //// favorites overview
      const favoritesMatch = matchPath<SearchMatcher>(
        location.pathname,
        '/workspace/:ws/favorites/'
      );
      if (favoritesMatch) {
        generatedURL = generatePath('/workspace/:workspaceId/node/:nodeId', {
          workspaceId: favoritesMatch.params.ws,
          nodeId: selection?.[0]['relationships']['node']['id']
        });
      }
      ////

      const searchMatch = matchPath<SearchMatcher>(
        location.pathname,
        '/workspace/:ws/search/'
      );
      if (searchMatch && currentLibrary) {
        generatedURL = generatePath(
          '/workspace/:workspaceId/library/:libraryId/asset/:assetId/detail' +
            tab,
          {
            workspaceId: searchMatch.params.ws,
            libraryId: currentLibrary, // we get the current library from withResourceEdges
            assetId: assetId
          }
        );
      } else {
        console.warn('Could not generate proper path for detail view');
      }

      if (
        !libraryOrProjMatch &&
        !shareLinkMatch &&
        !searchMatch &&
        !favoritesMatch
      ) {
        console.warn(
          'You are trying to open a detailview link from a location that is unknown in this component. Please add it to withPreviewActions.tsx'
        );
        return; // early return
      }
      // #endregion

      // open the modal with state so it knows where to return to when closing.
      push(generatedURL, {
        from: location.pathname
      });
    },
    [location, selection, libraries, push]
  );

  const [folderPath] = useContextPath({
    resourceId: selection?.[0]?.id,
    includeResource: true
  });

  const openFolder = useCallback(() => {
    push(folderPath);
    clearItems();
    clearActiveItem();
  }, [push, folderPath, clearItems, clearActiveItem]);

  const open = useCallback(() => {
    if (selection?.length !== 1) {
      return;
    }

    if (selection[0].type === 'asset') {
      openPreview('/overview');
    }

    if (selection[0].type === 'folder') {
      openFolder();
    }
    if (selection[0].type === 'project') {
      const to = generatePath('/workspace/:ws/project/:projectId', {
        ws: workspaceId,
        projectId: selection[0].id
      });
      push(to);
    }
  }, [selection, openPreview, openFolder]);

  // check if current selection can be previewed.
  const previewAvailable = useCallback((): boolean => {
    // get the current selection
    const typeOfLibrary: boolean = types?.library > 0;
    const typeOfFolder: boolean = types?.folder > 0;
    const typeOfProject: boolean = types?.project > 0;

    if (
      typeOfFolder ||
      typeOfLibrary ||
      typeOfProject ||
      !selection ||
      selection.length > 1
    ) {
      return false;
    }

    return true;
  }, [selection, types]);

  return useMemo(
    () => ({ openPreview, previewAvailable, open }),
    [open, openPreview, previewAvailable]
  );
};

/**
 *
 * @param WrappedComponent
 * @returns React.ComponentType - original component wrapped in hoc that injects previewActions props
 */
const withPreviewActions = (
  WrappedComponent: React.ComponentType<PreviewActionProps>
) => {
  const WithPreviewActionsHOC: React.FC<InternalProps> = React.memo((props) => {
    // get the current selection, url, and push an new route.

    const { openPreview, previewAvailable, open } = usePreviewActions();

    return (
      <WrappedComponent
        previewAsset={openPreview}
        previewAvailable={previewAvailable}
        visitNode={open}
        {...props}
      />
    );
  });

  return WithPreviewActionsHOC;
};

export default withPreviewActions;
